// extracted by mini-css-extract-plugin
export var sidebarContainer = "sidebar-module--sidebar-container--Mf4KX";
export var integrationInfo = "sidebar-module--integration-info--MN48S";
export var logoContainer = "sidebar-module--logo-container--1j5oK";
export var nameType = "sidebar-module--name-type--2tN3R";
export var nameContainer = "sidebar-module--name-container--3YEKS";
export var typeContainer = "sidebar-module--type-container--1FHfD";
export var publishContainer = "sidebar-module--publish-container--2dJXz";
export var versionStatus = "sidebar-module--version-status--TIZWy";
export var versionContainer = "sidebar-module--version-container--2i3q3";
export var statusContainer = "sidebar-module--status-container--1KJi1";
export var sidebarActionList = "sidebar-module--sidebar-action-list--2_1m6";
export var card = "sidebar-module--card--k1VpY";
export var sidebarCardBody = "sidebar-module--sidebar-card-body--35clr";
export var sidebarLinks = "sidebar-module--sidebar-links--1XBK5";
export var subSidebarLinks = "sidebar-module--sub-sidebar-links--1lzrv";
export var sidebarLinkActive = "sidebar-module--sidebar-link-active--2q4eJ";
export var sidebarCard = "sidebar-module--sidebar-card--3vsOj";
export var sidebarCardTitle = "sidebar-module--sidebar-card-title--2T4Ow";