import React, { useState, useContext, useEffect } from 'react'
import { Form, Spinner } from "react-bootstrap"
import AioButton from '../../../components/UI-Elements/AioButton'
import { navigate, RouteComponentProps, WindowLocation } from '@reach/router'
import { IAuthInputFields, IAppInfoVersion } from '../../../models'
import { updateAppInfoVersionAction } from '../../../actions'
import { Context, GlobalContext } from '../../../context/ContextStore'
import * as ActionTypes from '../../../actions/types'
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface IFormData {
    _id: string, key: string, label: string, defaultValue: string, required: boolean, type: "String" | "Number" | "Date" | "Password" | "Subdomain"
}

interface ILocation extends WindowLocation {
    state: {
        formData?: IFormData
    }
}
interface Props extends RouteComponentProps {
    location?: ILocation
}

interface InputFieldsProps extends Props {
    
}

const EditInputFields = (props: InputFieldsProps) => {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [validated, setValidated] = useState(false);
    const [fieldType, setFieldType] = useState('field')
    const [mode, setMode] = useState("add") //add or edit
    const [inputFieldsForm, setInputFieldsForm] = useState<IAuthInputFields>({
        key: '',
        type: 'String',
        required: false,
        list: '',
        helpText: '',
        defaultValue: '',
        label: ""
    })
    const { formData = {} as IFormData } = props.location && props.location.state || {};
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        const { key, label, defaultValue, required, type } = formData || {};
        if (key && key !== "") {
            setMode("edit");
        }
        setInputFieldsForm(prevState => ({
            ...prevState,
            key,
            label, type, defaultValue, required

        }))
    }, [])

    function onFormSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
            const formPayload: IAuthInputFields = {
                key: inputFieldsForm.key,
                type: inputFieldsForm.type,
                required: inputFieldsForm.required,
                list: inputFieldsForm.list,
                helpText: inputFieldsForm.helpText,
                defaultValue: inputFieldsForm.defaultValue,
                label: inputFieldsForm.label
            }
            let inputFields = state.appInfo.appInfoVersion.authParams.inputFields || [];
            if (mode === "edit") {
                inputFields = inputFields.map(item => {
                    if (item._id === formData._id) {
                        return {
                            ...item,
                            ...formPayload
                        };
                    }
                    return item;
                })
            } else {
                inputFields.push(formPayload);
            }
            const payload: IAppInfoVersion = {
                ...state.appInfo.appInfoVersion,
                authParams: {
                    ...state.appInfo.appInfoVersion.authParams,
                    inputFields
                }
            }
            console.log(payload)
            const onSuccessActions = [{ type: ActionTypes.NAVIGATE_TO, payload: "back" }];
            updateAppInfoVersionAction({ dispatch, payload, onSuccessActions })
        } else {
            setValidated(true);
        }
    }

    function setFormValues(event, formInput) {
        setInputFieldsForm(prevState => ({
            ...prevState,
            [formInput]: formInput === 'required' ? (event.target.value === 'Yes' ? true: false) : event.target.value
        }))
    }

    function renderKeyFormGroup() {
        return (
            <Form.Group controlId="key">
                <Form.Label>
                    Key<span className="required">(required)</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter a unique word or phrase without spaces to reference this trigger inside Comet. Not seen by users. Example: <span className="bg-light">new_ticket</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    value={inputFieldsForm.key}
                    onChange={(e) => setFormValues(e, 'key')}
                    placeholder="Enter key" />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
        )
    }

    function renderTypeFormGroup() {
        return (
            <Form.Group controlId="type">
                <Form.Label>
                    Type <span className="optional">(optional)</span>
                </Form.Label>
                <Form.Control as="select" value={inputFieldsForm.type} custom onChange={(e) => setFormValues(e, 'type')}>
                    <option value='string'>String</option>
                    <option value='password'>Password</option>
                </Form.Control>
            </Form.Group>
        )
    }

    function renderDefaultValueFormGroup() {
        return (
            <Form.Group controlId="defaultValue">
                <Form.Label>
                    Default Value <span className="optional">(optional)</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    {'Include a default value for this field as a fallback. For optional fields, the default value is set on initial creation and used instead of missing or null values every time the Integration runs. For required fields, this value is used during Integration  creation, but not when the Integration  runs (Integration ier raises an error for missing/null values instead).'}
                </Form.Text>
                <Form.Control type="textarea"
                    value={inputFieldsForm.defaultValue}
                    onChange={(e) => setFormValues(e, 'defaultValue')}
                    placeholder="Enter default value" />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
        );
    }

    function renderGenericFields() {
        return (
            <>
                <Form.Group controlId="label">
                    <Form.Label>
                        Label
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        Enter a unique word or phrase without spaces to reference this trigger inside Comet. Not seen by users. Example: <span className="bg-light">new_ticket</span>
                    </Form.Text>
                    <Form.Control type="text"
                        value={inputFieldsForm.label}
                        onChange={(e) => setFormValues(e, 'label')}
                        placeholder="Enter key" />
                    <Form.Control.Feedback type="invalid">
                        This field is required.
                    </Form.Control.Feedback>
                </Form.Group>
                {renderKeyFormGroup()}
                <Form.Group controlId="required">
                    <Form.Label>
                        Required
                    </Form.Label>
                    <Form.Control custom
                        as="select"
                        name="required"
                        value={inputFieldsForm.required ? 'Yes': 'No'}
                        onChange={(e) => setFormValues(e, 'required')}>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                    </Form.Control>
                </Form.Group>
                {renderTypeFormGroup()}
                <Form.Group controlId="helpText">
                    <Form.Label>
                        Help Text <span className="optional">(optional)</span>
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        Explain to users what to include in this field, especially for API keys and other hard to find info. Include directions to find the data and links to your app settings or help docs. Learn more.
                    </Form.Text>
                    <Form.Control type="textarea"
                        value={inputFieldsForm.helpText}
                        onChange={(e) => setFormValues(e, 'helpText')}
                        placeholder="Enter help text" />
                    <Form.Control.Feedback type="invalid">
                        This field is required.
                    </Form.Control.Feedback>
                </Form.Group>

                {renderDefaultValueFormGroup()}
                
                <Form.Group controlId="list">
                    <Form.Label>
                        Static Dropdown
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        List the dropdown choices. Example: ["Email", "Phone", "Address"].
                    </Form.Text>
                    <Form.Control type="textarea"
                        value={inputFieldsForm.list}
                        onChange={(e) => setFormValues(e, 'list')}
                        />
                </Form.Group>
            </>);
    }

    function renderComputedGields() {
        return (
            <>
                {renderKeyFormGroup()}
                {renderTypeFormGroup()}
                {renderDefaultValueFormGroup()}
            </>);
    }

    return (
        <div className="box-container">
            <Form.Group>
                <Form.Label>Field Type</Form.Label>
                <div className="d-flex">
                    <Form.Check
                        className="mr-3"
                        type="radio"
                        value="create"
                        checked={fieldType === 'field'}
                        onChange={event => setFieldType('field')}
                        label="Field"
                        name="typeRadio"
                    />
                </div>
            </Form.Group>
            <Form noValidate validated={validated} onSubmit={onFormSubmit}>
                {fieldType === 'field' ? renderGenericFields() : renderComputedGields()}
                <div className="d-flex justify-content-end mt-4">
                    <AioButton type="button" buttonType="btn-cta2" className="mr-4 pl-4 pr-4" onClick={() => navigate(-1)}>
                        Cancel
                    </AioButton>
                    <AioButton buttonType="btn-cta1" type="submit" disabled={postLoader}>
                        { !postLoader ? 'Save and Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                    </AioButton>
                </div>
            </Form>
        </div>
    )
}

export default EditInputFields
