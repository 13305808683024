import React from "react";
import { Table, } from "react-bootstrap";
import * as IntegrationsCSS from '../../../styles/integrations.module.css'
import { ThreeDots } from "react-bootstrap-icons"
import AioDropdown from "../../../components/UI-Elements/AioDropdown"

function ConfigureFieldTable(props) {
    const { inputFields, action } = props;
    return (<>{
        inputFields.length ?
            (
                <Table responsive="sm" className={`${IntegrationsCSS.integrationsTable} no-shadow`}>
                    <thead>
                        <tr>
                            <th>Lable</th>
                            <th className={IntegrationsCSS.tableHeadVersion}>Key</th>
                            <th className={IntegrationsCSS.tableHeadStatus}>Type</th>
                            <th className={IntegrationsCSS.tableHeadAction}>Required</th>
                            <th className={IntegrationsCSS.tableHeadAction}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            inputFields.map((field, index) => {
                                return (
                                    <tr key={index} onClick={() => action("edit", field)} className={"cursor-pointer"}>
                                        <td>{field.label}</td>
                                        <td>{field.key}</td>
                                        <td>{field.type}</td>
                                        <td>{field.required ? 'Yes': 'No'}</td>
                                        <td className={IntegrationsCSS.tableBodyAction} onClick={(e) => { e.stopPropagation(); console.log("bb") }}>
                                            <AioDropdown
                                                title={<ThreeDots />}
                                                list={[{
                                                    value: 'Edit',
                                                    click: () => action("edit", field)
                                                }, {
                                                    value: 'Delete',
                                                    class: 'text-error',
                                                    click: () => action("delete", field)
                                                }]} />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            ) : (<></>)
    }
    </>);
}
export default ConfigureFieldTable;