import React, { useContext, useEffect, useState } from 'react'
import Link from "gatsby-link"

import AutomateLogo from '../../../static/images/automate-io-blue.png'
import * as NavbarCSS from '../../styles/navbar.module.css'
import { Context, GlobalContext } from '../../context/ContextStore'
import { IUser, Props } from '../../models'
import { fetchUserDetailsAction } from "../../actions"

interface NavbarProps extends Props {
    isAuthenticated: boolean
}
export default function Navbar(props: NavbarProps) {
    const { state, dispatch} = useContext(Context) as GlobalContext
    const [ user, setUser ] = useState<IUser>({ email: '', name: ''})

    useEffect(() => {
        if (props.isAuthenticated) {
            fetchUserDetailsAction({ dispatch })
        }
    }, [props.isAuthenticated])

    useEffect(() => {
        setUser(state.auth.user)
    }, [state.auth.user])

    return (
        <div className="d-flex vw-100">
            <div className={`${NavbarCSS.navbarContainer} d-flex align-items-center navbar-box-shadow w-100`}>
                <Link to={'/app'} >
                    <img src={AutomateLogo} alt="Automate Logo" className={NavbarCSS.navbarLogo}/>
                </Link>
                <Link to={'/'} className={NavbarCSS.navbarLink}>How to Build</Link>
                <Link to={'/'} className={NavbarCSS.navbarLink}>Partner Program</Link>
                <Link to={'/'} className={NavbarCSS.navbarLink}>Documentation</Link>
                <div className={NavbarCSS.rightNavbar}>
                    <Link to={'/app'} className={`${NavbarCSS.navbarLinkActive} ${NavbarCSS.navbarLink}`}>My Integrations</Link>
                    {/*<Link to={'/'} className={NavbarCSS.navbarLink}>Comet Templates</Link>*/}
                    <div className={NavbarCSS.navbarUserInfo}>
                        <div className={NavbarCSS.navbarUserImg}>{ user.name && user.name[0]}</div>
                        <div>{ user.name && (user.name.split(' ')[0] || user.name) }</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
