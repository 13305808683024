import React from "react"
import { Router } from '@reach/router'
import Triggers from './containers/Triggers'
import EditTrigger from './containers/EditTrigger'
import { Props } from "../models"

const Trigger = (props: Props) => {
    return (
        <>
            <Router>
                <Triggers path="/" />
                <EditTrigger path="new/*" />
                <EditTrigger path=":triggerId/*" />
            </Router>
        </>
    )
}

export default Trigger
