import React, { useState, useContext, useEffect} from 'react'
import Link from "gatsby-link"
import { RouteComponentProps } from "@reach/router"

import * as DashboardCSS from '../styles/dashboard.module.css'
import AppInfoList from "./components/AppInfoList"
import AioButton from "../components/UI-Elements/AioButton"
import { Context, GlobalContext } from '../context/ContextStore'
import {
    deleteIntegrationAction,
    getAppInfosAction,
    listAppInfoVersionsAction
} from "../actions"
import { IAppInfo } from '../models'

export default function Dashboard(props: RouteComponentProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [ appInfoList, setAppInfos ] = useState([] as Array<IAppInfo>)

    useEffect(() => {
        getAppInfosAction({dispatch})   
    }, [])

    useEffect(() => {
        setAppInfos(state.appInfo.appInfos)
    }, [state.appInfo.appInfos])

    function deleteIntegrationHandler(appInfoId: string) {
        deleteIntegrationAction({ dispatch: dispatch, payload: {
                appInfoId: appInfoId
            }})
    }

    function listAppInfoVersionsHandler(appInfoId: string) {
        listAppInfoVersionsAction({ dispatch: dispatch, params: appInfoId })
    }
    
    return (
        <div className={`scroll-bar-css-container ${DashboardCSS.dashboardContainer}`}>
            <div className={DashboardCSS.dashboardContainerChild}>
                <div className={DashboardCSS.newIntegrationContainer}>
                    <h3>Build a Comet Integration</h3>
                    <p>
                        Comet’s Free Developer Platform connects your API to the web’s top apps. Learn more in our Quick Start Guide.
                    </p>
                    <Link to={'/app/integration/new'} className="d-flex">
                        <AioButton buttonType="btn-cta1">
                            Start a Comet Integration
                        </AioButton>
                    </Link>
                </div>
                <AppInfoList appInfos={appInfoList}
                        listAppInfoVersionsHandler={listAppInfoVersionsHandler}
                        deleteIntegrationHandler={deleteIntegrationHandler}/>
            </div>
        </div>
    )
}
