import React, { createRef, useContext, useEffect, useState } from "react"
import { Accordion, Card, Form } from "react-bootstrap"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"

import ApiEditor from "../../../+authentication/components/common/ApiEditor"
import AioButton from "../../../components/UI-Elements/AioButton"
import { IAuthTestRequestReference } from "../../../models"
import { Context, GlobalContext } from "../../../context/ContextStore"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ConfigureAPI(props) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [toggleValue, setToggleValue] = useState(true)
    const apiEndPointRef = createRef() as IAuthTestRequestReference;
    const { requestFields, source } = props.activeAction;
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    function submitForm() {
        const activeTriggerData = { ...props.activeAction };
        const { requestFields, source } = apiEndPointRef.current.getState()
        activeTriggerData.requestFields = requestFields;
        activeTriggerData.source = source;
        //call props function
        props.updateActionsHandler(activeTriggerData);
    }

    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} onClick={() => setToggleValue(!toggleValue)} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    <h6 className="ml-2 mb-0 flex-grow-1">1. Configure your API Request</h6>
                    {toggleValue ? <ChevronDown /> : <ChevronUp />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pl-3 pr-3">
                    <div className="mt-3">
                        <Form.Label className="">API Endpoint <span className="required">required</span></Form.Label>
                        <p className="text-muted">Enter the URL Comet will poll for new items. This request must return an array.</p>
                        <ApiEditor ref={apiEndPointRef} requestFields={requestFields} sourceTemplate={source} />
                        <AioButton buttonType="btn-cta1" className="float-right" type="submit" disabled={postLoader} onClick={submitForm}>
                            { !postLoader ? 'Save API Request & Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                        </AioButton>
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}