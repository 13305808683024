import React from "react"
import { Link } from "@reach/router";
import * as NavbarCSS from '../styles/navbar.module.css'

const NavLink = props => {
    const { isLeftLink, ...filteredProps } = props
    return (<Link
      {...filteredProps}
      getProps={({ isCurrent }) => {
        // the object returned here is passed to the
        // anchor element's props
        const classes = isLeftLink ? `${NavbarCSS.navbarLeftLink} ${NavbarCSS.navbarLink}`: `${NavbarCSS.navbarLink}`
        return {
          className: isCurrent ? `${NavbarCSS.navbarLinkActive} ${classes}` : `${classes}`
        };
      }}
    />)
};

export default NavLink