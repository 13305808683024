import React from 'react'

import ConfigureFields from "../components/oauth-v2/ConfigureFields"
import RedirectURL from "../components/oauth-v2/RedirectURL"
import ApplicationCredentials from "../components/oauth-v2/ApplicationCredentials"
import EndpointConfiguration from "../components/oauth-v2/EndpointConfiguration"
import TestAuthentication from "../components/common/TestAuthentication"
import { Props } from '../../models/props'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default function OAuthV2(props: Props) {
    return (
            <>
                <h5 className="mb-3">OAuth v2</h5>
                <p className="description" style={{ display: 'flex'}}>
                    <FontAwesomeIcon icon={faInfoCircle} className="icon-primary mr-1" inverse/>
                    <span>
                        {'Oauth v2 authentication redirects users to your API’s site where they can authorize Comet to access their account which sends a request token to Comet that is exchanged for an access token.'}
                    </span>
                </p>
                <ConfigureFields />
                <RedirectURL />
                <ApplicationCredentials />
                <EndpointConfiguration />
                <TestAuthentication />
            </>
    )
}
