import React, { useState, createRef, useEffect, useContext } from "react"
import { Accordion, Card, Form } from "react-bootstrap"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"
import { IAuthTestRequestReference } from "../../../models"
import ApiEditor from "../../../+authentication/components/common/ApiEditor"
import CodeEditor from "../../../components/CodeEditor"
import AioButton from "../../../components/UI-Elements/AioButton"
import ApiKeySourceTemplate from "../../../utils/constants/apiKeySourceTemplate";
import { Context, GlobalContext } from "../../../context/ContextStore"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ConfigureAPI(props) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [toggleValue, setToggleValue] = useState(true)
    const [triggerValue, setTriggerValue] = useState('webhook');
    const [perform, setPerform] = useState(ApiKeySourceTemplate);
    //refs
    const apiEndPointRef = createRef() as IAuthTestRequestReference;
    const subscribeRef = createRef() as IAuthTestRequestReference;
    const unsubscribeRef = createRef() as IAuthTestRequestReference;
    const performListRef = createRef() as IAuthTestRequestReference;
    //data
    const { subscribe = {}, unsubscribe = {}, list = {}, requestFields = {}, source = "", type, _id } = props.activeTrigger || {};
    const { source: subscribeSource = "", ...subscribeRequestFields } = subscribe;
    const { source: unsubscribeSource = "", ...unsubscribeRequestFields } = unsubscribe;
    const { source: listSource = "", ...listRequestFields } = list;
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    //didmount
    useEffect(() => {
        if (type !== "") {
            setTriggerValue(type)
        }
    }, [type])
    useEffect(() => {
        if (source !== "") {
            //     setPerform(ApiKeySourceTemplate)
            // } else {
            setPerform(source);
        }
    }, [source]);

    function formatEditorData(data) {
        const { requestFields, mode, source } = data;
        if (mode === "code") {
            return { source }
        } else {
            return { ...requestFields };
        }
    }

    function submitForm() {
        const activeTriggerData = { ...props.activeTrigger };
        if (triggerValue === "poll") {
            activeTriggerData.requestFields = apiEndPointRef.current.getState().requestFields;
            activeTriggerData.source = apiEndPointRef.current.getState().source;
        } else {
            activeTriggerData.subscribe = formatEditorData(subscribeRef.current.getState());
            activeTriggerData.unsubscribe = formatEditorData(unsubscribeRef.current.getState());
            activeTriggerData.list = formatEditorData(performListRef.current.getState());
            activeTriggerData.source = perform;
        }
        activeTriggerData.type = triggerValue;
        props.updateApiConfiguration(activeTriggerData);
    }
    function renderPolling() {
        return (
            <>
                <div className="mb-4">
                    <Form.Label>
                        API Endpoint <span className="required">required</span>
                    </Form.Label>
                    <p className="text-muted">Enter the URL Comet will poll for new items. This request must return an array.</p>
                    <ApiEditor ref={apiEndPointRef} requestFields={requestFields} sourceTemplate={source} />
                </div>
                <div className="mb-4">
                    <Form.Label>
                        Pagination<span className="optional">optional</span>
                    </Form.Label>
                    <p className="text-muted">If the trigger will populate an Action step dynamic dropdown and can paginate data, select to let users load more data, then include the bundle.meta.page value in the API options above</p>
                </div>
            </>
        );
    }
    function renderRestHook() {
        console.log(perform)
        return (
            <>
                <div className="mb-4">
                    <Form.Label>
                        Subscribe<span className="optional">optional</span>
                    </Form.Label>
                    <p className="text-muted">Enter the endpoint URL to create a new webhook subscription</p>
                    <ApiEditor ref={subscribeRef} requestFields={subscribeRequestFields} sourceTemplate={subscribeSource} />
                </div>
                <div className="mb-4">
                    <Form.Label>
                        Unsubscribe<span className="optional">optional</span>
                    </Form.Label>
                    <p className="text-muted">Enter the endpoint URL to remove a webhook subscription.</p>
                    <ApiEditor ref={unsubscribeRef} requestFields={unsubscribeRequestFields} sourceTemplate={unsubscribeSource} />
                </div>
                <div className="mb-4">
                    <Form.Label>
                        Perform List<span className="optional">optional</span>
                    </Form.Label>
                    <p className="text-muted">Enter the endpoint URL Zapier should check for recent items, to provide sample data in the Zap Editor. This request must return an array. </p>
                    <ApiEditor ref={performListRef} requestFields={listRequestFields} sourceTemplate={listSource} />
                </div>
                <div className="mb-4">
                    <Form.Label>
                        Perform<span className="required">required</span>
                    </Form.Label>
                    <p className="text-muted">The perform function is called each time a new hook is received. Make any needed changes to the data before it's returned to the Zap. This request must return an array. </p>
                    <div style={{ height: "300px" }} className="mb-3">
                        <CodeEditor language={'javascript'} value={perform} onChange={value => setPerform(value)} />
                    </div>
                </div>
            </>
        );
    }

    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} onClick={() => setToggleValue(!toggleValue)} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    {/* <div className="chip">
                        Step 1
                    </div> */}
                    <h6 className="mb-4 flex-grow-1">1. Configure your API Request</h6>
                    {toggleValue ? <ChevronDown /> : <ChevronUp />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pl-3 pr-3">
                    <>
                        <Form.Group className="mb-4">
                            <Form.Label>
                                Trigger Type <span className="optional">optional</span>
                            </Form.Label>
                            <Form.Control as="select" custom
                                value={triggerValue}
                                onChange={e => setTriggerValue(e.target.value)}>
                                <option value='webhook'>Webhook</option>
                                <option value='poll'>Poll</option>
                            </Form.Control>
                        </Form.Group>

                        {triggerValue === "poll" ? renderPolling() : triggerValue === "webhook" ? renderRestHook() : <div />}

                        <div className="">
                            <AioButton buttonType="btn-cta1" className="float-right" disabled={postLoader} onClick={submitForm}>
                                { !postLoader ? 'Save API Request & Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                            </AioButton>
                        </div>
                    </>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}