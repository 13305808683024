import React from "react"
import { RouteComponentProps } from "@reach/router"
// import { Link } from "@reach/router"

export default function ExportProject(props: RouteComponentProps) {
	return (
		<div>
			<h5 className="mb-4">Export Project to Automate CLI</h5>
			<div className="box-container d-flex align-items-center">
				<div className="flex-grow-1">
					<h6 className="title">Set Variables for This Integration Version</h6>
					<p className="description">
                        The Automate CLI (Command Line Interface) is a toolset you install and run on your local development environment. It allows you to build, test, and manage your Comet integration through code and command line commands. Moving from the visual builder to the CLI tool brings a couple of advantages:
					</p>
                    <p className="description">
                        <ul>
                            <li>Access to every feature of the Automate Platform</li>
                            <li>Ability to better optimize your code</li>
                            <li>Easier team collaboration</li>
                            <li>Automated regression testing tools, and more</li>
                        </ul>
                    </p>
                    <p className="description">
                        To try it out, you’ll install the Automate CLI and run a command that creates a local version of your project. This will not change the existing work you've done in the visual builder, so feel free to explore. Note that versions built with the CLI can't be edited in the UI.
					</p>
                    <p className="description">
                        <a href="/">How to export your integration</a>
					</p>
				</div>
			</div>
		</div>
	)
}
