import React, { useContext, useEffect, useState } from "react"
import { RouteComponentProps, Router } from "@reach/router"

import Actions from './containers/Actions'
import EditAction from "./containers/EditAction"
import { Props } from '../models/props'
import { Context, GlobalContext } from "../context/ContextStore"
import { updateAppInfoVersionAction } from "../actions"
import { navigate } from "gatsby"
import { IAppInfoAction, IAppInfoActivity } from "../models"

interface ActionProps extends Props {
    integrationId?: string
    versionId?: string
    actionId?: string
}

const Action = (props: ActionProps) => {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [actionsList, setActionsList] = useState<Array<IAppInfoAction>>([])
    const [activeAction, setActiveAction] = useState({} as IAppInfoAction)

    useEffect(() => {
        const clonedActions = [...state.appInfo.appInfoVersion.actions]
        setActionsList(clonedActions)
        if (activeAction) {
            if (activeAction.isNew) {
                const newlyAddedAction = clonedActions.filter(eachAction => eachAction.label === activeAction.label)[0]
                if (newlyAddedAction) {
                    setActiveAction(newlyAddedAction)
                    navigate(`../../${newlyAddedAction._id}/input`)
                }
            } else {
                setActiveAction(clonedActions.filter(eachAction => eachAction._id === activeAction._id)[0])
            }
        } else {

        }
    }, [state.appInfo.appInfoVersion.actions])

    const updateAppInfoWithAction = (actionData) => {
        const clonedAppInfoVersion = { ...state.appInfo.appInfoVersion }
        const clonedActions = [...clonedAppInfoVersion.actions]
        let updatedActions
        if (actionData && actionData.isNew) {
            const { _id, isNew, ...withoutIsNew } = actionData
            updatedActions = [...clonedActions, withoutIsNew]
        } else {
            updatedActions = clonedActions.map(action => {
                if (action._id === actionData._id) {
                    return actionData
                }
                return action
            })
        }
        const updatedAppInfo = {
            ...clonedAppInfoVersion,
            actions: updatedActions
        }
        setActiveAction(actionData)
        updateAppInfoVersionAction({
            dispatch: dispatch,
            payload: updatedAppInfo
        })
    }

    const addNewActionHandler = () => {
        const newAction: IAppInfoAction = {
            isNew: true,
            type: 'create' as 'create',
            label: '',
            name: '',
            entity: '',
            description: '',
            hidden: false,
            // enableCreate: false,
            inputFields: [],
            actionId: '',
            dynamicInputFields: '',
            requestFields: {
                url: ''
            }, 
            source: '',
            staticOutputFields: [],
            dynamicOutputFields: [],
            canPaginate: false,
            priority: -1,
            _id: ''
        }
        setActiveAction(newAction)
    }

    const deleteActionHandler = (actionId: string) => {
        const clonedAppInfoVersion: any = { ...state.appInfo.appInfoVersion }
        const clonedActions = [...clonedAppInfoVersion.actions]
        const updatedAppInfo = {
            ...clonedAppInfoVersion,
            actions: clonedActions.filter(eachAction => eachAction._id !== actionId)
        }
        updateAppInfoVersionAction({
            dispatch: dispatch,
            payload: updatedAppInfo
        })
    }

    return (
        <>
            <Router>
                <Actions path="/"
                    actionsList={actionsList}
                    setActiveAction={setActiveAction}
                    versionId={props.versionId}
                    integrationId={props.integrationId}
                    addNewActionHandler={addNewActionHandler}
                    deleteActionHandler={deleteActionHandler} />
                <EditAction path="new/*"
                    activeAction={activeAction}
                    setActiveAction={setActiveAction}
                    updateAppInfoWithAction={updateAppInfoWithAction} />
                <EditAction path=":actionId/*"
                    activeAction={activeAction}
                    setActiveAction={setActiveAction}
                    updateAppInfoWithAction={updateAppInfoWithAction} />
            </Router>
        </>
    )
}

export default Action
