import React from 'react'
import { Router } from '@reach/router'
import Authentication from './containers/Authentication'
import { Props } from '../models/props'
import EditAuthentication from './containers/EditAuthentication'
import EditInputFields from './components/common/EditInputFields'

const Auth = (props: Props) => {
    return (
        <Router>
            <Authentication path="/" />
            <EditAuthentication path="edit" />
            <EditInputFields path="fields" />
        </Router>
    )
}
export default Auth