const AppInfoService = {
    // Load all appinfos
    getAppInfos: () => {
        const url = '/comet/appInfos'
        return {
            isAPI: true,
            url,
            method: "GET"
        }
    },

    getAppInfo: (appInfoId) => {
        const url = '/comet/appInfos/details?appInfoId=' + appInfoId
        return {
            isAPI: true,
            url,
            method: "GET"
        }
    },

    postAppInfo: (payload) => {
        const url = '/comet/appInfos'
        return {
            isLoading: true,
            isAPI: true,
            url,
            method: 'POST',
            data: payload
        }
    },

    putAppInfo: (payload) => {
        const url = '/comet/appInfos'
        return {
            isAPI: true,
            url,
            method: 'PUT',
            data: payload
        }
    },

    deleteAppInfo: (payload) => {
        const url = '/comet/appInfos'
        return {
            isAPI: true,
            url,
            method: 'DELETE',
            data: payload
        }
    },

    listAppInfoVersions: (appInfoId) => {
        const url = '/comet/appInfos/versions?appInfoId=' + appInfoId
        return {
            isAPI: true,
            url,
            method: 'GET'
        }
    },

    updateAppInfoVersion: (payload) => {
        const url = '/comet/appInfos/versions'
        return {
            isAPI: true,
            url,
            method: 'PUT'
        }
    },

    loadAppInfoVersion: (params) => {
        const url = `/comet/appInfos/version?appInfoId=${params.integrationId}&version=${params.appInfoVid}`
        return {
            isAPI: true,
            url,
            method: 'GET'
        }
    }
}

export default AppInfoService