import React, { useEffect } from 'react'
import Editor, { useMonaco } from "@monaco-editor/react";
// import { app } from '../utils/constants/app'

interface Props {
    language: 'javascript' | 'typescript' | 'json'
    editable?: boolean
    classes?: string
    theme?: string
    value?: any
    onChange?: (e?: any) => void
}

export default function CodeEditor(props: Props) {
    const { language, classes, theme, value } = props
    const monaco = useMonaco();
    useEffect(() => {
        // do conditional chaining
        // monaco?.languages.typescript.javascriptDefaults.setEagerModelSync(true);
        // or make sure that it exists by other ways
        if (monaco) {
            // console.log("here is the monaco instance:", monaco);
        }
    }, [monaco]);

    // console.log('Code')

    function handleEditorValidation(markers) {
        // model markers
        markers.forEach(marker => console.log("onValidate:", marker.message));
    }

    function handleChange(value: string | undefined) {
        console.log(value)
        if (props.onChange) {
            props.onChange(value)
        }
    }
    const editorValue = !value ? "" : value;
    return (
        <div className="h-100">
            <Editor
                height="100%"
                className={classes ? classes : ''}
                defaultValue="// enter code here"
                value={editorValue}
                language={language ? language : 'json'}
                // defaultLanguage='javascript'
                onValidate={handleEditorValidation}
                onChange={handleChange}
                theme={theme ? theme : 'vs-dark'}
                options={{ minimap: { enabled: false }, formatOnPaste: true, formatOnType: true }}
            />
        </div>
    )
}

export function formatJSON(val) {
    try {
        const res = JSON.parse(val);
        return JSON.stringify(res, null, 2)
    } catch {
        const errorJson = {
            "error": `${val}`
        }
        return JSON.stringify(errorJson, null, 2)
    }
}
