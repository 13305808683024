import React from 'react'
import { Redirect, RouteComponentProps, Router } from "@reach/router"
import SettingsForm from "../components/SettingsForm"
import InputDesigner from "../components/InputDesigner"
import { Props } from '../../models/props'
import * as NavbarCSS from "../../styles/navbar.module.css"
import NavLink from "../../components/NavLink"
import APIConfiguration from "../components/APIConfiguration"
import { IAppInfoTrigger } from "../../models"

interface EditTriggerProps extends Props, RouteComponentProps {
    triggerId?: string
}

const EditTrigger = (props: EditTriggerProps) => {
    const isDisableTabs = !props.triggerId;
    return (
        <>
            <h5 className="mb-2">Trigger</h5>
            <div className={`d-flex mb-4 ${NavbarCSS.navbarSubnavs}`}>
                <NavLink to="settings" isLeftLink={true}>Settings</NavLink>
                <NavLink to="input" disabled={isDisableTabs}>Input Designer</NavLink>
                <NavLink to="api" disabled={isDisableTabs}>API Configuration</NavLink>
            </div>
            <Router>
                <Redirect from="/" to="settings" noThrow />
                <SettingsForm path="settings" />
                <InputDesigner path="input" />
                <APIConfiguration path="api" />
            </Router>
        </>
    )
}

export default EditTrigger