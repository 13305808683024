import React, { useState, useContext, useEffect } from 'react'
import { Form, Row, Col } from "react-bootstrap"
import { Context, GlobalContext } from '../../context/ContextStore'
import { Props } from '../../models/props'
import AioButton from "../../components/UI-Elements/AioButton"
import { IAppInfoVersion } from '../../models'
import { updateAppInfoVersionAction } from '../../actions'

interface ContainerProps extends Props {
    integrationId?: string
    versionId?: string
}

export default function Authentication(props: ContainerProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [ authType, setAuthType ] = useState('')
    const [ isEditing, setIsEditing ] = useState(false)
    const [ isAuthSelected, setIsAuthSelected] = useState(false)

    useEffect(() => {
        const isSelected = state.appInfo.appInfoVersion.authParams.type
        if (isSelected === 'basic' || isSelected === 'OAuthV2') {
            setIsAuthSelected(true)
            setAuthType(isSelected)
        } else {
            setIsAuthSelected(false)
            setAuthType('')
        }
    }, [state.appInfo.appInfoVersion.authParams.type])

    function saveAuthentication() {
        const payload = state.appInfo.appInfoVersion as IAppInfoVersion
        if (authType) {
            // payload.appInfoVId = payload._id
            // payload.authParams.type = authType as 'basic' | 'OAuthV2'
            payload.authParams = {
                inputFields: [],
                type: authType as 'basic' | 'OAuthV2',
                customConfig: {
                    client_id: '',
                    client_secret: ''
                }
            }
            updateAppInfoVersionAction({ dispatch, payload })
            setIsEditing(prevState => !prevState)
        }
    }

    function deleteAuthentication() {
        const payload = state.appInfo.appInfoVersion as IAppInfoVersion
        // payload.appInfoVId = payload._id
        payload.authParams = {
            inputFields: [],
            type: '' as 'basic',
            customConfig: {
                client_id: '',
                client_secret: ''
            }
        } 
        updateAppInfoVersionAction({ dispatch, payload })
    }

    return (
            <Row>
                <Col md={9}>
                    <div className="box-container authentication-box">
                        <Form>
                            <div className="d-flex justify-content-between mb-4">
                                <h5 className="mb-3">Authentication</h5>
                                <div>
                                    {
                                        isEditing ? (
                                            <>
                                                <AioButton type="button" buttonType="btn-cta2" className="mr-3" onClick={() => setIsEditing(prevState => !prevState)}>
                                                    Cancel
                                                </AioButton>
                                                <AioButton type="submit" buttonType="btn-cta1" className="" onClick={saveAuthentication}>
                                                    Save
                                                </AioButton>
                                            </>
                                        ): (
                                            <>
                                                <button  className="btn-cta3" onClick={() => setIsEditing(prevState => !prevState)}>
                                                    Edit
                                                </button>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <Form.Group>
                                <Row>
                                    <Col md={5}>
                                        <Form.Label>Authentication Type</Form.Label>
                                        <div className="sub-text-normal">
                                            {'Authentication lets users prove their identity to your app and authorize Comet to access their data, using your API authentication scheme.'}
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <Form.Control as="select" custom
                                            name="authenticationCheck" 
                                            onChange={(e) => setAuthType(e.target.value)}
                                            disabled={!isEditing}
                                            value={authType}>
                                                <option value='' disabled>Select Authentication</option>
                                                <option value='basic'>Basic</option>
                                                <option value='OAuthV2'>OAuthV2</option>    
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                    </div>
                </Col>
            </Row>
    )
}
