import React, { useState, useContext, useEffect } from 'react'
import { Accordion, Card, Tab, Tabs, Form } from "react-bootstrap"
import { ChevronDown } from "react-bootstrap-icons"
import { Props } from '../../../models/props'
import AioButton from "../../../components/UI-Elements/AioButton"
import { Context, GlobalContext } from '../../../context/ContextStore'
import { IUserapp, ITestRequest } from '../../../models'
import { loadUserappAction, testComponentAction, clearTestDataAction } from '../../../actions'

interface TestProps extends Props {
    indexValue?: number
    module?: string
}

let popups = [] as Array<Window>
export function openPopup(uri: string) {
    for (const p of popups) {
        p.close()
    }
    popups = []
    const left = screen.width / 2 - 300
    const top = screen.height / 2 - 400
    const firefoxProps = 'toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=1,resizable=1'
    const pos = `left=${left},top=${top},width=900,height=600,${firefoxProps}`
    const popup = open(uri, '', pos)
    if (popup) {
        popups.push(popup)
    }

    return popup
}

export default function TestAuthentication({ indexValue, module }: TestProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const { appInfoVersion } = state.appInfo
    const [ userapps, setUserApps ] = useState<Array<IUserapp>>([])
    const [ userapp, setUserApp ] = useState<IUserapp>()

    useEffect(() => {
        loadUserappAction({ dispatch, params: appInfoVersion._id })
        return function cleanup() {
            clearTestDataAction
        }
    }, [])

    useEffect(() => {
        setUserApps(state.common.userapps)
        if (state.common.userapps && state.common.userapps.length) {
            setUserApp(state.common.userapps[0])
        }
    }, [state.common.userapps])

    function selectUserapp(id: string) {
        const userappSelected = userapps.filter(u => u._id === id)[0]
        if (userappSelected) {
            setUserApp(userappSelected)
        }
    }

    function addUserapp() {
        const uri = `${process.env.GATSBY_AUTOMATE_URL}/user-app/add/${appInfoVersion.appInfoId}`
        openPopup(uri)
    }

    function testAuthentication() {
        if (userapp) {
            const payload: ITestRequest = {
                appInfoVid: appInfoVersion._id,
                userappId: userapp._id,
                componentKey: 'test',
                componentType: 'authentication',
                testComponent: {
                    requestFields: appInfoVersion.authParams.requestFields || null,
                    source: appInfoVersion.authParams.source || null
                }
            }
            // fire test authentication action
            testComponentAction({ dispatch, payload })
        }
    }

    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    {/* <div className="chip">
                        Step {indexValue ? indexValue : 3}
                    </div> */}
                    <h6 className="ml-2 mb-0 flex-grow-1">{indexValue ? `${indexValue}. ` : '3. '}Test your {module || 'Authentication'}</h6>
                    <ChevronDown />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pr-3">
                    <div className="border m-4 p-3">
                        <Tabs defaultActiveKey="testSetup" className="border border-top-0 mt-2 w-100 ml-0 border-left-0 border-right-0">
                            <Tab eventKey="testSetup" title="Test Setup">
                                <div className="mt-4 mb-4 pl-2 pr-2">
                                    <Form.Label>Test your {module || 'Authentication'} Configuration</Form.Label>
                                    <p className="text-muted">Connect to your app using the above settings with a personal or testing account, and Comet will use this connection to test API calls as you build the integration.</p>
                                    {
                                        userapps && userapps.length > 0 ? (<div>
                                            <Form.Control as="select" name="userApps" onChange={(e)=>selectUserapp(e.target.value)}>
                                                {
                                                    userapps.map((u, index) => (
                                                        <option key={`userapp_${index}`} value={u._id}>{u.name}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </div>):
                                        (
                                            <div>
                                                <Form.Label>Add an Input Field in Step 1 to test your {module?.toLowerCase() || 'authentication'}.</Form.Label>
                                                <AioButton buttonType="btn-cta1" className="ml-3" onClick={addUserapp}>
                                                    Add an Userapp
                                                </AioButton>
                                            </div>
                                        )
                                    }
                                </div>
                                {/* <div className="border-top pt-3 pl-3 d-flex align-items-center">
                                    <span className="flex-grow-1" />
                                    <AioButton buttonType="btn-cta2" className="mr-3" onClick={testAuthentication}>
                                        Test Authentication
                                    </AioButton>
                                </div> */}
                            </Tab>
                            <Tab eventKey="response" title="Response">
                                <div className="mt-3 pl-2 pr-2">
                                    <p className="pt-3">No test request made yet. Click "Test Setup" and "Try it!" at the bottom to make a test request and see its response here.</p>
                                    <AioButton buttonType="btn-cta2">
                                        Test Again
                                    </AioButton>
                                </div>
                            </Tab>
                            <Tab eventKey="bundle" title="Bundle">
                                <div className="mt-3 pl-2 pr-2">
                                    <p className="pt-3">No logs to show.</p>
                                    <AioButton buttonType="btn-cta2">
                                        Test Again
                                    </AioButton>
                                </div>
                                {/* <div className="border-top mt-4 pt-3 pl-3 d-flex align-items-center">
                                    
                                    <span className="flex-grow-1" />
                                    <AioButton buttonType="btn-cta2" className="mr-3" onClick={testAuthentication}>
                                        Test Authentication
                                    </AioButton>
                                </div> */}
                            </Tab>
                            <Tab eventKey="http" title="HTTP">
                                <div className="mt-3 pl-2 pr-2">
                                    <p className="pt-3">No logs to show.</p>
                                    <AioButton buttonType="btn-cta2">
                                        Test Again
                                    </AioButton>
                                </div>
                                
                            </Tab>
                            <Tab eventKey="console" title="Console">
                                <div className="mt-3 pl-2 pr-2">
                                    <p className="pt-3">No logs to show.</p>
                                    <AioButton buttonType="btn-cta2">
                                        Test Again
                                    </AioButton>
                                </div>
                                {/* <div className="border-top mt-4 pt-3 pl-3 d-flex align-items-center">
                                    
                                    <span className="flex-grow-1" />
                                    <AioButton buttonType="btn-cta2" className="mr-3">
                                        Test Authentication
                                    </AioButton>
                                </div> */}
                            </Tab>
                        </Tabs>
                        <div className="border-top mt-4 pt-3 pl-3 d-flex align-items-center">
                            <span className="flex-grow-1" />
                            <AioButton buttonType="btn-cta2" className="mr-3" onClick={testAuthentication}>
                                Test Authentication
                            </AioButton>
                        </div>
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
