import React from "react"
import { Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTimes, faCog, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"

export default function TriggersList({ triggersList, onRowSelect, onDelete }) {

    function handleRowClick(triggerId) {
        onRowSelect(triggerId)
    }

    return (
        <>
            {triggersList.length && <Table responsive="sm" hover className="box-container mt-4">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Key</th>
                        <th>Source</th>
                        {/* <th>Important</th> */}
                        <th>Hidden</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        triggersList.length ? triggersList.map((trigger, index) => {
                            const { name, label, type } = trigger;
                            return (
                                <tr key={index} className="cursor-pointer" onClick={() => handleRowClick(trigger._id)}>
                                    <td>{name}</td>
                                    <td>{label}</td>
                                    <td>{type === "webhook" ? "Webhook" : type === "poll" ? "Polling" : "-"}</td>
                                    {/* <td>{trigger.important ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td> */}
                                    <td>{trigger.hidden ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
                                    <td onClick={e => e.stopPropagation()}>
                                        <div onClick={() => onDelete(trigger._id)}>
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) :
                            (<tr>
                                <td>
                                    There are no Integrations currently.
                                </td>
                            </tr>)
                    }
                </tbody>
            </Table>}
        </>
    )
}