import { types as ActionTypes } from '../../actions'
import { IUserapp } from '../../models'

export interface ICommonState {
    userapps: Array<IUserapp>
    isLoading: boolean
    test: {
        logs: any[],
        response: any
    }
}

export const commonInitialState: ICommonState = {
    userapps: [],
    isLoading: false,
    test: {
        logs: [],
        response: {}
    }
}

export const commonReducer = (state = commonInitialState , action) => {
    // console.log(state, action);
    switch(action.type) {
        case ActionTypes.LOAD_USERAPPS: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ActionTypes.LOAD_USERAPPS_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ActionTypes.LOAD_USERAPPS_SUCCESS: {
            const payload = action.payload as Array<IUserapp>
            return {
                ...state,
                userapps: payload
            }
        }
        case ActionTypes.TEST_COMPONENT_SUCCESS: {
            const payload = action.payload
            return {
                ...state,
                test: {
                    logs: payload.logs,
                    response: payload.data
                }
            }
        }
        case ActionTypes.CLEAR_TESTDATA: {
            return {
                ...state,
                test: {
                    logs: [],
                    response: {}
                }
            }
        }
        default:
            return state
    }
}