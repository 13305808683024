// extracted by mini-css-extract-plugin
export var rightNavContainer = "rightnav-module--right-nav-container--VX-IL";
export var rightNavIconsContainer = "rightnav-module--right-nav-icons-container--3pkbT";
export var rightNavIcon = "rightnav-module--right-nav-icon--1aBXz";
export var rightNavDetails = "rightnav-module--right-nav-details--15syI";
export var topContainer = "rightnav-module--top-container--Rie92";
export var detailsTitle = "rightnav-module--details-title--2zX9N";
export var validationHeader = "rightnav-module--validation-header--M0BPK";
export var validationProgressInfo = "rightnav-module--validation-progress-info--Azkqp";
export var eachDetailContainer = "rightnav-module--each-detail-container--3PfGM";
export var eachDetailLeftContainer = "rightnav-module--each-detail-left-container--URh2G";
export var eachDetailRightContainer = "rightnav-module--each-detail-right-container--2806H";
export var toDoTitle = "rightnav-module--to-do-title--2BzS0";
export var rightNavFooter = "rightnav-module--right-nav-footer--3epHX";
export var footerEachLink = "rightnav-module--footer-each-link--2DSWU";