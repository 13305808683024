import React from 'react'
import ConfigureFields from "../components/api-key/ConfigureFields"
import ConfigureTest from "../components/api-key/ConfigureTest"
import TestAuthentication from "../components/common/TestAuthentication"
import { Props } from '../../models/props'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default function APIKey(props: Props) {
    return (
            <>
                <h5 className="mb-3">API Key</h5>
                <p className="description"  style={{ display: 'flex'}}>
                    <FontAwesomeIcon icon={faInfoCircle} className="icon-primary mr-1" inverse/>
                    <span>
                        {'API Key Auth lets you build a form to request an API key, along with any additional fields your API requires for authentication. Comet then passes the data users enter in those fields with every API call.'}
                    </span>
                </p>
                <ConfigureFields />
                <ConfigureTest />
                <TestAuthentication />
            </>
    )
}
