import React, { useContext, useState, useEffect } from 'react'
import CodeEditor, { formatJSON } from '../../components/CodeEditor'
import { Row, Col } from 'react-bootstrap'
import { RouteComponentProps } from '@reach/router'
import { Context, GlobalContext } from '../../context/ContextStore'
import AioButton from "../../components/UI-Elements/AioButton"
import { IAppInfoVersion } from '../../models'

interface Props extends RouteComponentProps {}

export default function Source(props: Props) {
    const { state, dispatch} = useContext(Context) as GlobalContext
    const [ appSource, setAppSource ] = useState('')

    useEffect(() => {
        setAppSource(formatJSON(JSON.stringify(state.appInfo.appInfoVersion)))
    }, [state.appInfo.appInfoVersion])

    return (
        <div>
            <h5 className="mb-4">Source Definition</h5>
            <Row>
                <Col md={8}>
                    <div className="box-container code-background mb-3" style={{ height: "60vh"}}>
                        <CodeEditor language="json" value={appSource} />
                    </div>
                    <div className="d-flex">
                        <AioButton buttonType="btn-cta1" className="mr-3">
                            Import
                        </AioButton>
                    </div>
                </Col>
                <Col md={4}>
                    <p>
                        This is the source definition of the selected version of your integration. Copy this into your favorite diff tool to compare changes from a previous version.
                        <br/><br/>
                        To learn more about this structure see the schema definition.
                        <br/><br/>
                        Want to edit this code directly instead of working through the UI? Have a look at the Automate CLI!
                    </p>
                </Col>
            </Row>
        </div>
    )
}
