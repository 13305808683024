import React from "react"
import { Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"

export default function InputFieldsList({ inputFields, deleteInputFieldHandler, editInputFieldHandler }) {
    return (
        <Table responsive="sm" className="box-container mt-3" hover>
            <thead>
                <tr>
                    <th>Label</th>
                    <th>Key</th>
                    <th className="input-fields-delete-row" />
                </tr>
            </thead>
            <tbody>
                {inputFields.map((eachInput, index) => {
                    return (
                        <tr key={index} className="cursor-pointer" onClick={() => editInputFieldHandler(eachInput._id)}>
                            <td>{eachInput.label}</td>
                            <td>{eachInput.key}</td>
                            <td className="input-fields-delete-row" onClick={e => e.stopPropagation()}>
                                <div onClick={() => deleteInputFieldHandler(eachInput._id)}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
