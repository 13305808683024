import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { Accordion, Button, Card, Form, Tab, Tabs } from "react-bootstrap"
import AioButton from "../../../components/UI-Elements/AioButton"
import { ChevronDown, ChevronUp, CodeSlash, XLg, Dash, Plus } from "react-bootstrap-icons"
import { IAuthInputRequestFields } from "../../../models";
import CodeEditor from "../../../components/CodeEditor";
import ApiKeySourceTemplate from "../../../utils/constants/apiKeySourceTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const getDefaultState = () => ({
    id: new Date().getTime(),
    key: '',
    value: ''
})
interface ITestRequestFormProps {
    sourceTemplate?: string;
    requestFields?: IAuthInputRequestFields;
    mode?: string;
}
interface IParamsInterface {
    id: number;
    key: string;
    value: string;
}
function ApiEditor(props: ITestRequestFormProps, ref) {
    const [httpUrl, setHttpUrl] = useState("");
    const [isExpanded, setIsExpanded] = useState(false)
    const [optionValue, setOptionValue] = useState('get')
    const [activeTab, setActiveTab] = useState("urlParams");
    const [mode, setMode] = useState("form");
    const [source, setSource] = useState("");
    const [URLParams, setURLParams] = useState([getDefaultState()])
    const [HTTPHeaders, setHTTPHeaders] = useState([getDefaultState()])
    const [requestBody, setRequestBody] = useState([getDefaultState()])
    //if form data is present load that;
    const { sourceTemplate = "" } = props;
    const { url = "", method = "get", params = {}, body = {}, headers = {} } = props.requestFields || {};
    const bodyDepedency = JSON.stringify(body);
    const paramsDepedency = JSON.stringify(params);
    const headersDepedency = JSON.stringify(headers);
    useEffect(() => {
        //form data
        setHttpUrl(url);
        setOptionValue(method);
        setURLParams(deconstructData(params));
        setRequestBody(deconstructData(body));
        setHTTPHeaders(deconstructData(headers));
        //source data
        if (sourceTemplate && sourceTemplate !== "") {
            setMode("code");
            setSource(sourceTemplate)
        } else {
            setSource(ApiKeySourceTemplate);
        }

    }, [url, method, bodyDepedency, paramsDepedency, headersDepedency, sourceTemplate]);

    //converts the list of object to single object in order to match with request payload
    const constructData = (data) => {
        return data.reduce((acc, item) => {
            if (item.key !== "" && item.value !== "") {
                acc[item.key] = item.value;
            }
            return acc;
        }, {})
    }
    //converts the object to list of object as need by UI to paint
    const deconstructData = (object) => {
        const result = Object.keys(object).reduce((acc: IParamsInterface[], item, idx) => {
            let obj: IParamsInterface = {
                "id": new Date().getTime() + idx,
                key: item,
                value: object[item]
            };
            acc.push(obj);
            return acc;
        }, []);
        return result.length ? result : [getDefaultState()]
    }
    //this is being used to access the state data in parent component using forward ref
    useImperativeHandle(ref, () => ({
        getState: () => {
            const requestFields: IAuthInputRequestFields = {
                params: {},
                headers: {},
                body: {},
                url: "",
                method: "",
            }
            let sourceCode = "";
            if (mode === "form") {
                requestFields.url = httpUrl;
                requestFields.method = optionValue;
                requestFields.params = constructData(URLParams);
                requestFields.headers = constructData(HTTPHeaders);
                requestFields.method !== "get" && (requestFields.body = constructData(requestBody));
            } else {
                sourceCode = source;
            }

            return { requestFields, source: sourceCode, mode };
        },
    }))

    function addNewParam(type) {
        const newField = getDefaultState();
        if (type === 'URLParams') {
            setURLParams([...URLParams, newField])
        } else if (type === 'HTTPHeaders') {
            setHTTPHeaders([...HTTPHeaders, newField])
        } else if (type === 'requestBody') {
            setRequestBody([...requestBody, newField])
        }
    }

    function handleOptionValueChange(event, type, id) {
        let data = type === 'URLParams' ?
            URLParams : type === 'HTTPHeaders' ?
                HTTPHeaders : type === 'requestBody' ?
                    requestBody : null;
        if (!data) {
            return;
        }
        const updatedData = data.map(item => {
            if (item.id === id) {
                item[event.target.name] = event.target.value;
            }
            return item;
        });
        if (type === 'URLParams') {
            setURLParams(updatedData)
        } else if (type === 'HTTPHeaders') {
            setHTTPHeaders(updatedData)
        } else if (type === 'requestBody') {
            setRequestBody(updatedData)
        }
    }

    function removeParam(type, id) {
        if (type === 'URLParams') {
            setURLParams(URLParams.filter(eachParam => eachParam.id !== id))
        } else if (type === 'HTTPHeaders') {
            setHTTPHeaders(HTTPHeaders.filter(eachParam => eachParam.id !== id))
        } else if (type === 'requestBody') {
            setRequestBody(requestBody.filter(eachParam => eachParam.id !== id))
        }
    }
    //if selected tab is body and method is "get" then switch back to default tab
    function handleMethodChange(event) {
        const { value } = event.target;
        if (value === "get" && activeTab === "requestBody") { //request body tab is disabled for get request
            setActiveTab("urlParams")
        }
        setOptionValue(event.target.value)
    }
    function renderUrlParamsTab() {
        return (
            <Tab eventKey="urlParams" title="Params">
                <div className="mt-4">
                    {URLParams.map(eachParam => (
                        <div className="d-flex align-items-center" key={eachParam.id}>
                            <Form.Control type="text" className="w-25 bg-light no-border-radius" placeholder="key" name="key" value={eachParam.key} onChange={(e) => handleOptionValueChange(e, "URLParams", eachParam.id)} />
                            <Form.Control type="text" className="mr-3 no-border-radius" placeholder="value" name="value" value={eachParam.value} onChange={(e) => handleOptionValueChange(e, "URLParams", eachParam.id)} />
                            <XLg size={16} className="flex-shrink-0 cursor-pointer" onClick={() => removeParam('URLParams', eachParam.id)} />
                        </div>)
                    )}
                    {/* <AioButton buttonType="btn-cta2" className="mb-2" >
                        Add
                    </AioButton> */}
                    <button className="mb-2 d-flex btn-add mt-3" onClick={() => addNewParam('URLParams')}>
                        <FontAwesomeIcon className="icon mr-1" icon={faPlusCircle}/>
                        <span>Add</span>
                    </button>
                </div>
            </Tab>
        )
    }

    function renderHttpHeadersTab() {
        return (
            <Tab eventKey="httpHeaders" title="Headers">
                <div className="mt-4">
                    {HTTPHeaders.map(eachParam => (
                        <div className="d-flex align-items-center" key={eachParam.id}>
                            <Form.Control type="text" className="w-25 bg-light no-border-radius" placeholder="key" name="key" value={eachParam.key} onChange={(e) => handleOptionValueChange(e, "HTTPHeaders", eachParam.id)} />
                            <Form.Control type="text" className="mr-3 no-border-radius" placeholder="value" name="value" value={eachParam.value} onChange={(e) => handleOptionValueChange(e, "HTTPHeaders", eachParam.id)} />
                            <XLg size={16} className="flex-shrink-0 cursor-pointer" onClick={() => removeParam('HTTPHeaders', eachParam.id)} />
                        </div>
                    )
                    )}
                    {/* <AioButton buttonType="btn-cta2" className="mb-2" onClick={() => addNewParam('HTTPHeaders')}>
                        Add
                    </AioButton> */}
                    <button className="mb-2 d-flex btn-add mt-3" onClick={() => addNewParam('HTTPHeaders')}>
                        <FontAwesomeIcon className="icon mr-1" icon={faPlusCircle}/>
                        <span>Add</span>
                    </button>
                </div>
            </Tab>)
    }

    function renderRequestBodyTab() {
        return (
            <Tab eventKey="requestBody" title="Body" disabled={['get', 'head'].includes(optionValue)}>
                <div className="mt-4">
                    {requestBody.map(eachParam => (
                        <div className="d-flex align-items-center" key={eachParam.id}>
                            <Form.Control type="text" className="w-25 bg-light no-border-radius" placeholder="key" name="key" value={eachParam.key} onChange={(e) => handleOptionValueChange(e, "requestBody", eachParam.id)} />
                            <Form.Control type="text" className="mr-3 no-border-radius" placeholder="value" name="value" value={eachParam.value} onChange={(e) => handleOptionValueChange(e, "requestBody", eachParam.id)} />
                            <XLg size={16} className="flex-shrink-0 cursor-pointer" onClick={() => removeParam('requestBody', eachParam.id)} />
                        </div>)
                    )}
                    {/* <AioButton buttonType="btn-cta2" className="mb-2" onClick={() => addNewParam('requestBody')}>
                        Add
                    </AioButton> */}
                    <button className="mb-2 d-flex btn-add mt-3" onClick={() => addNewParam('requestBody')}>
                        <FontAwesomeIcon className="icon mr-1" icon={faPlusCircle}/>
                        <span>Add</span>
                    </button>
                </div>
            </Tab>
        )
    }

    function renderOptionsTab() {
        return (
            <Tab eventKey="options" title="Options">
                <div className="mt-4">
                    <Form.Check
                        className="mb-2 mt-6"
                        type="switch"
                        id="omit-params"
                        label="Automatically omit parameters that are empty"
                    />
                    <Form.Check
                        type="switch"
                        id="omit-fields"
                        label="Automatically omit fields in the request body that are empty"

                    />
                </div>
            </Tab>
        )
    }

    return (
        <div className="box-container shadow-none border p-0" id="test-request-form">
            <div className="" style={{ padding: "15px 20px 0px 15px" }}>
                {mode === "form" ?
                    <Accordion activeKey={isExpanded ? "0" : ""} >
                        <Card className="border-0">
                            <Card.Header className="bg-white d-flex align-items-center border-0 p-0 mb-3">
                                <Form.Group className="d-flex flex-grow-1 mb-0">
                                    <Form.Control as="select" value={optionValue} onChange={handleMethodChange} className="mr-2" style={{ width: '100px' }} custom>
                                        <option value="get">GET</option>
                                        <option value="put">PUT</option>
                                        <option value="post">POST</option>
                                        <option value="patch">PATCH</option>
                                        <option value="delete">DELETE</option>
                                        <option value="head">HEAD</option>
                                    </Form.Control>
                                    <Form.Control type="text" placeholder="ex: https://www.example.com/" value={httpUrl} onChange={(e) => setHttpUrl(e.target.value)} />
                                </Form.Group>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={(e) => setIsExpanded(!isExpanded) } >
                                    <div className="d-flex align-items-center text-muted">
                                        {isExpanded ? 'Options' : 'Options'}
                                        {isExpanded ? <Dash className="ml-2" /> : <Plus className="ml-2" />}
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <div className="w-75 border box-container no-shadow mb-3">
                                    <Tabs defaultActiveKey="urlParams" activeKey={activeTab} onSelect={key => setActiveTab(key || "urlParams")} className={`border border-top-0 mt-2 w-100 ml-0 border-left-0 border-right-0`}>
                                        {renderUrlParamsTab()}
                                        {renderHttpHeadersTab()}
                                        {renderRequestBodyTab()}
                                        {renderOptionsTab()}
                                    </Tabs>
                                </div>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    : <div style={{ height: "300px" }} className="mb-3">
                        <CodeEditor language={'javascript'} value={source} onChange={(e) => setSource(e)} />
                    </div>}
            </div>
            <div className={"switch-to-code btn-cta3"} onClick={() => setMode(mode === "form" ? "code" : "form")}>
                {mode === "code" ? "Switch to Form Mode" : "Switch to Code Mode"}
            </div>

        </div>)
}

export default forwardRef(ApiEditor);