import * as ActionTypes from './types'
import CommonService from "../services/commonService"
import { IActionPayload } from '../models/actions'

export function loadUserappAction(actionPayload: IActionPayload) {
    const { dispatch, params } = actionPayload
    return dispatch({
        type: ActionTypes.LOAD_USERAPPS,
        payload: {
            dispatch,
            ...CommonService.getUserapps(params)
        }
    })
}

export function removeNotificationAction(actionPayload: IActionPayload) {
    const { dispatch, payload } = actionPayload
    return dispatch({
        type: ActionTypes.DELETE_NOTIFICATION,
        payload: {
            index: payload || 0,
            delay: true
        }
    })
}

// export function addNotificationAction(actionPayload: IActionPayload) {
//     const { dispatch, payload, onSuccessActions = [] } = actionPayload
//     return dispatch({
//         type: ActionTypes.ADD_NOTIFICATION,
//         payload: {
//             index: payload || 0,
//             onSuccessActions: []
//         }
//     })
// }

export function testComponentAction(actionPayload: IActionPayload) {
    const { dispatch, payload } = actionPayload
    return dispatch({
        type: ActionTypes.TEST_COMPONENT,
        payload: {
            dispatch,
            ...CommonService.testComponent(payload)
        }
    })
}

export function clearTestDataAction(actionPayload: IActionPayload) {
    const { dispatch } = actionPayload
    return dispatch({
        type: ActionTypes.CLEAR_TESTDATA,
    })
}