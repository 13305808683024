import React, { useState, createRef, useContext, useEffect } from "react"
import { Accordion, Card, Form, Spinner } from "react-bootstrap"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"
import { IAppInfoVersion, IAuthInputRequestFields, IAuthTestRequestReference } from '../../../models'
import { Context, GlobalContext } from '../../../context/ContextStore';
import AioButton from "../../../components/UI-Elements/AioButton";
import { updateAppInfoVersionAction } from '../../../actions';
import CodeEditor from "../../../components/CodeEditor";
import ApiEditor from "../common/ApiEditor";
import ApiKeySourceTemplate from "../../../utils/constants/apiKeySourceTemplate";
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ConfigureTest() {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [toggleValue, setToggleValue] = useState(true)
    const [showEditor, setShowEditor] = useState(false);
    const testRequestFormRef = createRef() as IAuthTestRequestReference;
    const { appInfo: { appInfoVersion: { authParams: { requestFields, source } } } } = state;
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    function submitForm() {
        const { requestFields, source, mode } = testRequestFormRef!.current!.getState();
        const payload: IAppInfoVersion = {
            ...state.appInfo.appInfoVersion,
            authParams: {
                ...state.appInfo.appInfoVersion.authParams,
                requestFields,
                source,
            }
        }
        console.log(payload)
        updateAppInfoVersionAction({ dispatch, payload })
    }

    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} onClick={() => setToggleValue(!toggleValue)} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    {/* <div className="chip">
                        Step 2
                    </div> */}
                    <h6 className="mb-3 flex-grow-1">{'2. Configure a Test Request & Connection Label'}</h6>
                    {toggleValue ? <ChevronDown /> : <ChevronUp />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pl-3 pr-3">
                    <>
                        <div className="mb-3">
                            <p className="description">
                                Add a simple API endpoint to test user credentials. Comet includes data from your input form in the URL Params by default; click Show Options to customize the API call if your API expects them in the header instead.
                            </p>
                        </div>
                        <Form.Label>
                            Test <span className="required">required</span>
                        </Form.Label>
                        <p className="text-muted">Enter an API endpoint URL to test authentication credentials, ideally one needing no configuration such as /me.</p>
                        <ApiEditor
                            ref={testRequestFormRef}
                            sourceTemplate={source}
                            requestFields={requestFields}

                        />
                        <Form.Label className="mt-3">
                            Connection Label <span className="optional">optional</span>
                        </Form.Label>
                        {!showEditor ? (<div className="d-flex">
                                <Form.Control type="text" className="flex-grow-1" placeholder="Enter Connection Label" />
                                <AioButton buttonType="btn-cta2 ml-3" className="flex-shrink-0" onClick={() => setShowEditor(true)}>
                                    Edit Code
                                </AioButton>
                            </div>) :
                            (<div className="box-container shadow-none border p-0">
                                <div style={{ height: "120px" }}>
                                    <CodeEditor language="json" value="" />
                                </div>
                                <div className={"m-3"}>
                                    <AioButton buttonType="btn-cta1" onClick={() => setShowEditor(false)}>
                                        Delete code
                                    </AioButton>
                                    <AioButton buttonType="btn-cta2" className="ml-3">
                                        Undo
                                    </AioButton>
                                </div>
                            </div>)}
                        <div className="mt-3">
                            {/*<AioButton buttonType="btn-cta1" className="float-right" onClick={submitForm}>*/}
                            {/*    Continue*/}
                            {/*</AioButton>*/}
                            <AioButton buttonType="btn-cta1" className="float-right" disabled={postLoader} onClick={submitForm}>
                                { !postLoader ? 'Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                            </AioButton>
                        </div>
                    </>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}