import React from "react"
import { RouteComponentProps } from "@reach/router"
// import { Link } from "@reach/router"

export default function EnvironmentVariables(props: RouteComponentProps) {
	return (
		<div>
			<h5 className="mb-4">Environment Variables</h5>
			<div className="box-container d-flex align-items-center">
				<div className="flex-grow-1">
					<h6 className="title">Set Variables for This Integration Version</h6>
					<p className="description">
						Use environment variables to set secret values (such as API keys or client IDs and secrets), or to manage configurations and switch between staging and production environments. Environment variables are set per version. Learn more.
					</p>
				</div>
			</div>
		</div>
	)
}
