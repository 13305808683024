import React, { useContext, useEffect, useState } from "react"
import { Form, Spinner } from "react-bootstrap"

// import CodeEditor from "../../../components/CodeEditor"
import AioButton from "../../../components/UI-Elements/AioButton"
import { Context, GlobalContext } from "../../../context/ContextStore"
import { Props, IAppInfoTrigger, IActivityInputFields } from "../../../models"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface IActionInputFields extends IActivityInputFields {
    inputDesignerType?: string
    id?: number
    isNew?: boolean
    multipleInputs?: Array<{id: string, value: string}>
}

interface InpuFieldFormEditorProps extends Props {
    inputFieldList: Array<IActionInputFields>
    setInputFieldList: React.Dispatch<React.SetStateAction<IActionInputFields[]>>
    activeInputField: IActionInputFields
    newInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, value: string, inputId) => void
    handleInputFieldSubmit: (event: Event) => void
    validated: boolean
    setValidated: React.Dispatch<React.SetStateAction<boolean>>
    setToggleAddInput: React.Dispatch<React.SetStateAction<boolean>>
    setDropdownType
}
export default function InputFieldFormEditor({ inputFieldList, setInputFieldList, activeInputField, newInputChange, handleInputFieldSubmit, validated, setValidated, setToggleAddInput, setDropdownType }: InpuFieldFormEditorProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [triggersList, setTriggersList] = useState<Array<IAppInfoTrigger>>([])
    const [formLabelValid, setFormLabelValid] = useState(true)
    const [labelErrorTxt, setLabelErrorTxt] = useState("This field is required.")
    const [postLoader, setPostLoader] = useState(false)

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        setTriggersList(state.appInfo.appInfoVersion.triggers)
    }, [state.appInfo.appInfoVersion])

    const handleFormSubmit = (event) => {
        setValidated(true)
        event.preventDefault()
        event.stopPropagation()
        if (event.currentTarget.checkValidity()) {
            // Check if form data label is available in actions triggers list
            const filteredList = inputFieldList.filter(eachAction => eachAction.label === activeInputField.label)
            if (filteredList.length > 1) {
                setFormLabelValid(false)
                setLabelErrorTxt("Choose a unique label.")
                window.scrollTo({top: 0, behavior: 'smooth'});
            } else {
                handleInputFieldSubmit(event)
            }
        } else {
            console.log('error')
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleFormSubmit} className="box-container">
            <Form.Group controlId="newInputKey">
                <Form.Label>
                    Key<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter the word or phrase your API uses to reference this field or parameter. Not seen by users. Example: <span className="bg-light px-2">first_name</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    placeholder="Enter key"
                    value={activeInputField.key}
                    onChange={e => newInputChange(e, 'key', activeInputField._id)} />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newInputLabel">
                <Form.Label>
                    Label<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter a user friendly name for this field that describes what to enter. Shown to users inside Comet. Example: <span className="bg-light px-2">First Name</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    placeholder="Enter label"
                    value={activeInputField.label}
                    isInvalid={!formLabelValid}
                    onChange={e => {
                        newInputChange(e, 'label', activeInputField._id);
                        setFormLabelValid(true);
                        setLabelErrorTxt('This field is required')
                    }} />
                <Form.Control.Feedback type="invalid">
                    {labelErrorTxt}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newInputHelp">
                <Form.Label>
                    Help Text<span className="optional">optional</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Describe clearly the purpose of this field in a complete, Markdown formatted sentence with at least 20 characters. Example: <span className="bg-light px-2">Filter by first name.</span>
                </Form.Text>
                <Form.Control as="textarea"
                    rows={3}
                    type="text"
                    placeholder="Enter help text"
                    value={activeInputField.description}
                    onChange={e => newInputChange(e, 'description', activeInputField._id)} />
            </Form.Group>
            <Form.Group controlId="newInputHelp">
                <Form.Label>
                    Type<span className="optional">optional</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    What type of data does this field collect?
                </Form.Text>
                <Form.Control as="select" custom
                    value={activeInputField.type}
                    onChange={e => newInputChange(e, 'type', activeInputField._id)}>
                    <option value='String'>String</option>
                    <option value='Number'>Number</option>
                    <option value='Date'>Date</option>
                    <option value='Password'>Password</option>
                    <option value='Subdomain'>Subdomain</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="newInputLabel">
                <Form.Label>
                    Default Text <span className="optional">optional</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    If most users need the same option, add default text that Comet will save when the Integration is created if the user leaves it blank.
                </Form.Text>
                <Form.Control type="text"
                    placeholder="Enter default text."
                    value={activeInputField.defaultValue}
                    onChange={e => newInputChange(e, 'defaultValue', activeInputField._id)} />
            </Form.Group>
            <h6 className="mt-4 mb-3">Options</h6>
            <Form.Group controlId="newInputRequired">
                <Form.Label>
                    Required<span className="optional">optional</span>
                </Form.Label>
                <Form.Control as="select" custom
                    value={activeInputField.required ? 'required' : 'optional'}
                    onChange={e => newInputChange(e, 'required', activeInputField._id)}>
                    <option value='required'>Yes</option>
                    <option value='optional'>No</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="newInputAllowMultiples">
                <Form.Label>
                    Allow Multiples<span className="optional">optional</span>
                </Form.Label>
                <Form.Control as="select" custom
                    value={['multiInput', 'multiSelect'].includes(activeInputField.inputType) ? 'allow' : 'deny'}
                    onChange={e => newInputChange(e, 'allowsMultiples', activeInputField._id)}>
                    <option value='allow'>Yes</option>
                    <option value='deny'>No</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="newInputDropdown">
                <Form.Label>
                    Dropdown<span className="optional">optional</span>
                </Form.Label>
                <Form.Control as="select" custom
                    value={['multiSelect', 'Select'].includes(activeInputField.inputType) &&
                        Object.keys(activeInputField.dynamicFields || {}).length === 0 ? 'Static' :
                        Object.keys(activeInputField.dynamicFields || {}).length > 0 ? 'Dynamic' : 'none'}
                    onChange={e => newInputChange(e, 'inputType', activeInputField._id)}>
                    <option value="none">None</option>
                    <option value="Dynamic">Dynamic</option>
                    <option value='Static'>Static</option>
                </Form.Control>
            </Form.Group>
            <div>
                {['Select', 'multiSelect'].includes(activeInputField.inputType) && <Form.Group controlId="newInputStaticDropdown">
                    <Form.Label>
                        Dropdown Source<span className="optional">required</span>
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        List the dropdown choices. Example: Email, Phone, Address.
                    </Form.Text>
                    <Form.Control type="text"
                        placeholder="Enter label"
                        value={activeInputField.list && activeInputField.list.toString()}
                        onChange={e => newInputChange(e, 'list', activeInputField._id)} />
                </Form.Group>}
                {Object.keys(activeInputField.dynamicFields || {}).length > 0 && (<>
                    <Form.Group controlId="newInputDynamicDropdown">
                        <Form.Label>
                            Trigger<span className="optional">required</span>
                        </Form.Label>
                        <Form.Control as="select" custom
                                      value={activeInputField.dynamicFields.triggerId}
                                      onChange={e => newInputChange(e, 'dynamicFields.triggerId', activeInputField._id)}>
                            {triggersList.length > 0 && (
                                <>
                                    <option>Choose a Trigger</option>
                                    {triggersList.map(each => <option key={each._id} value={each._id}>{each.label}</option>)}
                                </>
                            )}
                            {triggersList.length === 0 && (
                                <option value="none" disabled>No Triggers</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="newInputDynamicKey">
                        <Form.Label>
                            Key<span className="required">required</span>
                        </Form.Label>
                        <Form.Control type="text"
                                      required
                                      placeholder="Enter key"
                                      value={activeInputField.dynamicFields.key}
                                      onChange={e => newInputChange(e, 'dynamicFields.key', activeInputField._id)} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="newInputDynamicLabel">
                        <Form.Label>
                            Label<span className="required">required</span>
                        </Form.Label>
                        <Form.Control type="text"
                                      required
                                      placeholder="Enter label"
                                      value={activeInputField.dynamicFields.label}
                                      onChange={e => newInputChange(e, 'dynamicFields.required', activeInputField._id)} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </>)}
            </div>
            <div className="d-flex justify-content-end">
                <AioButton buttonType="btn-cta2" className="mr-2" onClick={() => {
                    setToggleAddInput(false);
                    setInputFieldList(inputFieldList.filter(eachField => !Object.keys(eachField).includes('isNew')))
                }}>Cancel</AioButton>
                <AioButton buttonType="btn-cta1" type="submit" disabled={postLoader}>
                    { !postLoader ? 'Save' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                </AioButton>
            </div>
        </Form>
    )
}