import React, {  useRef, useCallback } from "react"
import { useDetectOutsideClick } from "../useDetectOutsideClick"
import { Link } from "gatsby"

export default function AioDropdown({ title, list }) {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = useCallback(() => setIsActive(!isActive), [isActive])

    const handleClick = (propsFunction) => {
        // Execute function passed as props to the list item
        propsFunction()
        // Close dropdown when an option is clicked
        onClick()
    }
    return (
        <div className="menu-container">
            <div onClick={onClick} className="menu-trigger">{title}</div>
            <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
                <ul>
                    {list && list.map((option, index) =>
                        <div key={index}>
                            { option.url && <Link to={option.url}><li>{option.value}</li></Link> }
                            { option.click && <li className={option.class || ''} onClick={() => handleClick(option.click)}>{option.value}</li>}
                        </div>
                    )}
                </ul>
            </nav>
        </div>
    );
};