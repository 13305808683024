import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { XLg } from "react-bootstrap-icons"

import AioButton from "../../../components/UI-Elements/AioButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { INPUT_DESIGNER_TYPE, INPUT_FIELD_TYPES } from "../../../utils/constants/appConstants"
import { IActivityInputFields } from "../../../models"

interface FormPreviewProps {
    toggleAddInput: boolean
    inputFieldList: Array<IActionInputFields>
    addAnotherField: () => void
}
interface IActionInputFields extends IActivityInputFields {
    inputDesignerType: string
    id?: number
    isNew?: boolean
}

const modifyGroupList = (list) => {
    const groupObj = {}
    const filteredList = list.filter(eachField => {
        if (eachField.inputDesignerType) {
            return eachField.inputDesignerType === INPUT_DESIGNER_TYPE.GROUP_ITEM
        } else {
            return (eachField.groupName || eachField.groupKey)
        }
    })
    filteredList.forEach(each => {
        if (groupObj[each.groupName]) {
            groupObj[each.groupName].push(each)
        } else {
            groupObj[each.groupName] = [each]
        }
    })
    return groupObj
}

export default function FormPreview({
    toggleAddInput,
    inputFieldList,
    addAnotherField
}) {
    const [inputList, setInputList] = useState<Array<IActionInputFields>>([])
    const [groupList, setGroupList] = useState({})
    const [allowMultiplesList, setAllowMultiplesList] = useState([{
        id: new Date().getTime(),
        value: ''
    }])

    useEffect(() => {
        setInputList(inputFieldList.filter(eachField => {
            if (eachField.inputDesignerType) {
                return eachField.inputDesignerType === INPUT_DESIGNER_TYPE.INPUT_FIELD
            } else {
                return (!eachField.groupName && !eachField.groupKey)
            }
        }))
        setGroupList(modifyGroupList(inputFieldList))
    }, [JSON.stringify(inputFieldList)])

    const removeMultiples = (id) => {
        setAllowMultiplesList(allowMultiplesList.filter(list => list.id !== id))
    }

    // const allowMultiplesInputChange = (event, id) => {
    //     const updatedList = allowMultiplesList.map(eachList => {
    //         if (eachList.id === id) {
    //             return {
    //                 ...eachList,
    //                 value: event.target.value
    //             }
    //         }
    //         return eachList
    //     })
    //     setAllowMultiplesList(updatedList)
    // }

    return (
        <div className="w-50 pt-3 pr-0 pb-3 pl-3 min-w-50 preview-container">
            <h5 className="text-center mb-3">User Preview</h5>
            {!toggleAddInput && (
                <p className="text-muted text-center mt-4">
                    Get a preview of your app here as you build it in the Form Editor
                </p>
            )}
            {(toggleAddInput || (inputList.length > 0 || Object.keys(groupList).length > 0)) && <div className="box-container">
                {(toggleAddInput || inputList.length > 0) &&
                    inputList.map((eachPreview: IActionInputFields, previewIndex) => (
                        <Form className="mb-3" key={previewIndex}>
                            <Form.Group controlId="previewInput">
                                <Form.Label className="d-flex align-items-center justify-content-between">
                                    {eachPreview.label ? eachPreview.label : "New"}
                                    {eachPreview.required && (
                                        <span className="required float-right">required</span>
                                    )}
                                </Form.Label>
                                {eachPreview.inputType === INPUT_FIELD_TYPES.INPUT && eachPreview.type === 'String' &&
                                    Object.keys(eachPreview.dynamicFields || {}).length === 0 && (
                                        <Form.Control
                                            type={eachPreview.type}
                                            value={eachPreview.defaultValue}
                                            onChange={() => { }}
                                            placeholder="Enter text..."
                                        />
                                    )}
                                {/* {eachPreview.type === "textarea" &&
                                    !eachPreview.allowsMultiples && (
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            value={eachPreview.defaultValue}
                                            onChange={() => { }}
                                            placeholder="Enter text..."
                                        />
                                    )} */}
                                {eachPreview.type === "Number" && eachPreview.inputType === INPUT_FIELD_TYPES.INPUT && (
                                        <Form.Control
                                            pattern="\d*"
                                            type="number"
                                            step="1"
                                            value={eachPreview.defaultValue}
                                            onChange={() => { }}
                                            placeholder="Enter number..."
                                        />
                                    )}
                                {eachPreview.type === "Password" && eachPreview.inputType === INPUT_FIELD_TYPES.INPUT && (
                                    <Form.Control
                                        type="password"
                                        value={eachPreview.defaultValue}
                                        onChange={() => { }}
                                        placeholder="Enter password..."
                                    />
                                )}
                                {eachPreview.inputType === INPUT_FIELD_TYPES.SELECT && eachPreview && (
                                    <Form.Control as="select" custom>
                                        <option disabled>Choose</option>
                                        {eachPreview.list && eachPreview.list.map((eachOption, index) => <option value={eachOption} key={index}>{eachOption}</option>)}
                                    </Form.Control>
                                )}
                                {eachPreview.inputType === INPUT_FIELD_TYPES.MULTIINPUT &&
                                allowMultiplesList.length === 1 && (
                                    allowMultiplesList.map((each, index) => (
                                        <Form.Control type="text" key={index} />
                                    ))
                                )}
                                {eachPreview.inputType === INPUT_FIELD_TYPES.MULTIINPUT &&
                                allowMultiplesList.length > 1 && (
                                    allowMultiplesList.map((each, index) => (
                                        <div key={index} className="d-flex align-items-center mb-2">
                                            <FontAwesomeIcon icon={faMinusCircle}
                                                             className="flex-shrink-0 cursor-pointer mr-3 icon"
                                                             onClick={() => removeMultiples(each.id)}/>
                                            <Form.Control type="text" />
                                        </div>
                                    ))
                                )}
                                {eachPreview.inputType === INPUT_FIELD_TYPES.MULTISELECT &&
                                allowMultiplesList.length === 1 && (
                                    allowMultiplesList.map((each, index) => (
                                        <Form.Control as="select" custom>
                                            <option>Choose</option>
                                            {eachPreview.list && eachPreview.list.map((each, index) =>
                                                <option value={each} key={index}>{each}</option>)}
                                        </Form.Control>
                                    ))
                                )}
                                {eachPreview.inputType === INPUT_FIELD_TYPES.MULTISELECT &&
                                allowMultiplesList.length > 1 && (
                                    allowMultiplesList.map((each, index) => (
                                        <div key={index} className="d-flex align-items-center mb-2">
                                            <FontAwesomeIcon icon={faMinusCircle}
                                                             className="flex-shrink-0 cursor-pointer mr-3 icon"
                                                             onClick={() => removeMultiples(each.id)}/>
                                            <Form.Control as="select" custom>
                                                <option>Choose</option>
                                                {eachPreview.list && eachPreview.list.map((each, index) =>
                                                    <option value={each} key={index}>{each}</option>)}
                                            </Form.Control>
                                        </div>
                                    ))
                                )}
                                {[INPUT_FIELD_TYPES.MULTIINPUT, INPUT_FIELD_TYPES.MULTISELECT].includes(eachPreview.inputType) && (
                                    <div className="cursor-pointer mt-3"
                                         onClick={() => setAllowMultiplesList([...allowMultiplesList, {
                                             id: new Date().getTime(),
                                             value: ''
                                         }])}>
                                        <FontAwesomeIcon icon={faPlusCircle}
                                                         className="flex-shrink-0 mr-2 icon" />
                                        Add
                                    </div>
                                )}
                                {Object.keys(eachPreview.dynamicFields || {}).length > 0 && (
                                    <div className="border rounded p-2 bg-light">
                                        <Form.Text className="form-help-text mb-0">
                                            The preview doesn’t use live data, so the {eachPreview.label ? eachPreview.label : 'New'} dropdown will be empty here.
                                        </Form.Text>
                                    </div>
                                )}
                                {eachPreview.description && (
                                    <Form.Text className="form-help-text mt-2">
                                        {eachPreview.description}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Form>
                    ))}
                {(toggleAddInput || Object.keys(groupList).length > 0) && (
                    <div className="mt-4">
                        {Object.keys(groupList).map((eachKey, index) => (
                            <Form className="mb-3" key={index}>
                                <div className="group-name">{eachKey ? eachKey : 'New'}</div>
                                <div className="border position-relative px-4 py-3 rounded">
                                    {groupList[eachKey].map((eachGroup, groupIndex) => {
                                        return <Form.Group controlId="previewInput" key={groupIndex}>
                                            <Form.Label>
                                                {eachGroup.label ? eachGroup.label : "New"}
                                                {eachGroup.required && (
                                                    <span className="required float-right">required</span>
                                                )}
                                            </Form.Label>
                                            {eachGroup.inputType === INPUT_FIELD_TYPES.INPUT && Object.keys(eachGroup.dynamicFields || {}).length === 0 && (
                                                    <Form.Control
                                                        type={eachGroup.type}
                                                        value={eachGroup.defaultValue}
                                                        onChange={() => { }}
                                                        placeholder="Enter text..."
                                                    />
                                                )}
                                            {(Object.keys(eachGroup.dynamicFields || {}).length > 0) && (
                                                <div className="border rounded p-2 bg-light">
                                                    <Form.Text className="form-help-text mb-0">
                                                        The preview doesn’t use live data, so the {eachGroup.label ? eachGroup.label : 'New'} dropdown will be empty here.
                                                    </Form.Text>
                                                </div>
                                            )}
                                            {eachGroup.type === "textarea" &&
                                                !eachGroup.allowsMultiples && (
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        type="text"
                                                        value={eachGroup.defaultValue}
                                                        onChange={() => { }}
                                                        placeholder="Enter text..."
                                                    />
                                                )}
                                            {eachGroup.type === "Integer" && eachGroup.inputType === INPUT_FIELD_TYPES.INPUT &&
                                                !eachGroup.allowsMultiples && (
                                                    <Form.Control
                                                        pattern="\d*"
                                                        type="number"
                                                        step="1"
                                                        value={eachGroup.defaultValue}
                                                        onChange={() => { }}
                                                        placeholder="Enter text..."
                                                    />
                                                )}
                                            {eachGroup.inputType === INPUT_FIELD_TYPES.SELECT && (
                                                <Form.Control as="select" custom>
                                                    <option>Choose</option>
                                                    {eachGroup.list.map((each, index) => <option key={index}>{each}</option>)}
                                                </Form.Control>
                                            )}
                                            {eachGroup.description && (
                                                <Form.Text className="form-help-text mt-2">
                                                    {eachGroup.description}
                                                </Form.Text>
                                            )}
                                            {eachGroup.allowsMultiples && (
                                                <AioButton
                                                    buttonType="btn-cta2"
                                                    className="px-4"
                                                    onClick={() => addAnotherField(eachGroup.id)}
                                                >
                                                    Add Field
                                                </AioButton>
                                            )}
                                        </Form.Group>
                                    })}
                                </div>
                            </Form>
                        ))}
                    </div>
                )}
            </div>
            }
        </div >
    )
}
