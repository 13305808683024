import React from "react"
import { RouteComponentProps, Router, Redirect } from "@reach/router"
import * as NavbarCSS from '../styles/navbar.module.css'
import Source from "./components/Source"
import EnvironmentVariables from "./components/EnvironmentVariables"
import ExportProject from "./components/ExportProject"
import NavLink from "../components/NavLink"

interface Props extends RouteComponentProps { }
const Advanced = (props: Props) => {

    return (
        <>
            <h5 className="mb-2">Advanced</h5>
            <div className={`d-flex mb-4 ${NavbarCSS.navbarSubnavs}`}>
                <NavLink to="environment" isLeftLink={true}>Environment</NavLink>
                <NavLink to="export" >Export Project</NavLink>
                <NavLink to="source" >View Source</NavLink>
            </div>
            <Router>
                <Redirect from="/" to="environment" noThrow />
                <EnvironmentVariables path="environment" />
                <ExportProject path="export" />
                <Source path="source" />
            </Router>
        </>
    )
}

export default Advanced
