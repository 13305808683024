import React, { useState, useEffect, useContext } from "react"
import { Form, Spinner } from "react-bootstrap"
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";

import * as NewIntegrationCSS from '../../styles/new-integration.module.css'
import { categoryChoices } from "../../utils/constants/categoryChoices"
import AioButton from "../../components/UI-Elements/AioButton"
import {
    getAppInfoAction,
    postIntegrationDetailsAction,
    updateIntegrationDetailsAction
} from "../../actions"
import { Context, GlobalContext } from "../../context/ContextStore"
import { IAppInfo } from "../../models"
import * as ActionTypes from '../../actions/types'
import Loader from "../../components/UI-Elements/Loader"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props extends RouteComponentProps {
    integrationId?: string
}

export default function EditIntegration(props: Props) {
    console.log(props.location?.pathname)
    const { state, dispatch } = useContext(Context) as GlobalContext
    console.log('state: ', state)
    const [formData, setFormData] = useState<IAppInfo>({
        _id: '',
        name: '',
        appId: '',
        accountId: '',
        app_url: '',
        description: '',
        homepageURL: '',
        version: '',
        status: '' as 'public',
        logo: '',
        category: ''
    })

    const [validated, setValidated] = useState(false);
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        if (props.integrationId) {
            getAppInfoAction({ dispatch: dispatch, params: props.integrationId })
        }
    }, [])

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        if (props.integrationId) {
            const activeAppInfo = state.appInfo.appInfo
            const formData: IAppInfo = {
                _id: activeAppInfo._id,
                appId: activeAppInfo.appId ? activeAppInfo.appId : '',
                accountId: activeAppInfo.accountId ? activeAppInfo.accountId : '',
                category: activeAppInfo.category,
                logo: activeAppInfo.logo,
                version: activeAppInfo.version,
                name: activeAppInfo.name,
                description: activeAppInfo.description ? activeAppInfo.description : '',
                homepageURL: activeAppInfo.homepageURL ? activeAppInfo.homepageURL : '',
                status: activeAppInfo.status
            }
            setFormData(formData)
        }
    }, [state.appInfo.appInfo])

    function setFormDataValue(event, val) {
        setFormData({
            ...formData,
            [val]: val === 'subscribe' ? event.target.checked : event.target.value
        })
    }

    function onFormSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
            const payload = {
                appId: new Date().getTime() + '',
                name: formData.name,
                version: "1.0.0",
                status: formData.status,
                logo: "",
                description: formData.description,
                category: formData.category
            }
            console.log(payload)
            const onSuccessActions = [{ type: ActionTypes.NAVIGATE_TO, payload: "appInfo" }];
            if (props.location?.pathname.includes('new')) {
                postIntegrationDetailsAction({ dispatch, payload, onSuccessActions })
            } else {
                payload['appInfoId'] = formData._id
                updateIntegrationDetailsAction({ dispatch: dispatch, payload, onSuccessActions })
            }
        } else {
            setValidated(true);
        }
    }

    return (
        <div className={`scroll-bar-css-container ${NewIntegrationCSS.newIntegrationContainer}`}>
            <div className={NewIntegrationCSS.newIntegrationChild}>
                <h3 className="mb-2">Create Integration</h3>
                <Form noValidate validated={validated} onSubmit={onFormSubmit} className="box-container">
                    <Form.Group controlId="newIntegrationName">
                        <Form.Label>
                            Name<span className="required">(required)</span>
                        </Form.Label>
                        <Form.Text className="form-help-text">
                            The name of your app as it should display in Comet.
                        </Form.Text>
                        <Form.Control type="text"
                            required
                            placeholder="Enter name"
                            onChange={(e) => setFormDataValue(e, 'name')}
                            value={formData.name} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="newIntegrationDescription">
                        <Form.Label>
                            Description<span className="required">(required)</span>
                        </Form.Label>
                        <Form.Text className="form-help-text">
                            A sentence describing your app in 140 characters or less, e.g. "Trello is a team collaboration tool to organize tasks and keep projects on track."
                        </Form.Text>
                        <Form.Control as="textarea"
                            rows={3}
                            required
                            placeholder="Enter description"
                            onChange={(e) => setFormDataValue(e, 'description')}
                            value={formData.description} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="newIntegrationHomeURL">
                        <Form.Label>
                            App URL<span className="optional">(optional)</span>
                        </Form.Label>
                        <Form.Text className="form-help-text">
                            The App URL of your app.
                        </Form.Text>
                        <Form.Control type="text"
                            placeholder="Enter App URL"
                            onChange={(e) => setFormDataValue(e, 'homepageURL')}
                            value={formData.homepageURL} />
                    </Form.Group>
                    <Form.Group controlId="newIntegrationLogo">
                        <Form.Label>
                            Logo<span className="optional">(optional)</span>
                        </Form.Label>
                        <Form.Text className="form-help-text">
                            Upload a square, 256x256px or larger transparent PNG image that does not include the app name.
                        </Form.Text>
                        <div className="form-control file-upload p-0 d-flex align-items-center">
                            <Form.Control type="file" />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="newIntegrationStatus">
                        <Form.Label>
                            Status<span className="required">(required)</span>
                        </Form.Label>
                        <Form.Text className="form-help-text">
                            Are you building a public or private integration? Public integration development receives priority support and launch promotion from the Comet team.
                        </Form.Text>
                        <Form.Control as="select" custom
                            required
                            onChange={(e) => setFormDataValue(e, 'status')}
                            value={formData.status}>
                            <option value='' disabled>Choose Intended Audience</option>
                            <option value='public'>Public</option>
                            <option value='private'>Private</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group controlId="newIntegrationAudience">
                        <Form.Label>
                            Intended Audience<span className="required">(required)</span>
                        </Form.Label>
                        <Form.Text className="form-help-text">
                            Are you building a public or private integration? Public integration development receives priority support and launch promotion from the Comet team.
                        </Form.Text>
                        <Form.Control as="select" custom
                                      required
                                      onChange={(e) => setFormDataValue(e, 'audience')}
                                      value={formData.audience}>
                            <option value=''>Choose Intended Audience</option>
                            <option value='public'>Public: I'm evaluating making my app available to the entire Comet ecosystem</option>
                            <option value='private'>Private: I'm building an integration for personal use or to explore the Comet platform</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group> */}
                    {/* <Form.Group controlId="newIntegrationRole">
                        <Form.Label>
                            Role<span className="required">(required)</span>
                        </Form.Label>
                        <Form.Text className="form-help-text">
                            What is your relationship with the app you're integrating with Comet?
                        </Form.Text>
                        <Form.Control as="select" custom
                                      required
                                      onChange={(e) => setFormDataValue(e, 'role')}
                                      value={formData.role}>
                            <option value=''>Choose your Role</option>
                            <option value='1'>I am employed by ___</option>
                            <option value='2'>I was hired by ___ to develop this integration</option>
                            <option value='3'>I have no affiliation with ___</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group controlId="newIntegrationCategory">
                        <Form.Label>
                            Category<span className="required">(required)</span>
                        </Form.Label>
                        <Form.Text className="form-help-text">
                            How would you categorize your app? Choose the most appropriate option for your app's core features.
                        </Form.Text>
                        <Form.Control as="select" custom
                            required
                            onChange={(e) => setFormDataValue(e, 'category')}
                            value={formData.category}>
                            <option value='' disabled>Choose your Category</option>
                            {categoryChoices.map((category, index) =>
                                <option key={index} value={category.value}>{category.label}</option>
                            )}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicCheckbox">
                        <Form.Label>
                            Subscribe to Updates about your Integration<span className="optional">(optional)</span>
                        </Form.Label>
                        <Form.Check type="checkbox"
                                    onChange={(e) => setFormDataValue(e, 'subscribe')}
                                    checked={formData.subscribe}
                                    label="Get tips and recommendations about this integration along with our monthly newsletter that details the performance of your integration and the latest Comet news." />
                    </Form.Group> */}
                    <div className={NewIntegrationCSS.actionsFooter}>
                        <AioButton buttonType="btn-cta2" className="mr-3" onClick={() => navigate(-1)}>
                            Cancel
                        </AioButton>
                        <AioButton buttonType="btn-cta1" type="submit" disabled={postLoader}>
                            { !postLoader ? 'Submit' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                        </AioButton>
                    </div>
                </Form>
            </div>
        </div>
    )
}
