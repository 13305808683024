import { types as ActionTypes } from '../../actions'
import { IUser } from '../../models'

export interface IAuthState {
    isAuthenticating: boolean
    user: IUser
}

export const authInitialState: IAuthState = {
    isAuthenticating: false,
    user: { email: '', name: '' }
}

export const authReducer = (state = authInitialState , action) => {
    // console.log(state, action);
    switch(action.type) {
        case ActionTypes.LOAD_USER_DATA: {
            return {
                ...state,
                isAuthenticating: true,
            }
        }
        case ActionTypes.LOAD_USER_DATA_SUCCESS: {
            const payload = action.payload.user as IUser
            return {
                ...state,
                isAuthenticating: false,
                user: payload
            }
        }
        case ActionTypes.LOAD_USER_DATA_FAIL: {
            return {
                ...state,
                isAuthenticating: false,
            }
        }
        case ActionTypes.LOG_OUT: 
        default: 
            return state
    }
}