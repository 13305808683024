export const INPUT_TYPES = {
    DYNAMIC: 'Dynamic',
    STATIC: 'Static'
}

export const FORM_FIELDS = {
    REQUIRED: 'required',
    ALLOW_MULTIPLES: 'allowsMultiples',
    ALTERS_DYNAMIC: 'altersDynamicFields',
    INPUT_TYPE: 'inputType',
    LIST: 'list',
    DYNAMIC_FIELDS: 'dynamicFields'
}

export const FORM_VALUES = {
    REQUIRED: 'required',
    ALLOW: 'allow',
    ALTER: 'alter'
}

export const INPUT_FIELD_TYPES = {
    INPUT: 'Input',
    MULTIINPUT: 'multiInput',
    SELECT: 'Select',
    MULTISELECT: 'multiSelect'
}

export const INPUT_DESIGNER_TYPE = {
    INPUT_FIELD: 'inputField',
    GROUP_ITEM: 'lineItemGroup'
}

export const ACTION_TYPE = {
    NONE: 'none',
    INPUT_FIELD: 'Input Field',
    DYNAMIC_FIELD: 'Dynamic Field',
    GROUP_FIELD: 'Group Fields'
}