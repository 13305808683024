import React, { useContext, useEffect } from 'react'

import LeftNav from "./LeftNav"
import RightNav from "./RightNav"
import { Props } from '../../models/props'
import { Context, GlobalContext } from '../../context/ContextStore'
import { getAppInfosAction } from '../../actions'

interface SidebarProps extends Props {
    activeLink?: string
}
export default function SidebarsLayout({ children, activeLink }: SidebarProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    useEffect(() => {
        getAppInfosAction({ dispatch })
    }, [])
    return (
        <div className="d-flex">
            <LeftNav />
            <div className="flex-grow-1 content-container scroll-bar-css-container">
                <div className="container">
                    { children }
                </div>
            </div>
            <RightNav />
        </div>
    )
}
