import { IIntegrationPayload, IActionPayload } from "../models/actions"
import * as ActionTypes from './types'
import AppInfoService from '../services/appInfoService'
import { IAppInfo } from '../models'

export interface IAppInfoSuccessArgs {
    appInfos: Array<IAppInfo>
}
export function getAppInfosAction(actionPayload: IActionPayload) {
    const { dispatch, params } = actionPayload
    // return AdvancedService.getArticleDetails(dispatch);
    return dispatch({
        type: ActionTypes.LOAD_APPINFOS_DATA,
        payload: {
            dispatch,
            ...AppInfoService.getAppInfos()
        }
    })
}

export function getAppInfoAction(actionPayload: IActionPayload) {
    const { dispatch, params } = actionPayload
    return dispatch({
        type: ActionTypes.LOAD_APPINFO_DATA,
        payload: {
            dispatch,
            ...AppInfoService.getAppInfo(params)
        }
    })
}

export function postIntegrationDetailsAction(actionPayload: IActionPayload) {
    const { dispatch, payload, onSuccessActions = [] } = actionPayload
    return dispatch({
        type: ActionTypes.POST_APPINFO_DATA,
        payload: {
            dispatch,
            ...AppInfoService.postAppInfo(payload),
            onSuccessActions
        }
    })
}

export function updateIntegrationDetailsAction(actionPayload: IActionPayload) {
    const { dispatch, payload, onSuccessActions = [] } = actionPayload
    return dispatch({
        type: ActionTypes.UPDATE_APPINFO_DATA,
        payload: {
            dispatch,
            ...AppInfoService.putAppInfo(payload),
            onSuccessActions
        }
    })
}

export function deleteIntegrationAction(actionPayload: IActionPayload) {
    const { dispatch, payload } = actionPayload
    return dispatch({
        type: ActionTypes.DELETE_APPINFO_DATA,
        payload: {
            dispatch,
            ...AppInfoService.deleteAppInfo(payload)
        }
    })
}

export function listAppInfoVersionsAction(actionPayload: IActionPayload) {
    const { dispatch, params } = actionPayload
    return dispatch({
        type: ActionTypes.LOAD_APPINFO_VERISONS,
        payload: {
            dispatch,
            ...AppInfoService.listAppInfoVersions(params),
            data: {
                appInfoId: params
            }
        }
    })
}

export function updateAppInfoVersionAction(actionPayload: IActionPayload) {
    const { dispatch, params, payload, onSuccessActions = [], onFailureActions = [] } = actionPayload

    const onSuccess = [
        ...onSuccessActions,
        { type: ActionTypes.ADD_NOTIFICATION, payload: { error: { message: 'Save successful'}, type: 'success'}},
        { type: ActionTypes.DELETE_NOTIFICATION, payload: { delay: true}}
    ]

    return dispatch({
        type: ActionTypes.UPDATE_APPINFO_VERSION,
        payload: {
            dispatch,
            ...AppInfoService.updateAppInfoVersion(params),
            data: payload,
            onSuccessActions: onSuccess, 
            onFailureActions
        }
    })
}

export function loadAppInfoVersionAction(actionPayload: IActionPayload) {
    const { dispatch, params } = actionPayload
    return dispatch({
        type: ActionTypes.LOAD_APPINFO_VERSION,
        payload: {
            dispatch,
            ...AppInfoService.loadAppInfoVersion(params),
        }
    })
}