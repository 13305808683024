import React, { useContext, useEffect, useState } from "react"
import { Accordion, Card, Form, Spinner } from "react-bootstrap"
import { ChevronDown, ChevronUp, XLg } from "react-bootstrap-icons"
import CodeEditor, { formatJSON } from "../../../components/CodeEditor"
import AioButton from "../../../components/UI-Elements/AioButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { Context, GlobalContext } from "../../../context/ContextStore"

interface IOutputField {
    id: number, key: string, value: string, type: string
}

interface IStaticOutputField {
    key: string, entity: string, type: string
}

export default function DefineOutput(props) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [toggleValue, setToggleValue] = useState(true)
    const [editorValue, setEditorValue] = useState("")
    const [outputFields, setOutputFields] = useState<Array<IOutputField>>([{
        id: new Date().getTime(),
        key: '',
        value: '',
        type: "string"
    }])
    const { staticOutputFields = [], _id } = props.activeTrigger || {};
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        if (staticOutputFields.length) {
            console.log(staticOutputFields)
            const modifiedData = staticOutputFields.map(item => {
                return {
                    key: item.key,
                    id: item._id,
                    value: item.entity || "",
                    type: item.type || "string"
                };
            })
            setOutputFields(modifiedData);
            const modifiedFieldValues = staticOutputFields.reduce((acc, item) => {
                acc[item.key] = item.entity || "";
                return acc;
            }, {})
            console.log(JSON.stringify(modifiedFieldValues))
            setEditorValue(formatJSON(JSON.stringify(modifiedFieldValues)))
        }
    }, [_id])

    function addOutputField() {
        setOutputFields([...outputFields, {
            id: new Date().getTime(),
            key: '',
            value: '',
            type: "string"
        }])
    }

    function generateOutputFields() {
        const parsedEditorValue = JSON.parse(editorValue.replace(/\n/g, ""))
        const finalData: Array<IOutputField> = Object.keys(parsedEditorValue).reduce((acc: IOutputField[], item, idx) => {
            acc.push({
                id: new Date().getTime() + idx,
                key: item,
                value: parsedEditorValue[item],
                type: typeof parsedEditorValue[item]
            })
            return acc;
        }, []);
        setOutputFields(finalData)
    }

    function handleChange(event, id) {
        const updatedOutputFields = outputFields.map(item => {
            if (item.id === id) {
                const { name, value } = event.target;
                item[name] = value;
            }
            return item;
        })
        setOutputFields(updatedOutputFields);
    }

    function submitForm() {
        const staticOutputFields: Array<IStaticOutputField> = outputFields.reduce((acc: IStaticOutputField[], item, idx) => {
            acc.push({
                key: item.key,
                entity: item.value,
                type: item.type
            })
            return acc;
        }, [])
        console.log(outputFields, staticOutputFields)
        const updatedData = {
            ...props.activeTrigger,
            staticOutputFields
        }
        props.updateApiConfiguration(updatedData);
    }

    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} onClick={() => setToggleValue(!toggleValue)} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    {/* <div className="chip">
                        Step 3
                    </div> */}
                    <h6 className="mb-3 flex-grow-1">3. Define your Output</h6>
                    {toggleValue ? <ChevronDown /> : <ChevronUp />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pl-3 pr-3">
                    <div className="">
                        <div className="mb-4">
                            <Form.Label>
                                Sample Data <span className="optional">optional</span>
                            </Form.Label>
                            <p className="text-muted">Enter JSON-formatted sample output data for the most commonly used fields from this Trigger to use in Comet as sample data if users don’t test it.</p>
                            <div style={{ height: '300px' }}>
                                <CodeEditor language={'json'} value={editorValue} onChange={value => setEditorValue(value)} />
                            </div>
                            <AioButton buttonType="btn-cta1" className="mt-3 mb-4" onClick={generateOutputFields}>
                                Generate Output Field Definitions
                            </AioButton>
                        </div>
                        <div className="mb-4">
                            <Form.Label>
                                Output Fields <span className="optional">optional</span>
                            </Form.Label>
                            <p className="text-muted">Add user friendly names for the most popular fields from API output data.</p>
                            <div className="box-container shadow-none border">
                                <div className="mt-4 pl-2 pr-2">
                                    {outputFields.map((eachParam, idx) => {
                                        return (
                                            <div className="d-flex align-items-center mb-3" key={eachParam.id || idx}>
                                                <Form.Control type="text" className="mr-3 w-25 bg-light" placeholder="key" name="key" value={eachParam.key} onChange={(e) => handleChange(e, eachParam.id)} />
                                                <Form.Control type="text" className="mr-3" placeholder="value" name="value" value={eachParam.value} onChange={(e) => handleChange(e, eachParam.id)} />
                                                <Form.Control as="select" className="w-15" value={eachParam.type} name="type" custom onChange={(e) => handleChange(e, eachParam.id)}>
                                                    <option value='String'>String</option>
                                                    <option value='Text'>Text</option>
                                                    <option value='Integer'>Integer</option>
                                                    <option value='Number'>Number</option>
                                                    <option value='Boolean'>Boolean</option>
                                                    <option value='Datetime'>Datetime</option>
                                                    <option value='Password'>Password</option>
                                                </Form.Control>
                                                <XLg size={16} className="flex-shrink-0 cursor-pointer ml-3" onClick={() => setOutputFields(outputFields.filter(field => field.id !== eachParam.id))} />
                                            </div>
                                        )
                                    })}
                                    <button className="mb-2 d-flex btn-add" onClick={addOutputField}>
                                        <FontAwesomeIcon className="icon mr-1" icon={faPlusCircle} />
                                        <span>Add</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <AioButton buttonType="btn-cta1" className="mb-2 float-right" disabled={postLoader} onClick={submitForm}>
                            {!postLoader ? 'Save Output & Finish' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black" />}
                        </AioButton>
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}