export const categoryChoices = [
    {
        "value": "accounting",
        "label": "Accounting"
    },
    {
        "value": "ads-conversion",
        "label": "Ads & Conversion"
    },
    {
        "value": "analytics",
        "label": "Analytics"
    },
    {
        "value": "app-builder",
        "label": "App Builder"
    },
    {
        "value": "app-families",
        "label": "App Families"
    },
    {
        "value": "bookmarks",
        "label": "Bookmark Managers"
    },
    {
        "value": "business-intelligence",
        "label": "Business Intelligence"
    },
    {
        "value": "calendar",
        "label": "Calendar"
    },
    {
        "value": "call-tracking",
        "label": "Call Tracking"
    },
    {
        "value": "cms",
        "label": "Website Builders"
    },
    {
        "value": "commerce",
        "label": "Commerce"
    },
    {
        "value": "communication",
        "label": "Communication"
    },
    {
        "value": "contacts",
        "label": "Contact Management"
    },
    {
        "value": "content-files",
        "label": "Content & Files"
    },
    {
        "value": "crm",
        "label": "CRM (Customer Relationship Management)"
    },
    {
        "value": "customer-appreciation",
        "label": "Customer Appreciation"
    },
    {
        "value": "customer-support",
        "label": "Customer Support"
    },
    {
        "value": "dashboards",
        "label": "Dashboards"
    },
    {
        "value": "databases",
        "label": "Databases"
    },
    {
        "value": "developer-tools",
        "label": "Developer Tools"
    },
    {
        "value": "devices",
        "label": "Devices"
    },
    {
        "value": "documents",
        "label": "Documents"
    },
    {
        "value": "drip-emails",
        "label": "Drip Emails"
    },
    {
        "value": "ecommerce",
        "label": "eCommerce"
    },
    {
        "value": "education",
        "label": "Education"
    },
    {
        "value": "email",
        "label": "Email"
    },
    {
        "value": "email-newsletters",
        "label": "Email Newsletters"
    },
    {
        "value": "event-management",
        "label": "Event Management"
    },
    {
        "value": "fax",
        "label": "Fax"
    },
    {
        "value": "files",
        "label": "File Management & Storage"
    },
    {
        "value": "filters",
        "label": "Filters"
    },
    {
        "value": "fitness",
        "label": "Fitness"
    },
    {
        "value": "forms",
        "label": "Forms & Surveys"
    },
    {
        "value": "fundraising",
        "label": "Fundraising"
    },
    {
        "value": "gaming",
        "label": "Gaming"
    },
    {
        "value": "hr",
        "label": "HR Talent & Recruitment"
    },
    {
        "value": "human-resources",
        "label": "Human Resources"
    },
    {
        "value": "images",
        "label": "Images & Design"
    },
    {
        "value": "internet-of-things",
        "label": "Internet of Things"
    },
    {
        "value": "invoices",
        "label": "Proposal & Invoice Management"
    },
    {
        "value": "it-operations",
        "label": "IT Operations"
    },
    {
        "value": "it-operations-education",
        "label": "Online Courses"
    },
    {
        "value": "lifestyle-entertainment",
        "label": "Lifestyle & Entertainment"
    },
    {
        "value": "marketing",
        "label": "Marketing"
    },
    {
        "value": "marketing-automation",
        "label": "Marketing Automation"
    },
    {
        "value": "news",
        "label": "News & Lifestyle"
    },
    {
        "value": "notes",
        "label": "Notes"
    },
    {
        "value": "notifications",
        "label": "Notifications"
    },
    {
        "value": "payment-processing",
        "label": "Payment Processing"
    },
    {
        "value": "phone",
        "label": "Phone & SMS"
    },
    {
        "value": "printing",
        "label": "Printing"
    },
    {
        "value": "product-management",
        "label": "Product Management"
    },
    {
        "value": "productivity",
        "label": "Productivity"
    },
    {
        "value": "project-management",
        "label": "Project Management"
    },
    {
        "value": "reviews",
        "label": "Reviews"
    },
    {
        "value": "sales-crm",
        "label": "Sales & CRM"
    },
    {
        "value": "scheduling",
        "label": "Scheduling & Booking"
    },
    {
        "value": "security-identity",
        "label": "Security & Identity Tools"
    },
    {
        "value": "server-monitoring",
        "label": "Server Monitoring"
    },
    {
        "value": "signatures",
        "label": "Signatures"
    },
    {
        "value": "social",
        "label": "Social Media Accounts"
    },
    {
        "value": "social-marketing",
        "label": "Social Media Marketing"
    },
    {
        "value": "spreadsheets",
        "label": "Spreadsheets"
    },
    {
        "value": "support",
        "label": "Support"
    },
    {
        "value": "tax",
        "label": "Taxes"
    },
    {
        "value": "team-chat",
        "label": "Team Chat"
    },
    {
        "value": "team-collaboration",
        "label": "Team Collaboration"
    },
    {
        "value": "time-tracking",
        "label": "Time Tracking Software"
    },
    {
        "value": "todo-lists",
        "label": "Task Management"
    },
    {
        "value": "transactional-email",
        "label": "Transactional Email"
    },
    {
        "value": "transcription",
        "label": "Transcription"
    },
    {
        "value": "url-shorten",
        "label": "URL Shortener"
    },
    {
        "value": "video",
        "label": "Video & Audio"
    },
    {
        "value": "video-calls",
        "label": "Video Conferencing"
    },
    {
        "value": "webinars",
        "label": "Webinars"
    },
    {
        "value": "website-app-building",
        "label": "Website & App Building"
    }
]