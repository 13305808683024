import React, { useEffect, useState } from "react"
import AioButton from "../../../components/UI-Elements/AioButton"

import FormEditor from "./FormEditor"
import FormPreview from "./FormPreview"
import { Props, IActivityInputFields, IAuthInputFields } from "../../../models"

import { FORM_FIELDS, INPUT_TYPES, FORM_VALUES, INPUT_FIELD_TYPES, INPUT_DESIGNER_TYPE, ACTION_TYPE } from '../../../utils/constants/appConstants'

interface InputDesignerProps extends Props {
    activeInputFields: Array<IActionInputFields>
    submitInputDesignerData: (data: { inputFieldList: IActionInputFields[] }) => void
    submitLineItemHandler: (data: IActionInputFields[]) => void
}

interface IDynamicFields {
    triggerId?: string
    actionId?: string
    key: string
    label: string
}

interface IActionInputFields extends IActivityInputFields {
    inputDesignerType?: string
    id?: number
    isNew?: boolean
    multipleInputs?: Array<{id: string, value: string}>
}

const initialObject: IActionInputFields = {
    inputDesignerType: INPUT_DESIGNER_TYPE.INPUT_FIELD,
    isNew: true,
    key: '',
    label: '',
    description: '',
    type: 'String' as 'String',
    defaultValue: '',
    required: false,
    altersDynamicField: false,
    groupName: '',
    groupKey: '',
    list: [],
    dynamicFields: {} as IDynamicFields,
    inputType: INPUT_FIELD_TYPES.INPUT,
    id: -1
}

export default function InputDesigner({
    activeInputFields,
    submitInputDesignerData,
    submitLineItemHandler
}: InputDesignerProps) {
    const [toggleAddInput, setToggleAddInput] = useState(false)
    const [inputFieldList, setInputFieldList] = useState<Array<IActionInputFields>>([])
    const [activeInputField, setActiveInputField] = useState(initialObject)
    const [validated, setValidated] = useState(false)
    // 0 => default; 1 => Input Field; 2 => Dynamic Field; 3 => Group Fields
    const [addStatus, setAddStatus] = useState(ACTION_TYPE.NONE)

    // const [lineItemGroupList, setLineItemGroupList] = useState([])
    const [showLineGroupInputForm, setShowLineGroupInputForm] = useState(false)
    const [previewUIStatus, setPreviewUIStatus] = useState('show')

    useEffect(() => {
        setInputFieldList(activeInputFields ? activeInputFields : [])
    }, [activeInputFields])

    const handleInputFieldSubmit = (event: Event) => {
        submitInputDesignerData({
            inputFieldList,
            // lineItemGroupList: []
        })
        setInputFieldList(inputFieldList)
        setToggleAddInput(false)
        setShowLineGroupInputForm(false)
        setAddStatus(ACTION_TYPE.NONE)
    }

    function newInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, value: string, inputId) {
        let updatedActiveInputField
        if ([FORM_FIELDS.REQUIRED, FORM_FIELDS.ALLOW_MULTIPLES, FORM_FIELDS.ALTERS_DYNAMIC, FORM_FIELDS.INPUT_TYPE].includes(value)) {
            if (value === FORM_FIELDS.REQUIRED) {
                updatedActiveInputField = {
                    ...activeInputField,
                    [value]: e.target.value === FORM_VALUES.REQUIRED
                }
            }
            if (value === FORM_FIELDS.ALLOW_MULTIPLES) {
                updatedActiveInputField = {
                    ...activeInputField,
                    inputType: e.target.value === FORM_VALUES.ALLOW ?
                        activeInputField.inputType === INPUT_FIELD_TYPES.INPUT ? INPUT_FIELD_TYPES.MULTIINPUT :
                            activeInputField.inputType === INPUT_FIELD_TYPES.SELECT ? INPUT_FIELD_TYPES.MULTISELECT : INPUT_FIELD_TYPES.INPUT
                        : INPUT_FIELD_TYPES.INPUT
                }
            }
            if (value === FORM_FIELDS.ALTERS_DYNAMIC) {
                updatedActiveInputField = {
                    ...activeInputField,
                    [value]: e.target.value === FORM_VALUES.ALTER
                }
            }
            if (value === FORM_FIELDS.INPUT_TYPE) {
                if (e.target.value === INPUT_TYPES.DYNAMIC) {
                    updatedActiveInputField = {
                        ...activeInputField,
                        inputType: INPUT_FIELD_TYPES.INPUT,
                        dynamicFields: {
                            triggerId: '',
                            key: '',
                            label: ''
                        }
                    }
                } else if (e.target.value === INPUT_TYPES.STATIC) {
                    updatedActiveInputField = {
                        ...activeInputField,
                        dynamicFields: {},
                        inputType: activeInputField.inputType === INPUT_FIELD_TYPES.MULTIINPUT ? INPUT_FIELD_TYPES.MULTISELECT : INPUT_FIELD_TYPES.SELECT
                    }
                } else {
                    updatedActiveInputField = {
                        ...activeInputField,
                        inputType: INPUT_FIELD_TYPES.INPUT,
                        dynamicFields: {}
                    }
                }
            }
        } else if (value === FORM_FIELDS.LIST) {
            updatedActiveInputField = {
                ...activeInputField,
                [value]: e.target.value.split(',')
            }
        } else if (value.includes(FORM_FIELDS.DYNAMIC_FIELDS)) {
            const splitValue = value.split('.')
            updatedActiveInputField = {
                ...activeInputField,
                [splitValue[0]] : {
                    ...activeInputField[splitValue[0]],
                    [splitValue[1]]: e.target.value
                }
            }
        } else {
            updatedActiveInputField = {
                ...activeInputField,
                [value]: e.target.value
            }
        }
        setActiveInputField(updatedActiveInputField)
        let updatedInputFieldList
        if (updatedActiveInputField.isNew) {
            updatedInputFieldList = inputFieldList.map(eachInput => {
                if (eachInput.label === activeInputField.label) {
                    return updatedActiveInputField
                }
                return eachInput
            })
            setInputFieldList(updatedInputFieldList)
        } else {
            updatedInputFieldList = inputFieldList.map(eachInput => {
                if (eachInput._id === inputId) {
                    return updatedActiveInputField
                }
                return eachInput
            })
        }
        setInputFieldList(updatedInputFieldList)
    }

    function changeToggleStatus(actionType: string) {
        setValidated(false)
        setToggleAddInput(true)
        if (actionType === ACTION_TYPE.INPUT_FIELD) {
            const newObject = { ...initialObject }
            newObject.id = inputFieldList.length
            newObject.inputDesignerType = actionType === ACTION_TYPE.INPUT_FIELD ? INPUT_DESIGNER_TYPE.INPUT_FIELD : INPUT_DESIGNER_TYPE.GROUP_ITEM
            setInputFieldList([
                ...inputFieldList,
                newObject
            ])
            setActiveInputField(newObject)
        }
    }

    function addAnotherField() {
        const copyOfInputFieldList = [...inputFieldList]
        const copyActiveInputField = { ...activeInputField }
        if (!copyActiveInputField.list) {
            copyActiveInputField.list = []
        }
        copyActiveInputField.list.push('')
        const updatedActiveInput = {
            ...copyActiveInputField
        }
        setActiveInputField(updatedActiveInput)
    }

    function removeParam(inputId, multipleId) {
        const copyOfInputFieldList = [...inputFieldList]
        copyOfInputFieldList.map(each => {
            if (each.id === inputId && each.multipleInputs) {
                each.multipleInputs = each.multipleInputs.filter(eachMI => eachMI.id !== multipleId)
            }
            return each
        })
        setInputFieldList([...copyOfInputFieldList])
    }

    function changeMultiInputValue(event, inputId, multipleId) {
        const copyOfInputFieldList = [...inputFieldList]
        copyOfInputFieldList.map(each => {
            if (each.id === inputId && each.multipleInputs) {
                each.multipleInputs.map(eachMI => {
                    if (eachMI.id === multipleId) {
                        eachMI.value = event.target.value
                    }
                    return eachMI
                })
            }
            return each
        })
        setInputFieldList([...copyOfInputFieldList])
    }

    function setDropdownType(id, val) {
        const copyOfInputFieldList = [...inputFieldList]
        copyOfInputFieldList.map(each => {
            if (each.id === id) {
                each.dropdownType = val
            }
            return each
        })
        setInputFieldList([...copyOfInputFieldList])
    }

    function lineGroupInputChangeHandler(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, value: string) {
        let updatedActiveInputField
        if ([FORM_FIELDS.REQUIRED, FORM_FIELDS.ALLOW_MULTIPLES, FORM_FIELDS.ALTERS_DYNAMIC, FORM_FIELDS.INPUT_TYPE].includes(value)) {
            if (value === FORM_FIELDS.REQUIRED) {
                updatedActiveInputField = {
                    ...activeInputField,
                    [value]: e.target.value === FORM_VALUES.REQUIRED
                }
            }
            if (value === FORM_FIELDS.ALLOW_MULTIPLES) {
                updatedActiveInputField = {
                    ...activeInputField,
                    inputType: e.target.value === FORM_VALUES.ALLOW ?
                        activeInputField.inputType === INPUT_FIELD_TYPES.INPUT ? INPUT_FIELD_TYPES.MULTIINPUT :
                            activeInputField.inputType === INPUT_FIELD_TYPES.SELECT ? INPUT_FIELD_TYPES.MULTISELECT : INPUT_FIELD_TYPES.INPUT
                        : INPUT_FIELD_TYPES.INPUT
                }
            }
            if (value === FORM_FIELDS.ALTERS_DYNAMIC) {
                updatedActiveInputField = {
                    ...activeInputField,
                    [value]: e.target.value === FORM_VALUES.ALTER
                }
            }
            if (value === FORM_FIELDS.INPUT_TYPE) {
                if (e.target.value === INPUT_TYPES.DYNAMIC) {
                    updatedActiveInputField = {
                        ...activeInputField,
                        inputType: INPUT_FIELD_TYPES.INPUT,
                        dynamicFields: {
                            triggerId: '',
                            key: '',
                            label: ''
                        }
                    }
                } else if (e.target.value === INPUT_TYPES.STATIC) {
                    updatedActiveInputField = {
                        ...activeInputField,
                        dynamicFields: {},
                        inputType: activeInputField.inputType === INPUT_FIELD_TYPES.MULTIINPUT ? INPUT_FIELD_TYPES.MULTISELECT : INPUT_FIELD_TYPES.SELECT
                    }
                } else {
                    updatedActiveInputField = {
                        ...activeInputField,
                        inputType: INPUT_FIELD_TYPES.INPUT,
                        dynamicFields: {}
                    }
                }
            }
        } else if (value === FORM_FIELDS.LIST) {
            updatedActiveInputField = {
                ...activeInputField,
                [value]: e.target.value.split(',')
            }
        } else if (value.includes(FORM_FIELDS.DYNAMIC_FIELDS)) {
            const splitValue = value.split('.')
            updatedActiveInputField = {
                ...activeInputField,
                [splitValue[0]] : {
                    ...activeInputField[splitValue[0]],
                    [splitValue[1]]: e.target.value
                }
            }
        } else {
            updatedActiveInputField = {
                ...activeInputField,
                [value]: e.target.value
            }
        }
        setActiveInputField(updatedActiveInputField)
        const copyOfInputFields = inputFieldList.map(eachInput => {
            if (eachInput.isNew) {
                return updatedActiveInputField
            }
            return eachInput
        })
        setInputFieldList(copyOfInputFields)
    }

    function addLineGroupInput() {
        setShowLineGroupInputForm(true)
        const updateInitialObject = {
            ...initialObject,
            inputDesignerType: INPUT_DESIGNER_TYPE.GROUP_ITEM,
            groupName: activeInputField.groupName,
            groupKey: activeInputField.groupKey,
            _id: String(new Date().getTime())
        }
        setActiveInputField(updateInitialObject)
        const updatedInputFieldList = [...inputFieldList, updateInitialObject]
        setInputFieldList(updatedInputFieldList)
    }

    const editLineItemHandler = (item: IActionInputFields) => {
        setShowLineGroupInputForm(true)
        setActiveInputField(item)
    }

    function submitLineGroupInputForm() {
        setShowLineGroupInputForm(false)
        const copyOfInputFields = inputFieldList.map(eachInput => {
            if (eachInput.isNew) {
                const { isNew, inputDesignerType, _id, ...withoutParams } = activeInputField
                return withoutParams
            } else if (eachInput._id === activeInputField._id) {
                return activeInputField
            }
            return eachInput
        })
        setInputFieldList(copyOfInputFields)
        submitLineItemHandler(copyOfInputFields)
    }

    const deleteInputFieldHandler = (inputId: string) => {
        const copyOfInputFields = inputFieldList.filter(eachInput => eachInput._id !== inputId)
        setInputFieldList(copyOfInputFields)
        submitLineItemHandler(copyOfInputFields)
    }

    return (
        <div className="d-flex position-relative">
            <FormEditor
                activeInputFields={activeInputFields}
                previewUIStatus={previewUIStatus}
                toggleAddInput={toggleAddInput}
                validated={validated}
                setValidated={setValidated}
                inputFieldList={inputFieldList}
                setInputFieldList={setInputFieldList}
                activeInputField={activeInputField}
                setActiveInputField={setActiveInputField}
                setToggleAddInput={setToggleAddInput}
                addStatus={addStatus}
                setAddStatus={setAddStatus}
                showLineGroupInputForm={showLineGroupInputForm}
                setShowLineGroupInputForm={setShowLineGroupInputForm}
                deleteInputFieldHandler={deleteInputFieldHandler}
                // lineItemGroupList={lineItemGroupList}
                lineGroupInputChangeHandler={lineGroupInputChangeHandler}
                setDropdownType={setDropdownType}
                newInputChange={newInputChange}
                handleInputFieldSubmit={handleInputFieldSubmit}
                addLineGroupInput={addLineGroupInput}
                changeToggleStatus={changeToggleStatus}
                submitLineGroupInputForm={submitLineGroupInputForm}
                editLineItemHandler={editLineItemHandler} />
            {previewUIStatus === 'show' && <FormPreview toggleAddInput={toggleAddInput}
                inputFieldList={inputFieldList}
                // lineItemGroupList={lineItemGroupList}
                // changeMultiInputValue={changeMultiInputValue}
                // removeParam={removeParam}
                addAnotherField={addAnotherField} />}
            <AioButton buttonType="btn-cta3" className="hide-preview-btn" onClick={() => setPreviewUIStatus(previewUIStatus === 'show' ? 'hide' : 'show')}>
                {previewUIStatus === 'show' ? 'Hide User Preview' : 'Show User Preview'}
            </AioButton>
        </div>
    )
}
