import * as ActionTypes from './types'
import AuthService from '../services/authService'
import { IActionPayload } from '../models'

export function fetchUserDetailsAction(payload: IActionPayload) {
    const { dispatch } = payload
    return dispatch({
        type: ActionTypes.LOAD_USER_DATA,
        payload: {
            dispatch,
            ...AuthService.fetchUserDetails()
        }
    })
}