import React, { useState, useContext, useEffect } from "react"
import { Accordion, Card } from "react-bootstrap"
import { Link } from "gatsby"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"
import { RouteComponentProps } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import * as SidebarCSS from '../../styles/sidebar.module.css'
import AioButton from "../UI-Elements/AioButton"
import { Props } from "../../models/props"
import { Context, GlobalContext } from "../../context/ContextStore"
import { useLocation } from '@reach/router';
import { navigate } from "gatsby"



export default function LeftNav(props: Props) {
    const { state, dispatch } = useContext(Context) as GlobalContext;
    const location = useLocation();
    const [buildToggle, setBuildToggle] = useState(true)
    const [manageToggle, setManageToggle] = useState(true)
    const [integrationId, setIntegrationId] = useState('')
    const [versionId, setVersionId] = useState('')
    const { name = "", version = "1.0.0", status = "" } = state.appInfo.appInfo || {};
    const getClassNames = (props, isSubSidebar?) => {
        const classNamesList = [isSubSidebar ? SidebarCSS.subSidebarLinks : SidebarCSS.sidebarLinks];
        if (props.isCurrent) {
            classNamesList.push(SidebarCSS.sidebarLinkActive)
        }
        //special check for advanced routes; as we /advanced/* not /advanced route
        if (props.href.includes("/advanced") && props.isPartiallyCurrent) {
            classNamesList.push(SidebarCSS.sidebarLinkActive)
        }
        return { className: classNamesList.join(" ") };
    }
    useEffect(() => {
        setIntegrationId(window.location.pathname.split('/')[3])
        setVersionId(window.location.pathname.split('/')[5])
    }, [])

    const [authType, setAuthType] = useState('')

    useEffect(() => {
        setAuthType(state.appInfo.appInfoVersion.authParams.type)
    }, [state.appInfo.appInfoVersion])

    function navigateToSettings() {
        const [, l1, l2, l3, l4, l5] = location.pathname.split("/");
        const path = [l1,l2,l3,l4,l5].join("/");
        navigate(`/${path}/edit`)
    }
    return (
        <div className={SidebarCSS.sidebarContainer}>
            <div className={SidebarCSS.integrationInfo}>
                <div className="d-flex">
                    <div className={SidebarCSS.logoContainer} />
                    <div className={SidebarCSS.nameType}>
                        <h6 className={SidebarCSS.nameContainer}>
                            {name}
                        </h6>
                        <div className="d-flex align-items-center">
                            <AioButton buttonType="btn-cta2" className="py-1 mr-3">
                                {status.toUpperCase()}
                            </AioButton>
                            <FontAwesomeIcon
                                icon={faCog} className="cursor-pointer"
                                onClick={navigateToSettings} />
                        </div>
                    </div>
                </div>
                <div className={SidebarCSS.publishContainer}>
                    <AioButton buttonType="btn-cta1" className="w-100">
                        Publish
                    </AioButton>
                </div>
            </div>
            <div className={SidebarCSS.versionStatus}>
                <div className={SidebarCSS.versionContainer}>
                    Version <span className={SidebarCSS.typeContainer}>{version}</span>
                </div>
                <div className={SidebarCSS.statusContainer}>
                    Status <span className={SidebarCSS.typeContainer}>{status}</span>
                </div>
            </div>
            <div className={`${SidebarCSS.sidebarActionList} scroll-bar-css-container`}>
                <Accordion defaultActiveKey="0">
                    <Card className={SidebarCSS.sidebarCard}>
                        <Accordion.Toggle as={Card.Header} eventKey="0" className={SidebarCSS.sidebarCardTitle} onClick={() => setBuildToggle(!buildToggle)}>
                            BUILD {buildToggle ? <ChevronDown /> : <ChevronUp />}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className={SidebarCSS.sidebarCardBody}>
                                <Link to={`/app/integration/${integrationId}/version/${versionId}`}
                                    getProps={(props) => getClassNames(props)}>
                                    Integration Home
                                </Link>
                                <Link to={`/app/integration/${integrationId}/version/${versionId}/authentication`}
                                    getProps={(props) => getClassNames(props)}>
                                    Authentication
                                </Link>
                                {/* <Link to={`/app/integration/${integrationId}/version/${versionId}/authentication/api-key`}
                                    getProps={(props) => getClassNames(props, true)}>
                                    API Key
                                </Link>
                                <Link to={`/app/integration/${integrationId}/version/${versionId}/authentication/basic-auth`}
                                    getProps={(props) => getClassNames(props, true)}>
                                    Basic Auth
                                </Link>
                                <Link to={`/app/integration/${integrationId}/version/${versionId}/authentication/oauth-v2`}
                                    getProps={(props) => getClassNames(props, true)}>
                                    OAuth V2
                                </Link> */}
                                {
                                    authType &&
                                    <Link to={`/app/integration/${integrationId}/version/${versionId}/authentication/edit`}
                                        getProps={(props) => getClassNames(props, true)}>
                                        {authType && authType === 'basic' ? 'Basic Auth' : 'OAuth v2'}
                                    </Link>
                                }
                                <Link to={`/app/integration/${integrationId}/version/${versionId}/triggers`}
                                    getProps={(props) => getClassNames(props)}>
                                    Triggers
                                </Link>
                                {state.appInfo.appInfoVersion.triggers?.map(trigger => (
                                    <Link
                                        to={`/app/integration/${integrationId}/version/${versionId}/triggers/${trigger._id}/settings`}
                                        getProps={(props) => getClassNames(props, true)}
                                        key={trigger._id}
                                    >
                                        {trigger.name}
                                    </Link>
                                ))}
                                <Link to={`/app/integration/${integrationId}/version/${versionId}/actions`}
                                    getProps={(props) => getClassNames(props)}>
                                    Actions
                                </Link>
                                {state.appInfo.appInfoVersion.actions?.map(action => (
                                    <Link
                                        to={`/app/integration/${integrationId}/version/${versionId}/actions/${action._id}/settings`}
                                        getProps={(props) => getClassNames(props, true)}
                                        key={action._id}
                                    >
                                        {action.name}
                                    </Link>
                                ))}
                                <Link to={`/app/integration/${integrationId}/version/${versionId}/advanced`}
                                    getProps={(props) => getClassNames(props)}>
                                    Advanced
                                </Link>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Accordion defaultActiveKey="1">
                    <Card className={SidebarCSS.sidebarCard}>
                        <Accordion.Toggle as={Card.Header} eventKey="1" className={SidebarCSS.sidebarCardTitle} onClick={() => setManageToggle(!manageToggle)}>
                            MANAGE {manageToggle ? <ChevronDown /> : <ChevronUp />}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className={SidebarCSS.sidebarCardBody}>
                                <Link to={'/'} className={SidebarCSS.sidebarLinks}>Publishing</Link>
                                <Link to={'/'} className={SidebarCSS.sidebarLinks}>Versions</Link>
                                <Link to={'/'} className={SidebarCSS.sidebarLinks}>Sharing</Link>
                                <Link to={'/'} className={SidebarCSS.sidebarLinks}>Manage Team</Link>
                                <Link to={'/'} className={SidebarCSS.sidebarLinks}>Analytics</Link>
                                <Link to={'/'} className={SidebarCSS.sidebarLinks}>Monitoring</Link>
                                <Link to={'/'} className={SidebarCSS.sidebarLinks}>History</Link>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>
    )
}
