import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Loader = () => {
    return (
        <div style={{alignContent: 'center', alignItems: 'center', display: 'flex', fontSize: 'x-large', justifyContent: 'center', height: '100vh', position: 'fixed', width: '100vw'}}>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
        </div>
    )
}

export default Loader
