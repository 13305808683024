import React, {createContext, useReducer } from "react";
import { authReducer, authInitialState, IAuthState } from './reducers/authReducer'
import { commonReducer, commonInitialState, ICommonState } from './reducers/commonReducer'
import { appInfoReducer, appInfoInitialState, IAppInfoState } from "./reducers/appInfoReducer";
import combineReducers from 'react-combine-reducers'
import { logger , apiMiddleware } from "./middlewares";
import { notificationsReducer, notificationsInitialState, INotificationsState } from "./reducers/notificationsReducer";

interface IState {
	auth: IAuthState,
	common: ICommonState
	appInfo: IAppInfoState
	notifications: INotificationsState
}

export interface GlobalContext {
	state: IState,
	dispatch: React.Dispatch<any>
}

type GlobalReducer = (state: IState, action) => IState;

// Function to dispatch actions to middlewares, Can be used to make api calls, logging actions, etc.
const useReducerWithMiddleware = (
	reducer: GlobalReducer,
	initialState: IState,
	middlewareFns
  	) => {
		const [state, dispatch] = useReducer(reducer, initialState);
	
		console.log('in apply middleware', middlewareFns, state)
		// do something with middlewareFns
		const dispatchWithMiddleware = (action) => {
			middlewareFns.forEach((middlewareFn) => middlewareFn(action))
			dispatch(action);
		};
		
		return [state, dispatchWithMiddleware];
};



export const Context = createContext({});

const StoreProvider = ({children}) => {
	// Combine all reducers into one to ease dispatching of actions of any reducer
    const [globalReducer, globalState] = combineReducers<GlobalReducer>({
		auth: [authReducer, authInitialState],
		common: [commonReducer, commonInitialState],
		appInfo: [appInfoReducer, appInfoInitialState],
		notifications: [notificationsReducer, notificationsInitialState]
	});

	// Appy middleware functions to the GlobalReducer to make api calls
	const [state, dispatch] = useReducerWithMiddleware(globalReducer, globalState, [logger, apiMiddleware]);
	// const value = useMemo(() => [state, dispatch], [state])
	const value = {state, dispatch}
    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
};

export default StoreProvider;