import React, { useContext } from 'react'
import { PersonCircle, Gear, LightningFill, ShieldLockFill, XCircle, CheckCircle } from "react-bootstrap-icons"
import { Link } from "gatsby"
import { Props } from '../../models/props'
import AioButton from "../../components/UI-Elements/AioButton"
import { Context, GlobalContext } from '../../context/ContextStore'

export default function IntegrationHome(props: Props) {
    const { state, dispatch } = useContext(Context) as GlobalContext;
    const { authParams, triggers = [], actions = [] } = state.appInfo.appInfoVersion;
    const { type = "" } = authParams;

    function renderProgress(flag, message) {
        return (
            <div className="d-flex align-items-center mb-2">
                {flag ? <CheckCircle color="#5f84e1" />
                    : <XCircle color="#dd4b39" />}
                <div className="ml-3">{message}</div>
            </div>
        )
    }
    return (
        <div>
            <h5 className="mb-4">Integration Home</h5>
            <div className="box-container">
                <div className="d-flex  mb-4">
                    <div className="flex-grow-1">
                        <h6 className="title"><PersonCircle size={24} /> <span className="ml-2">Authentication</span></h6>
                        <p className="description mb-2">Tell us how to authenticate users with your API. OAuth 2 is most common, but Comet also supports a handful of options. Learn more.</p>
                        {renderProgress(type, `Setup Authentication${type !== "" ? " (" + type.toUpperCase() + ")" : ""}`)}
                        {renderProgress(false, "Test Authentication")}
                    </div>
                    <div className="d-flex  justify-content-end w-200px mt-3">
                        <Link to={'/app/integration/123/version/123/authentication'}>
                            <AioButton buttonType="btn-cta1">
                                Set Up Authentication
                            </AioButton>
                        </Link>
                    </div>
                </div>
                <div className="d-flex mb-4">
                    <div className="flex-grow-1">
                        <h6 className="title"><Gear size={24} /> <span className="ml-2">Triggers</span></h6>
                        <p className="description mb-2">Help users find new data as soon as it’s available in your app. Triggers connect to webhooks or polling endpoints. Learn more.</p>
                        {renderProgress(triggers.length > 0, "Setup Trigger")}
                        {renderProgress(false, "Test Trigger")}
                    </div>
                    {/* <div className="d-flex mt-3 justify-content-end w-200px">
                        <ShieldLockFill size={36} />
                    </div> */}
                </div>
                <div className="d-flex align-items-center mb-4">
                    <div className="flex-grow-1">
                        <h6 className="title"><LightningFill size={24} /> <span className="ml-2">Actions</span></h6>
                        <p className="description mb-2">Help users write data back to your integration. Actions create or update records through your API. Learn more.</p>
                        {renderProgress(actions.length > 0, "Setup Action")}
                        {renderProgress(false, "Test Action")}</div>
                    {/* <div className="d-flex align-items-center justify-content-end w-200px">
                        <ShieldLockFill size={36} />
                    </div> */}
                </div>
                <div className="d-flex align-items-center mb-4">
                    <div className="flex-grow-1">
                        <h6 className="title"><PersonCircle size={24} /> <span className="ml-2">Validate Integration</span></h6>
                        <p className="description">Check your project for errors, get specific user experience recommendations, and get a list of remaining items that are required before you can make your integration available to everyone on Comet. Learn more.</p>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-end w-200px">
                        <ShieldLockFill size={36} />
                    </div> */}
                </div>
                <div className="d-flex align-items-center mb-4">
                    <div className="flex-grow-1">
                        <h6 className="title"><PersonCircle size={24} /> <span className="ml-2">Publish</span></h6>
                        <p className="description">When you’re ready, let our review team know you want to take your integration public. We’ll respond with feedback and a timeline for a marketing launch. Learn more.</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-end w-200px">
                        <AioButton buttonType="btn-cta1">
                            Publish
                        </AioButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
