import React from "react"

interface ButtonProps extends React.ButtonHTMLAttributes<any> {
    buttonType: string
    variant?: string
}

export default function AioButton({ buttonType, className, children, ...props }: ButtonProps) {

    const classNames = `btn ${buttonType ? buttonType : 'btn-cta1'} ${className ? className : ''}`

    return <button {...props} className={classNames}>{children}</button>

}