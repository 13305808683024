import React, { useState, useContext, createRef, useEffect } from "react"
import { Accordion, Card, Form, Spinner } from "react-bootstrap"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"
import { Context, GlobalContext } from '../../../context/ContextStore'

import AioButton from "../../../components/UI-Elements/AioButton"
import CodeEditor from "../../../components/CodeEditor"
import { updateAppInfoVersionAction } from "../../../actions"
import { IAuthTestRequestReference, Props, IAuthInputRequestFields } from "../../../models"
import ApiEditor from "../common/ApiEditor"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface ContainerProps extends Props {
    integrationId?: string
    versionId?: string
}

export default function EndpointConfiguration(props: ContainerProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext

    // useStates
    const [toggleValue, setToggleValue] = useState(true)
    const [connectionLabelCodeMode, setConnectionLabelCodeMode] = useState(false)
    const [connectionLabel, setConnectionLabel] = useState('')
    const [scope, setScope] = useState('')
    const { authParams: { customConfig, requestFields = {} as IAuthInputRequestFields, source } } = state.appInfo.appInfoVersion;
    const { authorize_uri = '', token_uri = '', refresh_uri = '', authStrategy = '', authCallback = '', authRefresh = '' } = customConfig || {};
    // refs
    const authURLRef = createRef() as IAuthTestRequestReference;
    const accessTokenRef = createRef() as IAuthTestRequestReference;
    const refreshTokenRef = createRef() as IAuthTestRequestReference;
    const testRef = createRef() as IAuthTestRequestReference;

    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    // component functions
    function toggleConnectionLabelMode() {
        setConnectionLabelCodeMode(!connectionLabelCodeMode)
    }

    function saveEndpointConfig() {
        const { requestFields: authURLReqFields, source: authURLSource, mode: authURLMode } = authURLRef!.current!.getState();
        const { requestFields: accessTokenFields, source: accessTokenSource, mode: accessTokenMode } = accessTokenRef!.current!.getState();
        const { requestFields: refreshTokenFields, source: refreshTokenSource, mode: refreshTokenMode } = refreshTokenRef!.current!.getState();
        const { requestFields: testFields, source: testSource, mode: testMode } = testRef!.current!.getState();

        // Set values in the appinfo version payload
        const payload = state.appInfo.appInfoVersion
        payload.authParams.connectionLabel = connectionLabel

        payload.authParams.customConfig ? payload.authParams.customConfig : payload.authParams.customConfig = { client_id: '', client_secret: '' }
        authURLMode === 'form' ? payload.authParams.customConfig.authorize_uri = authURLReqFields.url : payload.authParams.customConfig.authStrategy = authURLSource
        accessTokenMode === 'form' ? payload.authParams.customConfig.token_uri = accessTokenFields.url : payload.authParams.customConfig.authCallback = accessTokenSource
        refreshTokenMode === 'form' ? payload.authParams.customConfig.refresh_uri = refreshTokenFields.url : payload.authParams.customConfig.authRefresh = refreshTokenSource
        testMode === 'form' ? payload.authParams.requestFields = testFields : payload.authParams.source = testSource
        payload.authParams.customConfig.scope = scope
        console.log(payload)

        // Call update appInfo version Action
        updateAppInfoVersionAction({ dispatch, payload })
    }

    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} onClick={() => setToggleValue(!toggleValue)} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    {/* <div className="chip">
                        Step 4
                    </div> */}
                    <h6 className="mb-3 flex-grow-1">4. Add OAuth v2 Endpoint Configuration</h6>
                    {toggleValue ? <ChevronDown /> : <ChevronUp />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pl-3 pr-3">
                    <>
                        <div className="mb-3">
                            <p className="description">
                                Add the Authorization URL from your API—no additional settings are typically needed—optionally with comma-separated scopes. Then add your API’s Access Token request and refresh endpoints. Comet includes the default fields, though click Show options to customize if needed. Finally add a test API call to test your authentication, and a connection label to identify accounts.
                            </p>
                        </div>
                        
                        <div>
                            <Form.Label>
                                Authorization URL
                                <span className="required">required</span>
                            </Form.Label>
                            <p className=" text-muted">Specify where to send users to authenticate with your API.</p>
                            <ApiEditor ref={authURLRef} requestFields={{ url: authorize_uri }} sourceTemplate={authStrategy} />
                        </div>
                        <Form>
                            <Form.Group controlId="newIntegrationName">
                                <Form.Label className="d-flex align-items-center">
                                    Scope<span className="optional">optional</span>
                                </Form.Label>
                                <p className="text-muted">
                                    If you want to limit Comet’s access to your app data, define the OAuth scopes with a comma or space separated list of values.
                                </p>
                                <Form.Control type="text"
                                    required
                                    placeholder="Enter Scope"
                                    onChange={(e) => setScope(e.target.value)} />
                            </Form.Group>
                        </Form>
                        
                        <div className="mt-4 ">
                            <Form.Label>
                                Access Token Request
                                <span className="required">required</span>
                            </Form.Label>
                            <p className=" text-muted">Enter the API endpoint URL where Comet sends the approval code on user redirect, typically via POST, and receives access_token in the response.</p>
                            <ApiEditor ref={accessTokenRef} requestFields={{ url: token_uri }} sourceTemplate={authCallback} />
                        </div>

                        
                        <div className="mt-4 ">
                            <Form.Label>
                                Refresh Token Request
                                <span className="optional">optional</span>
                            </Form.Label>
                            <p className=" text-muted">Enter the API endpoint URL where Comet can request a refreshed access token when a RefreshAuthError error is thrown.</p>
                            <ApiEditor ref={refreshTokenRef} requestFields={{ url: refresh_uri }} sourceTemplate={authRefresh} />
                        </div>
                        
                        <div className="mt-4 ">
                            <Form.Label>
                                Test
                                <span className="required">required</span>
                            </Form.Label>
                            <p className=" text-muted">Enter an API endpoint URL to test authentication credentials, ideally one needing no configuration such as /me.</p>
                            <ApiEditor ref={testRef} requestFields={requestFields} sourceTemplate={source} />
                        </div>

                        <div>
                            <Form.Label>
                                Connection Label
                                <span className="optional">optional</span>
                            </Form.Label>
                            <p className="text-muted ">
                                Customize the connection label in Comet to help users differentiate between app accounts. Include info from authentication input fields with <span className="bg-light p-1">bundle.authData.field</span> or from test request output fields with <span className="bg-light p-1">bundle.inputData.field</span>, replacing field with the field key you wish to use.
                            </p>
                            {
                                // Switch between connection label input mode and code mode
                                connectionLabelCodeMode ? (
                                    <div className="mt-3" style={{ height: '150px' }}>
                                        <CodeEditor language={"javascript"} onChange={(value) => setConnectionLabel(value)} classes={"w-100"} />
                                        <AioButton buttonType="btn-cta2" className="flex-shrink-0 mt-3" onClick={toggleConnectionLabelMode}>
                                            Delete Code
                                        </AioButton>
                                    </div>
                                ) : (
                                    <div className="d-flex mt-3">
                                        <Form.Control type="text" className="flex-grow-1" placeholder="Enter Connection Label" onChange={(e) => setConnectionLabel(e.target.value)} />
                                        <AioButton buttonType="btn-cta2" className="flex-shrink-0 " onClick={toggleConnectionLabelMode}>
                                            Edit Code
                                        </AioButton>
                                    </div>
                                )
                            }
                        </div>
                        
                        <div className="mt-3">
                            <AioButton buttonType="btn-cta1" className="float-right" disabled={postLoader} onClick={saveEndpointConfig}>
                                { !postLoader ? 'Save and Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                            </AioButton>
                        </div>
                    </>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
