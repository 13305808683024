import { types as ActionTypes } from '../../actions'
import { IAppInfo, IAppInfoVersion, IActionPayload, ILoadAppInfoVersionsPayload } from '../../models'

export interface IAppInfoState {
    isLoading: boolean
    appInfos: Array<IAppInfo>
    appInfo: IAppInfo
    appInfoVersion: IAppInfoVersion
    versions: { [key: string]: Array<IAppInfoVersion> }
}
const defaultAppInfoVersion: IAppInfoVersion = {
    _id: '',
    appInfoVId: '',
    appInfoId: '',
    version: '0.0.0',
    status: 'private',
    authParams: {
        inputFields: [],
        type: 'basic',
        customConfig: {
            client_id: '',
            client_secret: ''
        }
    },
    triggers: [],
    actions: [],
    changelog: {},
    platform: 'gui',
    etag: '',
    deprecated: false
}
export const appInfoInitialState: IAppInfoState = {
    isLoading: false,
    appInfos: [] as Array<IAppInfo>,
    appInfo: {
        _id: '',
        name: '',
        appId: '',
        accountId: '',
        version: '0.0.0',
        status: 'private',
    },
    appInfoVersion: defaultAppInfoVersion,
    versions: {}
}

export const appInfoReducer = (state = appInfoInitialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_APPINFOS_DATA: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ActionTypes.LOAD_APPINFOS_DATA_SUCCESS: {
            const payload = action.payload as IAppInfo[]
            return {
                ...state,
                isLoading: false,
                appInfos: payload ? payload : [],
            }
        }
        case ActionTypes.LOAD_APPINFO_DATA_FAIL:
        case ActionTypes.LOAD_APPINFOS_DATA_FAIL: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case ActionTypes.LOAD_APPINFO_DATA_SUCCESS: {
            const payload = action.payload
            return {
                ...state,
                isLoading: false,
                appInfo: payload
            }
        }
        case ActionTypes.UPDATE_APPINFO_DATA: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ActionTypes.UPDATE_APPINFO_DATA_SUCCESS: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ActionTypes.UPDATE_APPINFO_DATA_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ActionTypes.POST_APPINFO_DATA: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ActionTypes.POST_APPINFO_DATA_SUCCESS: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ActionTypes.POST_APPINFO_DATA_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ActionTypes.DELETE_APPINFO_DATA_SUCCESS: {
            const payload = action.payload
            return {
                ...state,
                appInfos: state.appInfos.filter(each => each._id !== payload.appInfoId)
            }
        }
        case ActionTypes.UPDATE_APPINFO_VERSION: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ActionTypes.UPDATE_APPINFO_VERSION_FAIL: {
            return {
                ...state,
                isLoading: false
            }
        }
        case ActionTypes.UPDATE_APPINFO_VERSION_SUCCESS: {
            const payload = {
                ...action.payload,
                appInfoVId: action.payload._id
            }
            return {
                ...state,
                isLoading: false,
                appInfoVersion: payload
            }
        }
        case ActionTypes.LOAD_APPINFO_VERSION_SUCCESS: {
            const payload = {
                ...action.payload,
                appInfoVId: action.payload._id
            }
            return {
                ...state,
                appInfoVersion: payload
            }
        }
        case ActionTypes.LOAD_APPINFO_VERISONS_SUCCESS: {
            const responsePayload = action.payload
            const initialPayload = action.initialPayload as ILoadAppInfoVersionsPayload

            const updatedVersions = state.versions
            updatedVersions[initialPayload.appInfoId] = responsePayload
            return {
                ...state,
                version: updatedVersions
            }
        }
        case ActionTypes.CLEAR_APPINFO_VERSION_DATA: {
            return {
                ...state,
                appInfoVersion: defaultAppInfoVersion
            }
        }
        default:
            return {
                ...state
            }
    }
}