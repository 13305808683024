import React, { useEffect, useState } from "react"
import { Form, Table } from "react-bootstrap"

import CodeEditor from "../../../components/CodeEditor"
import InputFieldFormEditor from "./InputFieldFormEditor"
import LineGroupInputFormEditor from "./LineGroupInputFormEditor"
import AioButton from "../../../components/UI-Elements/AioButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { INPUT_DESIGNER_TYPE, ACTION_TYPE } from "../../../utils/constants/appConstants"
import { IActivityInputFields } from "../../../models"

interface IActionInputFields extends IActivityInputFields {
    inputDesignerType?: string
    id?: number
    isNew?: boolean
    multipleInputs?: Array<{id: string, value: string}>
}

interface FormEditorProps {
    handleInputFieldSubmit: (event: Event) => void
    activeInputFields: Array<IActionInputFields>
    activeInputField: IActionInputFields
    setActiveInputField: React.Dispatch<React.SetStateAction<IActionInputFields>>
    addStatus: string
    setAddStatus: React.Dispatch<React.SetStateAction<string>>
    toggleAddInput: boolean
    changeToggleStatus: (actionType: string) => void
    inputFieldList: IActionInputFields[]
    setInputFieldList: React.Dispatch<React.SetStateAction<IActionInputFields[]>>
    validated: boolean
    setValidated: React.Dispatch<React.SetStateAction<boolean>>
    newInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, value: string, inputId) => void
    setToggleAddInput: React.Dispatch<React.SetStateAction<boolean>>
    setDropdownType
    showLineGroupInputForm: boolean
    lineGroupInputChangeHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, value: string) => void
    // lineItemGroupList,
    addLineGroupInput: () => void
    deleteInputFieldHandler: (inputId: string) => void
    submitLineGroupInputForm: () => void
    editLineItemHandler: (item: IActionInputFields) => void
    previewUIStatus: string
    setShowLineGroupInputForm: React.Dispatch<React.SetStateAction<boolean>>
}

const groupLineItems = (list: IActionInputFields[], activeItem: IActionInputFields) => {
    const activeItemName = activeItem.groupName
    return list.filter(each => each.groupName && each.groupName === activeItemName)
}

const modifyInputFieldList = (list) => {
    const withoutGroupName = list.filter(each => !each.groupName)
    const withGroupName = list.filter(each => each.groupName)
    // Remove duplicate objects based on groupName
    const filteredList = withGroupName.filter((v, i, a) => a.findIndex(t => (t.groupName === v.groupName)) === i)
    return [...withoutGroupName, ...filteredList]
}

export default function FormEditor(
    {
        handleInputFieldSubmit,
        activeInputFields,
        activeInputField,
        setActiveInputField,
        addStatus,
        setAddStatus,
        toggleAddInput,
        changeToggleStatus,
        inputFieldList,
        setInputFieldList,
        validated,
        setValidated,
        newInputChange,
        setToggleAddInput,
        setDropdownType,
        showLineGroupInputForm,
        lineGroupInputChangeHandler,
        // lineItemGroupList,
        addLineGroupInput,
        deleteInputFieldHandler,
        submitLineGroupInputForm,
        editLineItemHandler,
        previewUIStatus,
        setShowLineGroupInputForm
    }: FormEditorProps) {
    const [toggleAdd, setToggleAdd] = useState(false)
    const [inputFields, setInputFields] = useState<IActionInputFields[]>([])
    const [validatedLineGroup, setValidatedLineGroup] = useState(false)

    useEffect(() => {
        setInputFields(activeInputFields ? activeInputFields : [])
    }, [activeInputFields])

    function activeAddStatus(actionType: string) {
        setAddStatus(actionType)
        setToggleAddInput(true)
        setToggleAdd(false)
        if (actionType === ACTION_TYPE.INPUT_FIELD) {
            changeToggleStatus(ACTION_TYPE.INPUT_FIELD)
        }
    }

    const defaultValue = ""

    const submitLineGroupForm = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.checkValidity()) {
            setToggleAddInput(false)
        }
        setValidatedLineGroup(true)
    }

    const editInputField = (inputField, type) => {
        setToggleAddInput(true)
        setAddStatus(type === INPUT_DESIGNER_TYPE.INPUT_FIELD ? ACTION_TYPE.INPUT_FIELD : ACTION_TYPE.GROUP_FIELD)
        setActiveInputField(inputField)
    }

    const addLineGroupInputHandler = () => {
        if (activeInputField.groupName) {
            addLineGroupInput()
            setValidated(false)
        } else {
            setValidatedLineGroup(true)
        }
    }

    return (
        <div className={`pt-3 pl-0 pr-3 pb-3 scroll-bar-css-container form-editor-action-container ${previewUIStatus === 'hide' ? '' : 'border-right'} flex-grow-1`}>
            <h5>Form Editor</h5>
            {
                [ACTION_TYPE.NONE, ACTION_TYPE.INPUT_FIELD].includes(addStatus) && !inputFieldList.length &&
                <div className="box-container shadow-none border mt-3">
                    <p className="description">
                        Comet Actions include a form where users enter plain text and map Trigger and other Action input
                        fields to send data to your API. Add each field needed to create this Action item in your
                        integration.
                    </p>
                </div>
            }
            {
                !toggleAddInput && inputFields.length > 0 &&
                <Table responsive="sm" hover className="my-4 box-container">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Label</th>
                            <th>Key</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            modifyInputFieldList(inputFields).map((eachInput, index: number) => {
                                return (
                                    <tr key={index} className="cursor-pointer" onClick={() => editInputField(eachInput, eachInput.groupName ? INPUT_DESIGNER_TYPE.GROUP_ITEM : INPUT_DESIGNER_TYPE.INPUT_FIELD)}>
                                        <td>{eachInput.groupName ? 'Group Fields' : 'Input Field'}</td>
                                        <td>{eachInput.groupName ? eachInput.groupName : eachInput.label}</td>
                                        <td>{eachInput.groupName ? eachInput.groupKey : eachInput.key}</td>
                                        <td onClick={e => e.stopPropagation()}>
                                            {!eachInput.groupName && <div onClick={() => deleteInputFieldHandler(eachInput._id)}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </div>}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            }
            {
                !toggleAddInput && (<div className="d-flex flex-column w-200px ml-auto">
                    <AioButton buttonType="btn-cta1" className="ml-auto" onClick={() => setToggleAdd(!toggleAdd)}>
                        Add
                    </AioButton>
                    {toggleAdd && <div className="box-container">
                        <div className="cursor-pointer mb-2" onClick={() => activeAddStatus(ACTION_TYPE.INPUT_FIELD)}>Input Field</div>
                        <div className="cursor-pointer mb-2" onClick={() => activeAddStatus(ACTION_TYPE.DYNAMIC_FIELD)}>Dynamic Field</div>
                        <div className="cursor-pointer" onClick={() => activeAddStatus(ACTION_TYPE.GROUP_FIELD)}>Group Fields</div>
                    </div>}
                </div>)
            }
            {
                toggleAddInput && addStatus === ACTION_TYPE.INPUT_FIELD &&
                <InputFieldFormEditor validated={validated}
                    setValidated={setValidated}
                    inputFieldList={inputFieldList}
                    setInputFieldList={setInputFieldList}
                    handleInputFieldSubmit={handleInputFieldSubmit}
                    activeInputField={activeInputField}
                    newInputChange={newInputChange}
                    setToggleAddInput={setToggleAddInput}
                    setDropdownType={setDropdownType} />
            }
            {
                toggleAddInput && addStatus === ACTION_TYPE.DYNAMIC_FIELD && <div className="h-75 code-editor-action">
                    <CodeEditor language="javascript" value={defaultValue} />
                    <div className="d-flex justify-content-end mt-3">
                        <AioButton buttonType="btn-cta2" className="mr-3" onClick={() => setToggleAddInput(false)}>Cancel</AioButton>
                        <AioButton buttonType="btn-cta1">Save</AioButton>
                    </div>
                </div>
            }
            {
                toggleAddInput && addStatus === ACTION_TYPE.GROUP_FIELD && !showLineGroupInputForm && <div className="mt-3">
                    <Form noValidate validated={validatedLineGroup} onSubmit={submitLineGroupForm} className="box-container">
                        <Form.Group>
                            <Form.Label>Group Fields Key <span className="optional">optional</span></Form.Label>
                            <Form.Text className="form-help-text">
                                Enter a user friendly name for what this Group Fields creates. Example: Create Tickets
                            </Form.Text>
                            <Form.Control type="text"
                                          value={activeInputField.groupKey}
                                          disabled={!activeInputField.isNew}
                                        //   onChange={e => lineGroupInputChangeHandler(e, 'groupKey', activeInputField.isNew ? 'isNew' : activeInputField._id)} />
                                          onChange={e => lineGroupInputChangeHandler(e, 'groupKey')} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Group Fields Name <span className="required">required</span></Form.Label>
                            <Form.Text className="form-help-text">
                                What is the object or item in the name above that this line item creates? Example: Tickets
                            </Form.Text>
                            <Form.Control type="text"
                                          value={activeInputField.groupName}
                                          disabled={!activeInputField.isNew}
                                          required
                                        //   onChange={e => lineGroupInputChangeHandler(e, 'groupName', activeInputField.isNew ? 'isNew' : activeInputField._id)} />
                                          onChange={e => lineGroupInputChangeHandler(e, 'groupName')} />
                            <Form.Control.Feedback type="invalid">
                                This field is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <h5>Group Items</h5>
                        <Table className="border rounded" hover>
                            <thead>
                                <tr>
                                    <th>Label</th>
                                    <th>Key</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    groupLineItems(inputFieldList, activeInputField).map((item, index) => {
                                        return (
                                            <tr key={index} className="cursor-pointer" onClick={() => editLineItemHandler(item)}>
                                                <td>{item.label}</td>
                                                <td>{item.key}</td>
                                                <td onClick={e => e.stopPropagation()}>
                                                    <div onClick={() => {
                                                            if (item._id) {
                                                                deleteInputFieldHandler(item._id)
                                                            }
                                                        }}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    groupLineItems(inputFieldList, activeInputField).length === 0 &&
                                        <div className="form-help-text p-3 mb-0">
                                            No Group Items
                                        </div>
                                }
                            </tbody>
                        </Table>
                        <AioButton buttonType="btn-cta2" className="mt-2" onClick={() => addLineGroupInputHandler()}>Add Group Item</AioButton>
                        <div className="d-flex justify-content-end mt-4">
                            <AioButton buttonType="btn-cta2" variant="secondary" className="mr-3" onClick={() => setToggleAddInput(false)}>Cancel</AioButton>
                            <input type="submit" value="Save" className="btn btn-primary px-4" />
                        </div>
                    </Form>
                </div>
            }
            {
                showLineGroupInputForm &&
                <LineGroupInputFormEditor validated={validated}
                    setValidated={setValidated}
                    // type="lineGroup"
                    submitLineGroupInputForm={submitLineGroupInputForm}
                    activeInputField={activeInputField}
                    // lineItemGroupList={lineItemGroupList}
                    groupLineItems={groupLineItems(inputFieldList, activeInputField)}
                    lineGroupInputChangeHandler={lineGroupInputChangeHandler}
                    setToggleAddInput={setToggleAddInput}
                    setShowLineGroupInputForm={setShowLineGroupInputForm}
                    // setDropdownType={setDropdownType} 
                    />
            }
        </div >
    )
}
