import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap'
import { Context, GlobalContext } from '../context/ContextStore'
import { removeNotificationAction } from '../actions'

const Notifications = () => {
    const { state, dispatch } = useContext(Context) as GlobalContext

    function removeNotification(index: number) {
        removeNotificationAction({ dispatch, payload: { index: index} })
    }

    const notifications = state.notifications.notifications
    
    return (
        <div className="notifications-wrapper">
            {
                notifications && (notifications.length > 0) ? (notifications.map((n, index) => (
                    <Alert className={n.classes} dismissible key={`notification_${index}`} onClose={() => removeNotification(index)}>
                        <p>{n.error.message}</p>
                    </Alert>
                ))):
                (<></>)
            }
        </div>
    )
}

export default Notifications
