import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { Dash, XLg } from "react-bootstrap-icons"
import AioButton from "../../../components/UI-Elements/AioButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"

export default function FormPreview({ inputFields, activeInputField }) {
    return (
        <div className="w-50 pt-3 pr-0 pb-3 pl-3  preview-container min-w-50 ">
            <h5 className="text-center">User Preview</h5>
            {!Object.keys(activeInputField).length && <p className="text-muted text-center mt-4">
                Get a preview of your app here as you build it in the Form Editor
            </p>}
            <div className={`${inputFields.length > 0 ? "box-container" : ""}`}>
                {inputFields.map((eachPreview, index) => <EachPreview previewData={eachPreview} key={eachPreview._id} />)}
            </div>
        </div>
    )
}
interface IMultipleList {
    id: number
    value: string
}
function EachPreview(props) {
    const { label, required, type, description, defaultValue, allowsMultiples, inputType, list = [], dynamicFields={} } = props.previewData;
    const [allowMultipleList, setAllowMultipleList] = useState<IMultipleList[]>([]);
    function addMultiple() {
        setAllowMultipleList([...allowMultipleList, { id: new Date().getTime(), value: "" }]);
    }
    const staticFields =list;
    function removeMultiple(id) {
        if (allowMultipleList.length > 1) {
            setAllowMultipleList(allowMultipleList.filter(item => item.id !== id));
        }
    }
    useEffect(() => {
        setAllowMultipleList([{ id: new Date().getTime(), value: "" }]);
    }, [])
    function renderFormControlElement(idx = 0) {
        if (staticFields.length > 0) {
            return (
                <Form.Control
                    as="select" custom className="mb-3">
                    <option hidden>Choose Value</option>
                    {staticFields.map((field, index) => (
                        <option key={index} value={field}>{field}</option>
                    ))}
                </Form.Control>
            )
        }
        return (
            <Form.Control
                type={type ? type.toLowerCase() : "text"}
                // value={value}
                defaultValue={idx === 0 ? defaultValue : ""}
                className="mb-3"
                // onChange={() => { }}
                placeholder="Enter text..."
            />
        );
    }
    function renderFormFields() {
        if (dynamicFields.triggerId) {
            return (<div className="border rounded p-2 mb-3 bg-light">
                <Form.Text className="form-help-text mb-0">
                    The preview doesn’t use live data, so the {dynamicFields.label ? dynamicFields.label : 'New'} dropdown will be empty here.
                </Form.Text>
            </div>)
        }
        else if (inputType !== "MultiInput") {
            return (<>
                {renderFormControlElement()}
                {description && <Form.Text className="form-help-text">{description}</Form.Text>}
            </>);
        }
        return (
            <>
                {allowMultipleList.map((item, idx) => (
                    <div className="d-flex align-items-center justify-content-between form-label" key={item.id}>
                        {idx !== 0 && <FontAwesomeIcon icon={faMinusCircle}
                            className="flex-shrink-0 cursor-pointer mr-3 mb-3"
                            onClick={() => removeMultiple(item.id)} />}
                        {renderFormControlElement(idx)}
                    </div>
                ))}
                {description && <Form.Text className="form-help-text">{description}</Form.Text>}
            </>
        )
    }
    return (
        <Form className="mt-4 mx-3">
            <Form.Group controlId="previewInput">
                <Form.Label className="d-flex align-items-center justify-content-between">
                    {label || 'New'}
                    {required && <span className="required float-right">required</span>}
                </Form.Label>
                {renderFormFields()}

                {inputType === "MultiInput" &&
                    <div className="cursor-pointer" onClick={addMultiple}>
                        <FontAwesomeIcon icon={faPlusCircle} className="flex-shrink-0 mr-2" />
                        Add Field
                    </div>

                }
            </Form.Group>
        </Form>
    )
}
