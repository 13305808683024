import React, { useContext } from 'react'
import { navigate } from "gatsby"
import AioButton from "../../components/UI-Elements/AioButton"
import TriggersList from "../components/TriggersList"
import { Context, GlobalContext } from '../../context/ContextStore'
import { updateAppInfoVersionAction } from '../../actions'
import { Props } from '../../models'

interface TriggersProps extends Props {
    integrationId?: string
    versionId?: string
}

export default function Triggers(props: TriggersProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext;
    const { triggers = [] } = state.appInfo.appInfoVersion;

    function addNewTrigger() {
        navigate(`/app/integration/${props.integrationId}/version/${props.versionId}/triggers/new`)
    }
    function navigateTo(triggerId) {
        navigate(`/app/integration/${props.integrationId}/version/${props.versionId}/triggers/${triggerId}/settings`)
    }
    function deleteTrigger(triggerId) {
        //on delete, filtering the triggers list and call update api
        let clonedAppInfoVersion = { ...state.appInfo.appInfoVersion }
        const clonedTriggers = [...clonedAppInfoVersion.triggers]
        let updatedTriggers = clonedTriggers.filter(each => each._id !== triggerId)
        clonedAppInfoVersion = {
            ...clonedAppInfoVersion,
            triggers: updatedTriggers
        }
        updateAppInfoVersionAction({
            dispatch: dispatch,
            payload: clonedAppInfoVersion
        })
    }
    return (
        <div>
            <h5 className="mb-2">Trigger</h5>
            {!triggers.length && <div className="w-75">
                <div className="box-container mt-3">
                    <h6 className="title">Add Your First Trigger</h6>
                    <p className="description">
                        Triggers GET or read data from your API to start workflows. Add triggers for new and updated
                        items in your app so users can use the data in other apps.
                    </p>
                </div>
                <AioButton buttonType="btn-cta1" className="ml-auto pl-4 pr-4" onClick={addNewTrigger}>
                    Add Trigger
                </AioButton>
            </div>}
            {triggers.length > 0 && <>
                <TriggersList
                    triggersList={triggers}
                    onRowSelect={navigateTo}
                    onDelete={deleteTrigger} />
                <div className="float-right">
                    <AioButton buttonType="btn-cta1" className="ml-auto pl-4 pr-4" onClick={addNewTrigger}>
                        Add Trigger
                    </AioButton>
                </div>
            </>}
        </div>
    )
}
