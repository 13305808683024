import React, { useContext, useState, useEffect } from 'react'
import APIKey from './APIKey'
// import BasicAuth from './BasicAuth'
import OAuthV2 from './OAuthV2'
import { Context, GlobalContext } from '../../context/ContextStore'
import { Props } from '../../models'

const EditAuthentication = (props: Props) => {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [ authType, setAuthType ] = useState('')
    useEffect(() => {
        setAuthType(state.appInfo.appInfoVersion.authParams.type)
    }, [state.appInfo.appInfoVersion.authParams.type])
    
    return (
        <div>
            {authType === 'basic' && <APIKey  />}
            {authType === 'OAuthV2' && <OAuthV2 />}
        </div>
    )
}

export default EditAuthentication
