import React from "react"
import { Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"
import { Props, IAppInfoAction } from "../../models"

interface ActionListProps extends Props {
    actionsList: Array<IAppInfoAction>,
    deleteActionHandler: (actionId: string) => void,
    setActiveAction: React.Dispatch<React.SetStateAction<IAppInfoAction>>
    integrationId?: string
    versionId?: string
}
export default function ActionsList(
    {
        actionsList,
        deleteActionHandler,
        setActiveAction,
        integrationId,
        versionId
    }: ActionListProps) {

    function actionClickHandler(action) {
        console.log(action)
        setActiveAction(action)
        navigate(`/app/integration/${integrationId}/version/${versionId}/actions/${action._id}`)
    }

    return (
        <>
            <Table responsive="sm" hover className="box-container">
                <thead>
                <tr>
                    <th>Label</th>
                    <th>Name</th>
                    {/* <th>Important</th> */}
                    <th>Hidden</th>
                    <th> </th>
                </tr>
                </thead>
                <tbody>
                {
                    actionsList.map((action, index) => {
                        return (
                            <tr key={index} className="cursor-pointer" onClick={() => actionClickHandler(action)}>
                                <td>{action.label}</td>
                                <td>{action.name}</td>
                                {/* <td>{action.important ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} /> }</td> */}
                                <td>{action.hidden ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} /> }</td>
                                <td onClick={e => e.stopPropagation()}>
                                    <div onClick={() => deleteActionHandler(action._id)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
        </>
    )
}