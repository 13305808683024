import React from 'react'
import { navigate } from "gatsby"

import AioButton from "../../components/UI-Elements/AioButton"
import ActionsList from "../components/ActionsList"
import { Props, IAppInfoAction, IAppInfoActivity } from '../../models'

interface ActionsProps extends Props {
    actionsList: Array<IAppInfoAction>
    addNewActionHandler: () => void
    deleteActionHandler: (actionId: string) => void
    setActiveAction: React.Dispatch<React.SetStateAction<IAppInfoAction>>
    integrationId?: string
    versionId?: string
}
export default function Actions(
    {
        actionsList,
        addNewActionHandler,
        deleteActionHandler,
        setActiveAction,
        integrationId,
        versionId
    }: ActionsProps) {

    const addActionClick = () => {
        addNewActionHandler()
        navigate(`/app/integration/${integrationId}/version/${versionId}/actions/new`)
    }

    return (
        <div>
            <h5 className="mb-2">Actions</h5>
            {!actionsList.length && 
                <div className="w-75">
                    <div className="box-container mt-3">
                        <h6 className="title">Add Your First Action</h6>
                        <p className="description">
                            Actions POST or send data to your API, to find, update, or create items using data from Triggers and previous Actions in the Integration. Add actions for everything users can find, add, or update in your app.
                        </p>
                    </div>
                    <AioButton buttonType="btn-cta1" className="ml-auto pl-4 pr-4" onClick={addActionClick}>
                        Add Action
                    </AioButton>
                </div>
            }
            {actionsList.length > 0 && 
                <>
                    <ActionsList actionsList={actionsList}
                        integrationId={integrationId}
                        versionId={versionId}
                        deleteActionHandler={deleteActionHandler}
                        setActiveAction={setActiveAction} />
                    <div className="float-right">
                        <AioButton buttonType="btn-cta1" className="ml-auto pl-4 pr-4" onClick={addActionClick}>
                            Add Action
                        </AioButton>
                    </div>
                </>
            }
        </div>
    )
}
