import React, { useContext, useEffect, useState } from "react"
import { Form, Spinner } from "react-bootstrap"
import { RouteComponentProps } from "@reach/router"
import AioButton from "../../../components/UI-Elements/AioButton";
import { Context, GlobalContext } from "../../../context/ContextStore";
import { IActivityInputFields, IAuthInputFields, Props } from "../../../models";
import FormPreview from "./FormPreview";
import InputFieldsList from "./InputFieldsList";
import { updateAppInfoVersionAction } from "../../../actions";
import { faShieldAlt, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const defaultInputFieldState: IActivityInputFields = {
    _id: "",
    description: "",
    key: "",
    type: "String",
    required: false,
    dropdownType: "none",
    label: '',
    defaultValue: '',
    allowsMultiples: false,
    dynamicFields: { triggerId: "", key: "", label: "" },
    list: [],
    inputType: "Input"
}
interface InputDesignerProps extends Props, RouteComponentProps {

}
export default function InputDesigner(props: InputDesignerProps) {
    //state
    const { state, dispatch } = useContext(Context) as GlobalContext;
    const { triggers, etag } = state.appInfo.appInfoVersion;
    const [inputFields, setInputFields] = useState<IActivityInputFields[]>([]);
    const [activeInputFieldId, setActiveInputFieldId] = useState("");
    const [validated, setValidated] = useState(false);
    const [previewUIStatus, setPreviewUIStatus] = useState('show');
    const [dropdownType, setDropdownType] = useState("none")

    //active elements
    const activeInputField = inputFields.find(item => item._id === activeInputFieldId) || defaultInputFieldState;
    const { label, key, _id, description, type, inputType, allowsMultiples = false, dynamicFields, required, list = [], defaultValue } = activeInputField as IActivityInputFields;
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        if (props.triggerId && triggers.length) {
            const activeTrigger = triggers.find(trigger => trigger._id === props.triggerId) || { inputFields: [] };
            setInputFields(activeTrigger.inputFields);
        }
    }, [etag]);
    useEffect(() => {
        setDropdownType(dynamicFields && dynamicFields.triggerId ? "dynamic" : list.length ? "static" : "none");
    }, [_id])

    //submit form
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault()
        event.stopPropagation()
        setValidated(true)
        if (form.checkValidity()) {
            const isKeyAlreadyDefined = inputFields.some(field => field.key === key && field._id !== _id);
            if (!isKeyAlreadyDefined) {
                setValidated(false)
                const updatedInputFields = inputFields.map(item => {
                    if (item._id && item._id.includes("_new")) {
                        delete item._id;
                    }
                    return item;
                })
                setActiveInputFieldId("");
                updateAppInfoVersion(updatedInputFields)
            }
        }
    }

    //add new input
    const addInputFieldsHandler = () => {
        const inputFieldVal = {
            ...defaultInputFieldState,
            _id: new Date().getTime() + "_new",
        }
        const newInputFields = [...inputFields, inputFieldVal];
        setInputFields(newInputFields);
        setActiveInputFieldId(inputFieldVal._id);
    }
    const editInputFieldHandler = (inputId) => {
        setActiveInputFieldId(inputId);
    }
    const deleteInputFieldHandler = (inputFieldId: string) => {
        const newInputFields = inputFields.filter(({ _id }) => _id !== inputFieldId);
        updateAppInfoVersion(newInputFields);
    }
    const changeInputFieldsHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, type: string, inputFieldId: string | undefined) => {
        const updatedInputFields = [...inputFields.map(eachInput => {
            if (eachInput._id === inputFieldId) {
                let finalValue: any = e.target.value;
                if (type === "dynamicFields") {
                    finalValue = {
                        ...eachInput.dynamicFields, [e.target.name]: e.target.value
                    }
                } else if (type === "required") {
                    finalValue = e.target.value === "yes";
                } else if (type === "list") {
                    finalValue = e.target.value ? e.target.value.split(",") : []
                }
                return {
                    ...eachInput,
                    [type]: finalValue
                }
            }
            return eachInput
        })]
        setInputFields(updatedInputFields);
    }
    const cancelForm = () => {
        const activeTrigger = triggers.find(trigger => trigger._id === props.triggerId) || { inputFields: [] };
        setActiveInputFieldId("");
        setInputFields(activeTrigger.inputFields);
    }
    const updateAppInfoVersion = (inputFieldsData) => {
        const clonedAppInfoVersion = JSON.parse(JSON.stringify(state.appInfo.appInfoVersion));
        clonedAppInfoVersion.triggers = clonedAppInfoVersion.triggers.map(trigger => {
            if (props.triggerId === trigger._id) {
                trigger.inputFields = inputFieldsData;
            }
            return trigger;
        })
        updateAppInfoVersionAction({
            dispatch: dispatch,
            payload: clonedAppInfoVersion
        })

    }
    function renderEditorMsg() {
        return (
            <>
                <div className="box-container shadow-none border mt-3">
                    <h6 className="title">Add Input Form Fields</h6>
                    <p className="description">
                        Let users customize the trigger to watch for specific items with the Integration Editor form. Add input fields so users can select or enter data, then Comet will send it to your API.
                    </p>
                    <p className="mt-2 mb-0">
                        If your trigger doesn’t include user customizable options, skip ahead to API Configuration.
                    </p>
                </div>
                <p className="text-muted">
                    Comet Triggers may optionally include a form where users enter plain text to pass to your API. Add each field needed to create this Trigger item in your integration.
                </p>
                <AioButton buttonType="btn-cta1" className="ml-auto" onClick={addInputFieldsHandler}>
                    Add User Input Field
                </AioButton>
            </>
        )
    }


    function renderInputFieldsList() {
        return (
            <>
                <InputFieldsList inputFields={inputFields} deleteInputFieldHandler={deleteInputFieldHandler} editInputFieldHandler={editInputFieldHandler} />
                <AioButton buttonType="btn-cta1" className="ml-auto" onClick={addInputFieldsHandler}>
                    Add User Input Field
                </AioButton>
            </>
        )
    }
    function renderForm() {
        return (<>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-3 box-container">
                <Form.Group controlId="newInputKey">
                    <Form.Label>
                        Key<span className="required">required</span>
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        Enter the word or phrase your API uses to reference this field or parameter. Not seen by users. Example: <span className="bg-light px-2">first_name</span>
                    </Form.Text>
                    <Form.Control type="text"
                        required
                        placeholder="Enter key"
                        value={key}
                        isInvalid={inputFields.some(field => field.key === key && field._id !== _id)}
                        onChange={e => changeInputFieldsHandler(e, 'key', _id)} />
                    <Form.Control.Feedback type="invalid">
                        {validated && inputFields.some(field => field.key === key && field._id !== _id) ? "Choose a unique label." : validated && key === "" ? "This field is required." : ""}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="newInputLabel">
                    <Form.Label>
                        Label<span className="required">required</span>
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        Enter a user friendly name for this field that describes what to enter. Shown to users inside Comet. Example: <span className="bg-light px-2">First Name</span>
                    </Form.Text>
                    <Form.Control type="text"
                        required
                        placeholder="Enter label"
                        value={label}
                        onChange={e => changeInputFieldsHandler(e, 'label', _id)} />
                    <Form.Control.Feedback type="invalid">
                        This field is required.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="newInputHelp">
                    <Form.Label>
                        Help Text<span className="optional">optional</span>
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        Describe clearly the purpose of this field in a complete, Markdown formatted sentence with at least 20 characters. Example: <span className="bg-light px-2">Filter by first name.</span>
                    </Form.Text>
                    <Form.Control as="textarea"
                        rows={3}
                        type="text"
                        placeholder="Enter help text"
                        value={description}
                        onChange={e => changeInputFieldsHandler(e, 'description', _id)} />
                </Form.Group>
                <Form.Group controlId="newInputHelp">
                    <Form.Label>
                        Type<span className="optional">optional</span>
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        What type of data does this field collect?
                    </Form.Text>
                    <Form.Control as="select" custom
                        value={type}
                        onChange={e => changeInputFieldsHandler(e, 'type', _id)}>
                        <option value='String'>String</option>
                        <option value='Number'>Number</option>
                        <option value='Date'>Date</option>
                        <option value='Password'>Password</option>
                        <option value='Subdomain'>Subdomain</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="newInputLabel">
                    <Form.Label>
                        Default Text <span className="optional">optional</span>
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        If most users need the same option, add default text that Comet will save when the Integration  is created if the user leaves it blank.
                    </Form.Text>
                    <Form.Control type="text"
                        placeholder="Enter default text"
                        value={defaultValue}
                        onChange={e => changeInputFieldsHandler(e, 'defaultValue', _id)} />
                </Form.Group>

                <Form.Group controlId="newInputHelp">
                    <Form.Label>
                        Required<span className="optional">optional</span>
                    </Form.Label>
                    <Form.Control as="select" custom
                        value={required ? "yes" : "no"}
                        onChange={e => changeInputFieldsHandler(e, 'required', _id)}>
                        <option value={"yes"}>Yes</option>
                        <option value={"no"}>No</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="newInputHelp">
                    <Form.Label>
                        Allows Multiples<span className="optional">optional</span>
                    </Form.Label>
                    <Form.Control as="select" custom
                        value={inputType}
                        onChange={e => changeInputFieldsHandler(e, 'inputType', _id)}>
                        <option value={"MultiInput"}>Yes</option>
                        <option value={"Input"}>No</option>
                    </Form.Control>
                </Form.Group>

                {/* <Form.Group>
                    <Form.Check
                        name="altersDynamicFields"
                        label="Alters Dynamic Fields"
                        value={altersDynamicField}
                        defaultChecked={altersDynamicField}
                        onChange={e => changeInputFieldsHandler(e, 'altersDynamicField', _id)}
                    />
                </Form.Group> */}
                <Form.Group controlId="newInputHelp">
                    <Form.Label>
                        Dropdown<span className="optional">optional</span>
                    </Form.Label>
                    <Form.Control as="select" custom
                        value={dropdownType}
                        onChange={e => setDropdownType(e.target.value)}>
                        <option value='static'>Static</option>
                        <option value='dynamic'>Dynamic</option>
                        <option value="none">None</option>
                    </Form.Control>
                </Form.Group>
                {/* { */}
                {/* // dropdown && */}
                <div>
                    {dropdownType === 'static' && <>
                        <h6>Dropdown Source <span className="required">required</span></h6>
                        <p className="text-muted">
                            List the dropdown choices. Example: Email, Phone, Address.
                        </p>
                        <Form.Control type="text"
                            placeholder="Enter text"
                            className="mb-3"
                            value={list?.join(",")}
                            onChange={e => changeInputFieldsHandler(e, 'list', _id)}
                        />
                    </>}
                    {dropdownType === 'dynamic' && <>
                        <p className="text-muted">Choose the trigger and field to populate this menu.</p>
                        <Form.Group>
                            <Form.Control as="select"
                                custom required
                                value={dynamicFields.triggerId || ""}
                                name="triggerId"
                                onChange={e => changeInputFieldsHandler(e, 'dynamicFields', _id)}>
                                <option key='Select Trigger' hidden value={""}>Select Trigger</option>
                                {triggers.filter(({ _id }) => _id !== props.triggerId).map(trigger => (<option key={trigger._id} value={trigger._id}>{trigger.name}</option>))}
                            </Form.Control>
                        </Form.Group>
                        {dynamicFields.triggerId && <>
                            <Form.Group controlId="newInputKey">
                                <Form.Label>
                                    Key<span className="required">required</span>
                                </Form.Label>
                                <Form.Text className="form-help-text">
                                    Select the data from this trigger to include in the API call.
                                </Form.Text>
                                <Form.Control type="text"
                                    required
                                    placeholder="Enter key"
                                    value={dynamicFields.key || ""}
                                    name="key"
                                    onChange={e => changeInputFieldsHandler(e, 'dynamicFields', _id)} />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="newInputLabel">
                                <Form.Label>
                                    Label<span className="optional">optional</span>
                                </Form.Label>
                                <Form.Text className="form-help-text">
                                    Enter a user friendly name for this field that describes what to enter. Shown to users inside Comet. Example: <span className="bg-light px-2">First Name</span>
                                </Form.Text>
                                <Form.Control type="text"
                                    placeholder="Enter label"
                                    value={dynamicFields.label || ""}
                                    name={"label"}
                                    onChange={e => changeInputFieldsHandler(e, 'dynamicFields', _id)} />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>}
                    </>}
                </div>
                {/* } */}
                <div className="d-flex justify-content-end">
                    <AioButton buttonType="btn-cta2" className="mr-2 px-3" onClick={() => cancelForm()}>Cancel</AioButton>
                    {/*<input type="submit" value="Save" className="btn btn-primary px-4" />*/}
                    <AioButton buttonType="btn-cta1" type="submit" disabled={postLoader}>
                        {!postLoader ? 'Save' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black" />}
                    </AioButton>
                </div>
            </Form>
        </>)
    }
    function renderFormEditor() {
        if (activeInputFieldId === "" && inputFields.length === 0) {
            return renderEditorMsg();
        } else if (activeInputFieldId === "" && inputFields.length > 0) {
            return renderInputFieldsList();
        } else {
            return renderForm();
        }
    }
    return (
        <div className="d-flex position-relative">
            <div className={`pt-3 pr-3 pb-3 pl-0 scroll-bar-css-container ${previewUIStatus === "show" ? "border-right" : ""} flex-grow-1`}>
                <h5>Form Editor</h5>
                {renderFormEditor()}
            </div>
            {previewUIStatus === 'show' && <FormPreview
                inputFields={inputFields}
                activeInputField={activeInputField}
            />}
            <AioButton buttonType="btn-cta3" className="hide-preview-btn" onClick={() => setPreviewUIStatus(previewUIStatus === 'show' ? 'hide' : 'show')}>
                {previewUIStatus === 'show' ? 'Hide User Preview' : 'Show User Preview'}
            </AioButton>
        </div>
    )
}
