// auth
export const AUTHENTICATE = 'auth/authenticate user'
export const LOG_OUT = 'auth/log out user'
export const LOAD_USER_DATA = 'auth/load user details'
export const LOAD_USER_DATA_SUCCESS = 'auth/load user details success'
export const LOAD_USER_DATA_FAIL = 'auth/load user details fail'

// common
export const LOAD_USERAPPS = 'common/load userapps'
export const LOAD_USERAPPS_SUCCESS = 'common/load userapps success'
export const LOAD_USERAPPS_FAIL = 'common/load userapps fail'
export const DELETE_SOURCE = 'common/delete app source'
export const TEST_COMPONENT = 'common/test component'
export const TEST_COMPONENT_SUCCESS = 'common/test component success'
export const TEST_COMPONENT_FAIL = 'common/test component fail'
export const CLEAR_TESTDATA = 'common/clear test data'

// appinfo - integrations
export const LOAD_APPINFOS_DATA = 'integrations/load appinfos data'
export const LOAD_APPINFOS_DATA_SUCCESS = 'integrations/load appinfos data success'
export const LOAD_APPINFOS_DATA_FAIL = 'integrations/load appinfos data fail'
export const LOAD_APPINFO_DATA = 'integrations/load appinfo data'
export const LOAD_APPINFO_DATA_SUCCESS = 'integrations/load appinfo data success'
export const LOAD_APPINFO_DATA_FAIL = 'integrations/load appinfo data fail'
export const POST_APPINFO_DATA = 'integrations/post appinfo data'
export const POST_APPINFO_DATA_SUCCESS = 'integrations/post appinfo data success'
export const POST_APPINFO_DATA_FAIL = 'integrations/post appinfo data fail'
export const UPDATE_APPINFO_DATA = 'integrations/put appinfo data'
export const UPDATE_APPINFO_DATA_SUCCESS = 'integrations/put appinfo data success'
export const UPDATE_APPINFO_DATA_FAIL = 'integrations/put appinfo data fail'
export const DELETE_APPINFO_DATA = 'integrations/delete appinfo data'
export const DELETE_APPINFO_DATA_SUCCESS = 'integrations/delete appinfo data success'
export const DELETE_APPINFO_DATA_FAIL = 'integrations/delete appinfo data fail'
export const LOAD_APPINFO_VERISONS = 'integrations/load appinfo versions'
export const LOAD_APPINFO_VERISONS_SUCCESS = 'integrations/load appinfo versions success'
export const LOAD_APPINFO_VERISONS_FAIL = 'integrations/load appinfo versions fail'
export const UPDATE_APPINFO_VERSION = 'integrations/update appInfo version'
export const UPDATE_APPINFO_VERSION_SUCCESS = 'integrations/update appInfo version success'
export const UPDATE_APPINFO_VERSION_FAIL = 'integrations/update appInfo version fail'
export const LOAD_APPINFO_VERSION = 'integrations/load appinfo version'
export const LOAD_APPINFO_VERSION_SUCCESS = 'integrations/load appinfo version success'
export const LOAD_APPINFO_VERSION_FAIL = 'integrations/load appinfo version fail'
export const CLEAR_APPINFO_VERSION_DATA = 'intergation/clear appinfo version'
//common
export const NAVIGATE_TO = "navigate to";

// notifications
export const ADD_NOTIFICATION = 'notifications/add notification'
export const DELETE_NOTIFICATION = 'notifications/delete notification'

// Loader while posting data
export const IS_LOADING = 'loading/loader while posting data'