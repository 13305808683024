import { navigate } from '@reach/router';
import axios from 'axios'
import * as ActionTypes from '../../actions/types';
import { IAppInfoVersion, IActionPayload } from '../../models';

interface IHandleActions {
    action: any
    dispatch: React.Dispatch<any>,
    response?: any
    error?: Error
}

const handleNavigation = (payload, response) => {
    if (payload === "back") { 
        // Navigate back
        navigate(-1)
    } else if (payload === "appInfo") {
        let appInfoVersion
        // Check the response to verify if it is new or existing appInfo
        if (response.hasOwnProperty('defaultVersion')) {
            // New appinfo will have defaultVersion in response
            appInfoVersion = response.defaultVersion as IAppInfoVersion
            if (appInfoVersion.appInfoId && appInfoVersion.version) {
                navigate(`/app/integration/${appInfoVersion.appInfoId}/version/${appInfoVersion.version}`)
            }
        } else if (response.hasOwnProperty('_id')) {
            // Editing existing appInfo will have the updated appInfo as the response
            appInfoVersion = response as IAppInfoVersion
            if (appInfoVersion._id && appInfoVersion.version) {
                navigate(`/app/integration/${appInfoVersion._id}/version/${appInfoVersion.version}`)
            }
        } else {
            // If no responses match then navigate to home as fallback
            navigate('/app')
        }
    } else {
        // Navigate to specified route
        navigate(payload)
    }
}

const handleActions = ({action, dispatch, response, error}: IHandleActions) => {
    if (action.type === ActionTypes.NAVIGATE_TO) {
        handleNavigation(action.payload, response)
    } else if (error) {
        // handle failure actions
        if (action.payload) {
            action.payload['error'] = error
        } else {
            action['payload'] = {
                type: 'error',
                error
            }
        }
        dispatch(action);
    } else {
        // handle success actions

        // Delay actions which have delay property in payload. Used to remove notification after some delay
        if (action.payload.delay) {
            const duration = action.payload?.duration || 10000
            setTimeout(() => dispatch(action), duration)
        } else {
            dispatch(action);
        }
    }
}

export const apiMiddleware = async action => {

    // next(action);

    if (!action.payload || !action.payload.isAPI) return;

    const {
        url,
        method,
        data,
        dispatch,
        // accessToken,
        onSuccessActions = [],
        onFailureActions = [],
        // onFailure,
        label,
        // headers,
        isLoading = false
    } = action.payload;

    // const dataOrParams = ["GET"].includes(method) ? "params" : "data";

    if (label) {
        // dispatch(apiStart(label));
        console.log('api started')
        dispatch({
            type: action.type,
            payload: action.payload.data
        })
    }

    try {
        const response = await axios({
            baseURL: process.env.GATSBY_API_URL,
            url,
            method,
            data: ["GET"].includes(method) ? null : data,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                // Uncomment for local use. Update connect.sid from current logged in user in dev env
                // "Authorization" : "connect.sid=s%3AoN_YGOc2o-HpRwNi90N-3Uy1Bue1hdzG.k2ksrrCL2cxNK9%2Fnzll3mtmv2zE41GJcDfP1%2B4Wodp0;"
            },
            withCredentials: true
        })

        // console.log('response: ', response.data.data)
        const successAction = `${action.type} success`
        if (method === 'DELETE') {
            dispatch({
                type: successAction,
                payload: action.payload.data
            })
        } else {
            dispatch({
                type: successAction,
                // Send initialPayload to get data from the initial action that was triggered
                initialPayload: action.payload.data,
                payload: response.data.data // Response payload
            })
        }
        onSuccessActions.map(action => {
            handleActions({action, dispatch, response: response.data.data});
        })
        return response.data
    } catch (error) {
        console.log(error)
        dispatch({
            type: `${action.type} fail`,
            error: error
        })
        onFailureActions.map(action => {
            handleActions({action, dispatch, error});
        })
        if (error.response && error.response.status === 401) {
            // dispatch(accessDenied(window.location.pathname));

            // If 401 then user is not authorised, and is redirected to automate login
            window.location.href = `${process.env.GATSBY_AUTOMATE_LOGIN_URL}`
        }
    } finally {
        if (label) {
            // dispatch(apiEnd(label));
            // console.log('api end')
        }
    }
}