import React, { useContext, useEffect, useState } from "react"
import { Form } from "react-bootstrap"

// import CodeEditor from "../../../components/CodeEditor"
import AioButton from "../../../components/UI-Elements/AioButton"
import { Context, GlobalContext } from "../../../context/ContextStore"
import { IAppInfoTrigger, IActivityInputFields } from "../../../models"

interface IActionInputFields extends IActivityInputFields {
    inputDesignerType?: string
    id?: number
    isNew?: boolean
    multipleInputs?: Array<{id: string, value: string}>
}

interface LineGroupInputFormEditorProps {
    validated: boolean
    setValidated: React.Dispatch<React.SetStateAction<boolean>>
    groupLineItems: IActionInputFields[]
    activeInputField: IActionInputFields
    lineGroupInputChangeHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, value: string, inputType) => void
    setToggleAddInput: React.Dispatch<React.SetStateAction<boolean>>
    setShowLineGroupInputForm: React.Dispatch<React.SetStateAction<boolean>>
    submitLineGroupInputForm: () => void
}
export default function LineGroupInputFormEditor({ validated, setValidated, groupLineItems, activeInputField, lineGroupInputChangeHandler, setToggleAddInput, setShowLineGroupInputForm, submitLineGroupInputForm }: LineGroupInputFormEditorProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [triggersList, setTriggersList] = useState<Array<IAppInfoTrigger>>([])
    const [formLabelValid, setFormLabelValid] = useState(true)
    const [labelErrorTxt, setLabelErrorTxt] = useState("This field is required.")

    useEffect(() => {
        setTriggersList(state.appInfo.appInfoVersion.triggers)
    }, [state.appInfo.appInfoVersion])

    const submitFormHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.currentTarget.checkValidity()) {
            // Check if active item label exists in the list
            const filteredList = groupLineItems.filter(eachItem => eachItem.label === activeInputField.label)
            if (filteredList.length > 1) {
                setFormLabelValid(false)
                setLabelErrorTxt("Choose a unique label.")
            } else {
                submitLineGroupInputForm()
            }
        }
        setValidated(false)
    }

    return (
        <Form noValidate validated={validated} onSubmit={submitFormHandler} className="box-container">
            <Form.Group controlId="newInputKey">
                <Form.Label>
                    Key<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter the word or phrase your API uses to reference this field or parameter. Not seen by users. Example: <span className="bg-light px-2">first_name</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    placeholder="Enter key"
                    value={activeInputField.key}
                    onChange={e => lineGroupInputChangeHandler(e, 'key', activeInputField.isNew ? 'isNew' : activeInputField._id)} />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newInputLabel">
                <Form.Label>
                    Label<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter a user friendly name for this field that describes what to enter. Shown to users inside Comet. Example: <span className="bg-light px-2">First Name</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    placeholder="Enter label"
                    value={activeInputField.label}
                    isInvalid={!formLabelValid}
                    onChange={e => {
                        lineGroupInputChangeHandler(e, "label", activeInputField.isNew ? "isNew" : activeInputField._id);
                        setFormLabelValid(true);
                        setLabelErrorTxt("This field is required")
                    }} />
                <Form.Control.Feedback type="invalid">
                    {labelErrorTxt}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newInputHelp">
                <Form.Label>
                    Help Text<span className="optional">optional</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Describe clearly the purpose of this field in a complete, Markdown formatted sentence with at least 20 characters. Example: <span className="bg-light px-2">Filter by first name.</span>
                </Form.Text>
                <Form.Control as="textarea"
                    rows={3}
                    type="text"
                    placeholder="Enter help text"
                    value={activeInputField.description}
                    onChange={e => lineGroupInputChangeHandler(e, 'description', activeInputField.isNew ? 'isNew' : activeInputField._id)} />
            </Form.Group>
            <Form.Group controlId="newInputHelp">
                <Form.Label>
                    Type<span className="optional">optional</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    What type of data does this field collect?
                </Form.Text>
                <Form.Control as="select" custom
                    value={activeInputField.type}
                    onChange={e => lineGroupInputChangeHandler(e, 'type', activeInputField.isNew ? 'isNew' : activeInputField._id)}>
                    <option value='String'>String</option>
                    <option value='Number'>Number</option>
                    <option value='Date'>Date</option>
                    <option value='Password'>Password</option>
                    <option value='Subdomain'>Subdomain</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="newInputLabel">
                <Form.Label>
                    Default Text <span className="optional">optional</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    If most users need the same option, add default text that Comet will save when the Integration is created if the user leaves it blank.
                </Form.Text>
                <Form.Control type="text"
                    placeholder="Enter default text."
                    value={activeInputField.defaultValue}
                    onChange={e => lineGroupInputChangeHandler(e, 'defaultValue', activeInputField.isNew ? 'isNew' : activeInputField._id)} />
            </Form.Group>
            <h6 className="mt-4 mb-3">Options</h6>
            <Form.Group controlId="newInputRequired">
                <Form.Label>
                    Required<span className="optional">optional</span>
                </Form.Label>
                <Form.Control as="select" custom
                              value={activeInputField.required ? 'required' : 'optional'}
                              onChange={e => lineGroupInputChangeHandler(e, 'required', activeInputField._id)}>
                    <option value='required'>Yes</option>
                    <option value='optional'>No</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="newInputDropdown">
                <Form.Label>
                    Dropdown<span className="optional">optional</span>
                </Form.Label>
                <Form.Control as="select" custom
                              value={['multiSelect', 'Select'].includes(activeInputField.inputType) &&
                                  Object.keys(activeInputField.dynamicFields || {}).length === 0 ? 'Static' :
                                  Object.keys(activeInputField.dynamicFields || {}).length > 0 ? 'Dynamic' : 'none'}
                              onChange={e => lineGroupInputChangeHandler(e, 'inputType', activeInputField._id)}>
                    <option value="none">None</option>
                    <option value="Dynamic">Dynamic</option>
                    <option value='Static'>Static</option>
                </Form.Control>
            </Form.Group>
            <div>
                {['Select', 'multiSelect'].includes(activeInputField.inputType) && <Form.Group controlId="newInputStaticDropdown">
                    <Form.Label>
                        Dropdown Source<span className="required">required</span>
                    </Form.Label>
                    <Form.Text className="form-help-text">
                        List the dropdown choices. Example: Email, Phone, Address.
                    </Form.Text>
                    <Form.Control type="text"
                                  required
                                  placeholder="Enter label"
                                  value={activeInputField.list && activeInputField.list.toString()}
                                  onChange={e => lineGroupInputChangeHandler(e, 'list', activeInputField._id)} />
                </Form.Group>}
                {Object.keys(activeInputField.dynamicFields).length > 0 && (<>
                    <Form.Group controlId="newInputDynamicDropdown">
                        <Form.Label>
                            Trigger<span className="required">required</span>
                        </Form.Label>
                        <Form.Control as="select" custom
                                      required
                                      value={activeInputField.dynamicFields.triggerId}
                                      onChange={e => lineGroupInputChangeHandler(e, 'dynamicFields.triggerId', activeInputField._id)}>
                            {triggersList.length > 0 && (
                                <>
                                    <option>Choose a Trigger</option>
                                    {triggersList.map(each => <option key={each._id} value={each._id}>{each.label}</option>)}
                                </>
                            )}
                            {triggersList.length === 0 && (
                                <option value="none" disabled>No Triggers</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="newInputDynamicKey">
                        <Form.Label>
                            Key<span className="required">required</span>
                        </Form.Label>
                        <Form.Control type="text"
                                      required
                                      placeholder="Enter key"
                                      value={activeInputField.dynamicFields.key}
                                      onChange={e => lineGroupInputChangeHandler(e, 'dynamicFields.key', activeInputField._id)} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="newInputDynamicLabel">
                        <Form.Label>
                            Label<span className="required">required</span>
                        </Form.Label>
                        <Form.Control type="text"
                                      required
                                      placeholder="Enter label"
                                      value={activeInputField.dynamicFields.label}
                                      onChange={e => lineGroupInputChangeHandler(e, 'dynamicFields.required', activeInputField._id)} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </>)}
            </div>
            <div className="d-flex justify-content-end">
                <AioButton buttonType="btn-cta2" className="mr-2 px-3" onClick={() => {
                    setToggleAddInput(false);
                    setShowLineGroupInputForm(false);

                }}>Cancel</AioButton>
                <input type="submit" value="Save" className="btn btn-primary px-4" />
            </div>
        </Form>
    )
}