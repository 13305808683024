import React, { useContext, useEffect, useState } from "react"
import { Form, Spinner } from "react-bootstrap"
import { navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import AioButton from "../../components/UI-Elements/AioButton"
import { Props } from '../../models/props'
import { IAppInfoTrigger } from "../../models"
import { Context, GlobalContext } from "../../context/ContextStore"
import { updateAppInfoVersionAction } from "../../actions"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface SettingsFormProps extends Props, RouteComponentProps {
}
const defaultAppInfoVersionState: IAppInfoTrigger = {
    name: '',
    label: '',
    description: '',
    hidden: false,
    priority: 0,
    entity: '',
    type: 'webhook',
    inputFields: [],
    _id: '',
    source: "",
    requestFields: {
        url: "string",
    },
    subscribe: {},
    unsubscribe: {},
    staticOutputFields: [{
        key: "",
        type: "",
        entity: ""
    }],
    dynamicOutputFields: [{
        triggerId: "",
        dynamicFields: {}
    }],
    canPaginate: false
}
export default function SettingsForm(props: SettingsFormProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext;
    const [validated, setValidated] = useState(false);
    const [settingsForm, setSettingsForm] = useState<IAppInfoTrigger>(defaultAppInfoVersionState)
    const { triggers, etag = "" } = state.appInfo.appInfoVersion;
    const { label = "", name = "", entity = "", description = "", hidden = false, _id = "" } = settingsForm;
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        //if props triggerid present, populate the form with existing data;
        if (props.triggerId) {
            const activeTrigger = triggers.find(trigger => trigger._id === props.triggerId);
            if (activeTrigger) {
                setSettingsForm(activeTrigger);
            }
        }
        //navigate to input designer after new trigger is added
        if (!props.triggerId && triggers.length && label !== "") {
            const newlyAddedTrigger = triggers.find(trigger => trigger.label === label);
            if (newlyAddedTrigger) {
                navigate(`../../${newlyAddedTrigger._id}/input`);
            }
        };
    }, [etag, props.triggerId])

    function onFormSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity()) {
            const isKeyAlreadyDefined = triggers.some(trigger => trigger.label === label && trigger._id !== _id);
            if (!isKeyAlreadyDefined) {
                setValidated(false);
                handleSettings(settingsForm);
            }
        }
    }
    //update or add new trigger; if props.trigger present then update else add new trigger
    function handleSettings(triggerPayload) {
        const clonedAppInfoVersion = JSON.parse(JSON.stringify(state.appInfo.appInfoVersion));
        const { _id, ...withoutId } = triggerPayload;
        if (props.triggerId) {
            clonedAppInfoVersion.triggers = clonedAppInfoVersion.triggers.map(trigger => {
                if (props.triggerId === trigger._id) {
                    trigger = {
                        ...trigger,
                        ...withoutId
                    }
                }
                return trigger;
            })
        } else {
            clonedAppInfoVersion.triggers.push(withoutId);
        }
        updateAppInfoVersionAction({
            dispatch: dispatch,
            payload: clonedAppInfoVersion
        })
    }

    function handleFormChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, type: string) {
        setSettingsForm({
            ...settingsForm,
            [type]: type === "hidden" ? event.target.value === "yes" : event.target.value
        })
    }

    return (
        <Form noValidate validated={validated} onSubmit={onFormSubmit} className="box-container">
            <Form.Group controlId="newSettingsKey">
                <Form.Label>
                    Key<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter a unique word or phrase without spaces to reference this trigger inside Comet. Not seen by users. Example: <span className="bg-light">new_ticket</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    value={label}
                    onChange={e => handleFormChange(e, 'label')}
                    isInvalid={triggers.some(item => item.label === label && item._id !== _id)}
                    placeholder="Enter label" />
                <Form.Control.Feedback type="invalid">
                    {validated && triggers.some(item => item.label === label && item._id !== _id) ? "Choose a unique label." : validated && label === "" ? "This field is required." : ""}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newSettingsName">
                <Form.Label>
                    Name<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter a user friendly name for this trigger that describes what makes it run. Shown to users inside Comet. Example: <span className="bg-light">New Ticket</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    value={name}
                    onChange={e => handleFormChange(e, 'name')}
                    placeholder="Enter name" />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newSettingsNoun">
                <Form.Label>
                    Entity<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    What is the object or item in the name above that this trigger provides? Example: <span className="bg-light">Ticket</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    value={entity}
                    onChange={e => handleFormChange(e, 'entity')}
                    placeholder="Enter entity" />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newSettingsDescription">
                <Form.Label>
                    Description<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Describe clearly the purpose of this trigger in a complete sentence. Example: <span className="bg-light">Triggers when a new support ticket is created</span>.
                </Form.Text>
                <Form.Control as="textarea"
                    rows={3}
                    type="text"
                    required
                    value={description}
                    onChange={e => handleFormChange(e, 'description')}
                    placeholder="Enter description" />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newIntegrationAudience">
                <Form.Label>
                    Hidden Options <span className="optional">optional</span>
                </Form.Label>
                <Form.Control as="select" custom
                    value={hidden ? "yes" : "no"}
                    onChange={e => handleFormChange(e, 'hidden')}>
                    <option value={"yes"}>Yes</option>
                    <option value={"no"}>No</option>
                </Form.Control>
            </Form.Group>
            <div className="d-flex justify-content-end mt-4">
                <AioButton buttonType="btn-cta2" className="mr-4 bg-white pl-4 pr-4" onClick={() => navigate(-1)}>
                    Cancel
                </AioButton>
                <AioButton buttonType="btn-cta1" type="submit" disabled={postLoader}>
                    { !postLoader ? 'Save and Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                </AioButton>
            </div>
        </Form>
    )
}
