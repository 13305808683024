import React, { useContext, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { navigate } from "gatsby"

import AioButton from "../../components/UI-Elements/AioButton"
import { IAppInfoActivity, IAppInfoAction, Props } from "../../models"
import { Context, GlobalContext } from "../../context/ContextStore"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface IActionSettingsPayload extends IAppInfoActivity {
    enableCreate?: 'enable' | 'disable'
}

interface SettingsFormProps extends Props {
    actionId?: string
    activeSettings: IAppInfoActivity
    submitSettingsFormData: (data: IAppInfoActivity) => void
    setActiveAction: React.Dispatch<React.SetStateAction<IAppInfoAction>>
}

export default function SettingsForm(props: SettingsFormProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [validated, setValidated] = useState(false);
    const [actionsList, setActionsList] = useState([] as IAppInfoAction[]);
    const [formData, setFormData] = useState<IActionSettingsPayload>({} as IActionSettingsPayload)
    const [formLabelValid, setFormLabelValid] = useState(true)
    const [labelErrorTxt, setLabelErrorTxt] = useState("This field is required.")
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        const clonedActions = [...state.appInfo.appInfoVersion.actions]
        if (props.actionId && clonedActions.length > 0) {
            // Actions List without active action
            setActionsList(clonedActions.filter(eachAction => eachAction._id !== props.actionId))
            const selectedAction = clonedActions.filter(eachAction => eachAction._id === props.actionId)[0]
            setFormData({
                description: selectedAction.description,
                entity: selectedAction.entity,
                label: selectedAction.label,
                name: selectedAction.name,
                type: selectedAction.type,
                hidden: selectedAction.hidden || false,
                _id: selectedAction.isNew ? String(new Date().getTime()) : selectedAction._id
            })
            props.setActiveAction(selectedAction)
        } else if(clonedActions.length > 0) {
            setActionsList(clonedActions.filter(eachAction => eachAction._id !== props.activeSettings._id))
            console.log(clonedActions, props.activeSettings)
            if (props.activeSettings) {
                setFormData(props.activeSettings)
            } else {
                setFormData({
                    description: '',
                    entity: '',
                    label: '',
                    name: '',
                    type: 'create',
                    hidden: false,
                    _id: String(new Date().getTime())
                })
            }
        }
    }, [props.activeSettings, props.actionId])

    const handleSubmit = (event) => {
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()
        if (form.checkValidity()) {
            // Check if form data label is available in actions list
            const filteredList = actionsList.filter(eachAction => eachAction.label === formData.label)
            if (filteredList.length > 0) {
                setFormLabelValid(false)
                setLabelErrorTxt("Choose a unique label.")
            } else {
                props.submitSettingsFormData(formData)
            }
        }
        setValidated(true);
    }

    const handleSettingsFormChange = (e, value) => {
        // if (value === 'enableCreate') {
        //     setFormData({
        //         ...formData,
        //         [value]: e.target.value === "enable"
        //     })
        // } else {
            setFormData({
                ...formData,
                [value]: value === "hidden" ? e.target.value === "hidden" : e.target.value
            })
        // }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="box-container">
            <Form.Group controlId="newIntegrationAudience">
                <Form.Label>
                    Type
                </Form.Label>
                <Form.Text className="form-help-text">
                    Choose the type of Action.
                </Form.Text>
                <Form.Control as="select" custom value={formData.type}
                    onChange={e => handleSettingsFormChange(e, 'type')}>
                    <option value='create'>Create</option>
                    <option value='search'>Search</option>
                </Form.Control>
            </Form.Group>
            {/* <Form.Group>
                <Form.Label>Type</Form.Label>
                <div className="d-flex">
                    <Form.Check
                        className="mr-3"
                        type="radio"
                        value="create"
                        checked={activeAction?.type === 'create'}
                        onChange={e => handleSettingsFormChange(e, 'type')}
                        label="Create"
                        name="typeRadio"
                    />
                    <Form.Check
                        type="radio"
                        value="search"
                        checked={activeAction?.type === 'search'}
                        onChange={e => handleSettingsFormChange(e, 'type')}
                        label="Search"
                        name="typeRadio"
                    />
                </div>
            </Form.Group> */}
            <Form.Group controlId="newSettingsKey">
                <Form.Label>
                    Label<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter a unique word or phrase without spaces to reference this trigger inside Comet. Not seen by users. Example:
                    <span className="bg-light px-2">{formData.type === 'create' ? 'create_ticket' : 'search_ticket'}</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    placeholder="Enter Label"
                    value={formData.label}
                    isInvalid={!formLabelValid}
                    onChange={e => {
                        handleSettingsFormChange(e, 'label');
                        setFormLabelValid(true);
                        setLabelErrorTxt('This field is required')
                    }} />
                <Form.Control.Feedback type="invalid">
                    {labelErrorTxt}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newSettingsName">
                <Form.Label>
                    Name<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Enter a user friendly name for this trigger that describes what makes it run. Shown to users inside Comet. Example:
                    <span className="bg-light px-2">{formData.type === 'create' ? 'Create Ticket' : 'Search Ticket'}</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={e => handleSettingsFormChange(e, 'name')} />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newSettingsNoun">
                <Form.Label>
                    Entity<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    What is the object or item in the name above that this trigger provides? Example: <span className="bg-light px-2">Ticket</span>
                </Form.Text>
                <Form.Control type="text"
                    required
                    placeholder="Enter entity"
                    value={formData.entity}
                    onChange={e => handleSettingsFormChange(e, 'entity')} />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newSettingsDescription">
                <Form.Label>
                    Description<span className="required">required</span>
                </Form.Label>
                <Form.Text className="form-help-text">
                    Describe clearly the purpose of this trigger in a complete sentence. Example:
                    <span className="bg-light px-2">{formData.type === 'create' ? 'Creates a Ticket' : 'Search a Ticket'}</span>.
                </Form.Text>
                <Form.Control as="textarea"
                    rows={3}
                    type="text"
                    required
                    placeholder="Enter description"
                    value={formData.description}
                    onChange={e => handleSettingsFormChange(e, 'description')} />
                <Form.Control.Feedback type="invalid">
                    This field is required.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="newIntegrationAudience">
                <Form.Label>
                    Hidden Options <span className="optional">optional</span>
                </Form.Label>
                <Form.Control as="select" custom value={formData.hidden ? "hidden" : "show"} onChange={(e) => handleSettingsFormChange(e, "hidden")} >
                    <option value="hidden">Yes</option>
                    <option value="show">No</option>
                </Form.Control>
            </Form.Group>
            {/*{formData.type === 'search' && <>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label>*/}
            {/*            Enable Create if not found <span className="optional">optional</span>*/}
            {/*        </Form.Label>*/}
            {/*        <Form.Text className="form-help-text">*/}
            {/*            Pair an existing search and a create to enable 'Find or Create' functionality?*/}
            {/*        </Form.Text>*/}
            {/*        <Form.Control as="select" custom*/}
            {/*            value={formData.enableCreate ? 'enable' : 'disable'}*/}
            {/*            onChange={e => handleSettingsFormChange(e, 'enableCreate')}>*/}
            {/*            <option value="enable">Enable</option>*/}
            {/*            <option value="disable">Disable</option>*/}
            {/*        </Form.Control>*/}
            {/*    </Form.Group>*/}
            {/*    {formData.enableCreate && <Form.Group>*/}
            {/*        <Form.Label>Create Action <span className="required">required</span></Form.Label>*/}
            {/*        <Form.Text className="form-help-text">*/}
            {/*            Choose an Action that will create this object.*/}
            {/*        </Form.Text>*/}
            {/*        { actionsList.length === 0 && <Form.Control as="select" custom>*/}
            {/*            <option value='none' />*/}
            {/*            <option value='none'>No Choices available</option>*/}
            {/*        </Form.Control> }*/}
            {/*        { actionsList.length > 0 && <Form.Control as="select" custom>*/}
            {/*            <option>Choose Action</option>*/}
            {/*            {actionsList.map(eachAction => <option value={eachAction._id} key={eachAction._id}>{eachAction.name}</option>)}*/}
            {/*        </Form.Control> }*/}
            {/*    </Form.Group>}*/}
            {/*</>}*/}
            <div className="d-flex justify-content-end mt-4">
                <AioButton buttonType="btn-cta2" onClick={() => navigate(-1)} className="mr-4">
                    Cancel
                </AioButton>
                <AioButton buttonType="btn-cta1" type="submit" disabled={postLoader}>
                    { !postLoader ? 'Save and Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                </AioButton>
            </div>
        </Form>
    )
}