import React, { useState, useContext, useEffect } from "react"
import { Accordion, Card, Form, Spinner } from "react-bootstrap"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"
import Link from "gatsby-link"
import AioButton from "../../../components/UI-Elements/AioButton"
import { IAppInfoVersion, IAuthInputFields } from "../../../models"
import { Context, GlobalContext } from '../../../context/ContextStore'
import { navigate } from "gatsby"
import { updateAppInfoVersionAction } from "../../../actions"
import ConfigureFieldTable from "../common/ConfigureFieldTable"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ConfigureFields() {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [toggleValue, setToggleValue] = useState(true);
    const [inputFields, setInputFields] = useState<IAuthInputFields[]>([])
    const { authParams } = state.appInfo.appInfoVersion;
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        setInputFields(authParams.inputFields)
    }, [authParams.inputFields])

    //navigate to edit fields page
    function goToEditFieldsPage(fieldData) {
        navigate(`../fields`, { state: { formData: fieldData } });
    }

    function deleteField(field) {
        const filteredFields = authParams.inputFields.filter(item => item._id !== field._id);
        const payload: IAppInfoVersion = {
            ...state.appInfo.appInfoVersion,
            authParams: {
                ...state.appInfo.appInfoVersion.authParams,
                inputFields: filteredFields
            }
        }
        
        updateAppInfoVersionAction({ dispatch, payload })
    }

    function handleTableActions(type, fieldData) {
        if (type === "edit") {
            goToEditFieldsPage(fieldData);
        } else if (type === "delete") {
            deleteField(fieldData);
        }
    }

    function updateAppInfoVersion() {
        const payload: IAppInfoVersion = {...state.appInfo.appInfoVersion}
        updateAppInfoVersionAction({ dispatch, payload })
    }

    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} onClick={() => setToggleValue(!toggleValue)} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    {/* <div className="chip">
                        Step 1
                    </div> */}
                    <h6 className="mb-3 flex-grow-1">1. Configure your fields</h6>
                    {toggleValue ? <ChevronDown /> : <ChevronUp />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pl-3 pr-3">
                    <>
                        <div className="mb-3">
                            <Form.Label>
                                Authentication Fields <span className="required">required</span>
                            </Form.Label>
                            <p className="description mb-1">
                                Build a form with fields for each item your API requires for authentication, including a field for your API key and additional field for any other data needed. Comet does not include any fields by default.
                            </p>
                            <p className="">You must define at least one field where your users can enter API credentials. Your authentication configuration is not complete</p>
                        </div>
                        <ConfigureFieldTable inputFields={inputFields} action={handleTableActions} />
                        <div className="">
                            {/*<AioButton className="float-right" buttonType="btn-cta1" onClick={updateAppInfoVersion}>*/}
                            {/*    Continue*/}
                            {/*</AioButton>*/}
                            <AioButton buttonType="btn-cta1" className="float-right" disabled={postLoader} onClick={updateAppInfoVersion}>
                                { !postLoader ? 'Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                            </AioButton>
                            <Link to={`../fields`} state={{ formData: {} }}>
                                <AioButton className="float-right mr-3" buttonType="btn-cta2">
                                    Add Fields
                                </AioButton>
                            </Link>
                        </div>
                    </>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
