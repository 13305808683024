import React, { useContext, useEffect, useState } from "react"
import { IAppInfoTrigger, Props } from "../../../models"
import ConfigureAPI from "./ConfigureAPI"
import DefineOutput from "./DefineOutput"
import TestAuthentication from "../../../+authentication/components/common/TestAuthentication"
import { RouteComponentProps } from "@reach/router"
import { Context, GlobalContext } from "../../../context/ContextStore"
import { updateAppInfoVersionAction } from "../../../actions"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
interface APIConfigurationProps extends Props, RouteComponentProps {

}
const defaultTriggerState: IAppInfoTrigger = {
    name: '',
    label: '',
    description: '',
    hidden: false,
    priority: 0,
    entity: '',
    type: 'webhook',
    inputFields: [],
    _id: '',
    source: "",
    requestFields: {
        url: "string",
    },
    subscribe: {},
    unsubscribe: {},
    staticOutputFields: [{
        key: "",
        type: "",
        entity: ""
    }],
    dynamicOutputFields: [{
        triggerId: "",
        dynamicFields: {}
    }],
    canPaginate: false
}
export default function APIConfiguration(props: APIConfigurationProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext;
    const [activeTrigger, setActiveTrigger] = useState<IAppInfoTrigger>(defaultTriggerState)
    const { triggers, etag } = state.appInfo.appInfoVersion;

    useEffect(() => {
        const activeTrigger = triggers.find(trigger => trigger._id === props.triggerId);
        if (activeTrigger) {
            setActiveTrigger(activeTrigger);
        }
    }, [etag])
    //update appinfoversion data
    const updateApiConfiguration = (triggerPayload) => {
        const clonedAppInfoVersion = JSON.parse(JSON.stringify(state.appInfo.appInfoVersion));
        const { _id, ...withoutId } = triggerPayload;
        if (props.triggerId) {
            clonedAppInfoVersion.triggers = clonedAppInfoVersion.triggers.map(trigger => {
                if (props.triggerId === trigger._id) {
                    trigger = {
                        ...trigger,
                        ...withoutId
                    }
                }
                return trigger;
            })
        } else {
            clonedAppInfoVersion.triggers.push(withoutId);
        }
        updateAppInfoVersionAction({
            dispatch: dispatch,
            payload: clonedAppInfoVersion
        })
    }
    return (
        <>
            <p className="description" style={{ display: 'flex' }}>
                <FontAwesomeIcon icon={faInfoCircle} className="icon-primary mr-1" inverse />
                <span>
                    {'Now, configure how to get data from your API endpoint, and optionally send data from input fields. Select your trigger type, fill in each field or customize the code, then test your trigger and define the data it returns.'}
                </span>
            </p>
            <ConfigureAPI activeTrigger={activeTrigger} updateApiConfiguration={updateApiConfiguration} />
            <TestAuthentication module="Trigger" indexValue={2} />
            <DefineOutput activeTrigger={activeTrigger} updateApiConfiguration={updateApiConfiguration} />
        </>
    )
}