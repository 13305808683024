import React from "react"

import { Props } from "../../../models"
import ConfigureAPI from "./ConfigureAPI"
import TestAuthentication from "../../../+authentication/components/common/TestAuthentication"
import DefineOutput from "../../../+triggers/components/APIConfiguration/DefineOutput"

interface ApiConfigProps extends Props {
    updateActionsHandler, activeAction
}
const APIConfiguration = (props: ApiConfigProps) => {
    const { updateActionsHandler, activeAction } = props;
    return (
        <>
            <ConfigureAPI activeAction={activeAction} updateActionsHandler={updateActionsHandler} />
            <TestAuthentication module="Action" indexValue={2} />
            <DefineOutput activeTrigger={activeAction} updateApiConfiguration={updateActionsHandler} />
        </>
    )
}

export default APIConfiguration