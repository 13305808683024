import React, { useContext, useEffect, useState } from "react"
import { Redirect, Router } from "@reach/router"

import SettingsForm from "../components/SettingsForm"
import InputDesigner from "../components/InputDesigner"
import * as NavbarCSS from "../../styles/navbar.module.css"
import NavLink from "../../components/NavLink"
import APIConfiguration from "../components/APIConfiguration"
import { IAppInfoActivity, IActivityInputFields, IAuthInputFields, IAppInfoAction, Props } from "../../models"
import { Context, GlobalContext } from "../../context/ContextStore"

interface EditActionProps extends Props {
    integrationId?: string
    versionId?: string
    actionId?: string
    activeAction: IAppInfoAction
    updateAppInfoWithAction: (actionData) => void
    // updateActionsHandler?
    setActiveAction: React.Dispatch<React.SetStateAction<IAppInfoAction>>
}

interface IActionInputFields extends IActivityInputFields {
    inputDesignerType?: string
    id?: number
    isNew?: boolean
    multipleInputs?: Array<{id: string, value: string}>
}

export default function EditAction(props: EditActionProps) {
    const isDisableTabs = !props.actionId
    const { state, dispatch } = useContext(Context) as GlobalContext
    const [activeSettings, setActiveSettings] = useState<IAppInfoActivity>({} as IAppInfoActivity)
    const [activeInputFields, setActiveInputFields] = useState<Array<IActionInputFields>>([])

    useEffect(() => {
        const clonedActions = [...state.appInfo.appInfoVersion.actions]
        if (clonedActions.length > 0) {
            if (props.actionId) {
                const selectedAction = clonedActions.filter(eachAction => eachAction._id === props.actionId)[0]
                props.setActiveAction(selectedAction)
                setActiveSettings({
                    description: selectedAction.description,
                    entity: selectedAction.entity,
                    label: selectedAction.label,
                    name: selectedAction.name,
                    type: selectedAction.type,
                    hidden: selectedAction.hidden,
                    _id: selectedAction.isNew ? String(new Date().getTime()) : selectedAction._id
                })
                setActiveInputFields(selectedAction.inputFields)
            } else if (props.activeAction) {
                setActiveSettings({
                    description: props.activeAction.description,
                    entity: props.activeAction.entity,
                    label: props.activeAction.label,
                    name: props.activeAction.name,
                    type: props.activeAction.type,
                    hidden: props.activeAction.hidden,
                    _id: props.activeAction.isNew ? String(new Date().getTime()) : props.activeAction._id
                })
                setActiveInputFields(props.activeAction.inputFields)
            } else {
                setActiveSettings({
                    description: '',
                    entity: '',
                    label: '',
                    name: '',
                    type: 'create',
                    hidden: false,
                    _id: String(new Date().getTime())
                })
            }
        }
    }, [props.activeAction, state.appInfo.appInfoVersion.actions])

    const submitSettingsFormData = (data: IAppInfoActivity) => {
        const updatedAction = {
            ...props.activeAction,
            ...data
        }
        props.updateAppInfoWithAction(updatedAction)
    }

    const submitInputDesignerData = (data: { inputFieldList: IActionInputFields[] }) => {
        const updatedAction = {
            ...props.activeAction,
            inputFields: [...data.inputFieldList] // ...data.lineItemGroupList
        }
        props.updateAppInfoWithAction(updatedAction)
    }

    const submitLineItemHandler = (data: IActionInputFields[]) => {
        console.log('submitLineItemHandler: ', data)
        const updatedAction = {
            ...props.activeAction,
            inputFields: data
        }
        props.updateAppInfoWithAction(updatedAction)
    }

    return (
        <>
            <h5 className="mb-2">Actions</h5>
            <div className={`d-flex mb-4 ${NavbarCSS.navbarSubnavs}`}>
                <NavLink to="settings" isLeftLink={true}>Settings</NavLink>
                <NavLink to="input" disabled={isDisableTabs}>Input Designer</NavLink>
                <NavLink to="api" disabled={isDisableTabs}>API Configuration</NavLink>
            </div>
            <Router>
                <Redirect from="/" to="settings" noThrow />
                <SettingsForm path="settings"
                    activeSettings={activeSettings}
                    setActiveAction={props.setActiveAction}
                    submitSettingsFormData={submitSettingsFormData} />
                <InputDesigner path="input"
                    activeInputFields={activeInputFields}
                    submitLineItemHandler={submitLineItemHandler}
                    submitInputDesignerData={submitInputDesignerData} />
                <APIConfiguration path="api"
                    updateActionsHandler={props.updateAppInfoWithAction}
                    activeAction={props.activeAction}
                />
            </Router>
        </>
    )
}