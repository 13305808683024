import axios from "axios"

const options = {
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        // Uncomment for local use. Update connect.sid from current logged in user in dev env
        // "Authorization" : "connect.sid=s%3AoN_YGOc2o-HpRwNi90N-3Uy1Bue1hdzG.k2ksrrCL2cxNK9%2Fnzll3mtmv2zE41GJcDfP1%2B4Wodp0;"
    },
    withCredentials: true
}

const AuthService = {
    verifyUser() {
        return axios.get(`${process.env.GATSBY_API_URL}/auth`, {...options})
            // .catch(err => location.href = `${process.env.GATSBY_AUTOMATE_URL}/login?developer=true`)
            // Comment catch in local use. Uncomment when pushing the code
    },

    fetchUserDetails() {
        const url = '/auth/me'
        return {
            isAPI: true,
            url,
            method: 'GET'
        }
    }
}

export default AuthService