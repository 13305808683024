export const guideList = [{
    title: 'What is the Comet Platform?',
    description: 'It’s a tool for you to build a Comet integration for your software product',
}, {
    title: 'What’s needed to build on the platform?',
    description: 'Your product must have an API and you’ll need a basic knowledge of how APIs work'
}, {
    title: 'How does the platform work?',
    description: 'Get a step-by-step walk-through of how to build a Comet integration.'
}, {
    title: 'Ready to get started?',
    description: 'The first step is to configure a way for your users to authenticate themselves.'
}, {
    title: 'Learn about versions for Comet Integrations',
    description: 'Comet integrations use versions to help you manage changes to your project over time'
}, {
    title: 'Where can you go for help?',
    description: 'Read our documentation or reach out to our team.'
}]

export const validationList = [{
    title: 'Errors',
    description: 'Issues that will prevent your integration from functioning properly'
}, {
    title: 'Publishing Tasks',
    description: 'To-dos that must be addressed before your integration can be included in the App Directory'
}, {
    title: 'Warnings',
    description: 'Issues and recommendations that need to be reviewed by Comet before publishing your integration'
}]