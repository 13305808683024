import React  from "react"
import { Table } from "react-bootstrap"
import { navigate } from "gatsby"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import * as IntegrationsCSS from '../../styles/integrations.module.css'
import AioDropdown from "../../components/UI-Elements/AioDropdown"
import { Props, IAppInfo } from "../../models";
import { ThreeDots } from "react-bootstrap-icons"

interface AppInfoListProps extends Props {
    appInfos: Array<IAppInfo>,
    deleteIntegrationHandler: (id: string) => void,
    listAppInfoVersionsHandler: (id: string) => void
}

export default function AppInfoList(props: AppInfoListProps) {

    const { appInfos } = props

    function goToIntegrationDetails(integration: IAppInfo) {
        navigate(`/app/integration/${integration._id}/version/${integration.version}`)
    }

    return (
        <div className={IntegrationsCSS.integrationsContainer}>
            <h3 className={IntegrationsCSS.integrationsTitle}>Integrations</h3>
            <Table responsive="sm" className={IntegrationsCSS.integrationsTable} hover>
                <thead>
                <tr>
                    <th>Name</th>
                    <th className={IntegrationsCSS.tableHeadVersion}>Version</th>
                    <th className={IntegrationsCSS.tableHeadStatus}>Status</th>
                    <th className={IntegrationsCSS.tableHeadAction}> </th>
                </tr>
                </thead>
                <tbody>
                {
                    appInfos.length ? appInfos.map((integration, index) => {
                        return (
                            <tr key={index} className="cursor-pointer">
                                <td onClick={() => goToIntegrationDetails(integration)}>{integration.name}</td>
                                <td onClick={() => goToIntegrationDetails(integration)}>{integration.version}</td>
                                <td onClick={() => goToIntegrationDetails(integration)}>{integration.status}</td>
                                <td className={IntegrationsCSS.tableBodyAction} >
                                    <div className={IntegrationsCSS.gearDropdownContainer}>
                                        <AioDropdown title={<ThreeDots />}
                                                    list={[{
                                                        value: 'Edit',
                                                        url: `integration/${integration._id}/version/${integration.version}/edit`
                                                    }, {
                                                        value: 'Show Versions',
                                                        click: () => props.listAppInfoVersionsHandler(integration._id)
                                                    }, {
                                                        value: 'Delete',
                                                        class: 'text-error',
                                                        click: () => props.deleteIntegrationHandler(integration._id)
                                                    }]} />
                                    </div>
                                </td>
                            </tr>
                        )
                    }): 
                    (<tr>
                        <td>
                            There are no Integrations currently. 
                        </td>
                    </tr>)
                }
                </tbody>
            </Table>
        </div>
    )
}
