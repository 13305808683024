import React, { useState } from "react"
import { QuestionCircleFill, ExclamationCircleFill, XLg, ChevronRight } from "react-bootstrap-icons"

import * as RightNavCSS from '../../styles/rightnav.module.css'
import { guideList, validationList } from "../../utils/constants/guideList"
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap"
import { Link } from "gatsby"

export default function RightNav() {
    const [toggleState, setToggleState] = useState({
        guide: false,
        validate: false
    })

    function handleClick(value) {
        const otherVal = value === 'guide' ? 'validate' : 'guide'
        setToggleState({
            ...toggleState,
            [otherVal]: false,
            [value]: true
        })
    }

    function closeDetails() {
        setToggleState({
            guide: false,
            validate: false
        })
    }

    return (
        <div className={RightNavCSS.rightNavContainer}>
            <div className={RightNavCSS.rightNavIconsContainer}>
                <OverlayTrigger
                    placement={'left'}
                    overlay={
                        <Tooltip id='tooltip-left'>
                            Guide
                        </Tooltip>
                    }>
                    <QuestionCircleFill size={24}
                                        onClick={() => handleClick('guide')}
                                        className={RightNavCSS.rightNavIcon}/>
                </OverlayTrigger>
                <OverlayTrigger
                    placement={'left'}
                    overlay={
                        <Tooltip id='tooltip-left'>
                            Validate
                        </Tooltip>
                    }>
                    <ExclamationCircleFill size={24}
                                           onClick={() => handleClick('validate')}
                                           className={RightNavCSS.rightNavIcon}/>
                </OverlayTrigger>
            </div>
            { (toggleState.guide || toggleState.validate) && <div className={RightNavCSS.rightNavDetails}>
                { toggleState.guide && <div className={RightNavCSS.topContainer}>
                    <div className={RightNavCSS.detailsTitle}>
                        <h6>Guide <XLg size={12} onClick={closeDetails}/></h6>
                    </div>
                    {guideList.map((each, index) => {
                        return <div className={RightNavCSS.eachDetailContainer} key={index}>
                            <div className={RightNavCSS.eachDetailLeftContainer}>
                                <h6>{each.title}</h6>
                                <p>{each.description}</p>
                            </div>
                            <div className={RightNavCSS.eachDetailRightContainer}>
                                <ChevronRight size={24} />
                            </div>
                        </div>
                    })}
                </div> }
                { toggleState.validate && <div className={RightNavCSS.topContainer}>
                    <div className={RightNavCSS.detailsTitle}>
                        <h6>Validate <XLg size={12} onClick={closeDetails} /></h6>
                    </div>
                    <div className={RightNavCSS.validationHeader}>
                        <h5>Validate Integration</h5>
                        <p>Check your project for errors, get specific user experience recommendations, and get a list of remaining items that are required before you can make your integration available to everyone on Comet.</p>
                        <div className={RightNavCSS.validationProgressInfo}>VALIDATED <span>37/43</span></div>
                        <ProgressBar now={60} />
                    </div>
                    <h6 className={RightNavCSS.toDoTitle}>To Do </h6>
                    {validationList.map((each, index) => {
                        return <div className={RightNavCSS.eachDetailContainer} key={index}>
                            <div className={RightNavCSS.eachDetailLeftContainer}>
                                <h6>{each.title}</h6>
                                <p>{each.description}</p>
                            </div>
                            <div className={RightNavCSS.eachDetailRightContainer}>
                                <ChevronRight size={24} />
                            </div>
                        </div>
                    })}
                </div> }
                <div className={RightNavCSS.rightNavFooter}>
                    <Link to={'/'} className={RightNavCSS.footerEachLink}>All help docs</Link>
                    <Link to={'/'} className={RightNavCSS.footerEachLink}>Get help</Link>
                </div>
            </div> }
        </div>
    )
}
