import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"

import Dashboard from "../../+dashboard/Dashboard"
import Integration from "../../+integration/integration"
import StoreProvider from "../../context/ContextStore"
import AuthService from "../../services/authService"
import Navbar from "../../components/layouts/Navbar"
import Loader from "../../components/UI-Elements/Loader"
import Notifications from "../../components/Notifications"

const App = () => {
    const [isAuthenticated, setAuthenticated] = useState(false)

    useEffect(() => {
        // Verify if user is authenticated
        AuthService.verifyUser()
            .then(() => setAuthenticated(true))
            .catch((err) => {
                console.log('User Authentication Failed:', err)
                window.location.href = `${process.env.GATSBY_AUTOMATE_LOGIN_URL}`
            })
    }, [])

    return (
        <>
            {
                isAuthenticated ?
                    (<StoreProvider>
                        <Navbar isAuthenticated={isAuthenticated}/>
                        <Notifications />
                        <Router basepath="/app">
                            <Dashboard path="/" />
                            <Integration path="/integration/*" />
                        </Router>
                    </StoreProvider>)
                    : (
                        <Loader />
                    )
            }
        </>
    )
}

export default App