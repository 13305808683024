import React, { useEffect, useContext } from 'react'
import { Router } from '@reach/router'
import EditIntegration from './containers/EditIntegration'
import IntegrationHome from './containers/IntegrationHome'
import Auth from '../+authentication/Auth'
import Advanced from '../+advanced/advanced'
import Trigger from '../+triggers/Trigger'
import Action from "../+actions/Action"
import SidebarsLayout from '../components/layouts/SidebarsLayout'
import { loadAppInfoVersionAction, getAppInfoAction } from '../actions'
import { Context, GlobalContext } from '../context/ContextStore'
import { Props } from '../models'
import { types as ActionTypes } from '../actions'

interface ContainerProps extends Props {
    integrationId?: string
    versionId?: string
}

function Integration(props: Props) {
    return (
        <div>
            <Router>
                <EditIntegration path="new" />
                <EditIntegration path=":integrationId/version/:versionId/edit" />
                <IntegrationContainer path=":integrationId/version/:versionId/*" />
            </Router>
        </div>
    )
}

function IntegrationContainer(props: ContainerProps) {
    const { state, dispatch } = useContext(Context) as GlobalContext

    useEffect(() => {
        if (props.integrationId && props.versionId) {
            getAppInfoAction({ dispatch: dispatch, params: props.integrationId })
            loadAppInfoVersionAction({ dispatch, params: { integrationId: props.integrationId, appInfoVid: props.versionId } })
        }
        return () => {
            dispatch({ type: ActionTypes.CLEAR_APPINFO_VERSION_DATA })
        }
    }, [])

    return (
        <SidebarsLayout>
            <Router>
                <IntegrationHome path="/" />
                <Auth path="authentication/*" />
                <Trigger path="triggers/*" />
                <Action path="actions/*" />
                <Advanced path="advanced/*" />
            </Router>
        </SidebarsLayout>
    )
}

export default Integration
