import { types as ActionTypes } from '../../actions'
import { INotification } from '../../models'

export interface INotificationsState {
    notifications: INotification[]
}

export const notificationsInitialState: INotificationsState = {
    notifications: []
}

export const notificationsReducer = (state = notificationsInitialState , action) => {
    switch(action.type) {
        case ActionTypes.ADD_NOTIFICATION: {
            const payload = action.payload
            // set notification class based on type
            payload.classes = `alert-${payload.type}`
            return {
                ...state,
                notifications: [
                    ...state.notifications, payload
                ]
            }
        }
        case ActionTypes.DELETE_NOTIFICATION: {
            const payload = action.payload
            // set index from payload or remove top notification by default
            const indexToRemove = payload.index || 0
            // remove notification
            const updatedNotifications = [...state.notifications]
            updatedNotifications.splice(indexToRemove,1)
            return {
                ...state,
                notifications: updatedNotifications
            }
        }
        default:
            return state
    }
}