import React, { useState, useContext } from "react"
import { Accordion, Card, Form } from "react-bootstrap"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"
import AioButton from "../../../components/UI-Elements/AioButton"
import { Context, GlobalContext } from "../../../context/ContextStore"

export default function RedirectURL() {
    const { state } = useContext(Context) as GlobalContext
    const [toggleValue, setToggleValue] = useState(true)

    const { appInfoVersion } = state.appInfo
    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} onClick={() => setToggleValue(!toggleValue)} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    {/* <div className="chip">
                        Step 2
                    </div> */}
                    <h6 className="mb-3 flex-grow-1">2. Copy your OAuth Redirect URL</h6>
                    {toggleValue ? <ChevronDown /> : <ChevronUp />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pl-3 pr-3">
                    <>
                        <div className="mb-3">
                            <p className="description">
                                Copy the link below, then use it in your app’s API or developer settings to create a new integration or app to use with Comet.
                            </p>
                        </div>
                        <Form className="mb-3">
                            <Form.Group>
                                <Form.Label>Copy Comet's OAuth Redirect URI, and, if required, go to your app's admin console and add it to the allowed list.</Form.Label>
                                <div className="d-flex">
                                    <Form.Control type="text"
                                        readOnly
                                        className="flex-grow-1"
                                        placeholder={`${process.env.GATSBY_AUTOMATE_URL}/auth/${appInfoVersion._id}/callback`} />
                                    <div className="ml-3"><AioButton buttonType="btn-cta2" onClick={() => { navigator.clipboard.writeText("https://api-dev.automate.io/auth/salesforce/callback") }}>
                                        Copy
                                    </AioButton></div>
                                </div>
                            </Form.Group>
                        </Form>
                        {/* <div className="">

                            <AioButton buttonType="btn-cta1" className="float-right">
                                Continue
                            </AioButton>

                        </div> */}
                    </>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
