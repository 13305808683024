import React, { useState, useEffect, useContext } from "react"
import { Accordion, Card, Form, Spinner } from "react-bootstrap"
import { ChevronDown, ChevronUp } from "react-bootstrap-icons"
import AioButton from "../../../components/UI-Elements/AioButton"
import { Context, GlobalContext } from '../../../context/ContextStore'
import { updateAppInfoVersionAction } from "../../../actions"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ApplicationCredentials() {
    const { state, dispatch } = useContext(Context) as GlobalContext
    const { appInfo: { appInfoVersion: { authParams: { customConfig = { client_id: '', client_secret: '' } } } } } = state
    const [toggleValue, setToggleValue] = useState(true)
    const [clientCredentials, setClientCredentials] = useState({ clientId: '', clientSecret: '' })
    const [validated, setValidated] = useState(false);
    const [postLoader, setPostLoader] = useState(false);

    useEffect(() => {
        setPostLoader(state.appInfo.isLoading)
    }, [JSON.stringify(state.appInfo.isLoading)])

    useEffect(() => {
        setClientCredentials({ clientId: customConfig.client_id, clientSecret: customConfig.client_secret })
    }, [customConfig!.client_id, customConfig!.client_secret])

    const updateClientCreds = (event) => {
        setValidated(true)
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()
        if (form.checkValidity()) {
            const payload = {
                ...state.appInfo.appInfoVersion,
                authParams: {
                    ...state.appInfo.appInfoVersion.authParams,
                    customConfig: {
                        ...state.appInfo.appInfoVersion.authParams.customConfig,
                        client_id: clientCredentials.clientId,
                        client_secret: clientCredentials.clientSecret
                    }
                }
            }
            updateAppInfoVersionAction({ dispatch, payload })
        }
    }
    return (
        <Accordion defaultActiveKey="0" className="box-container">
            <Card className="border-0">
                <Accordion.Toggle as={Card.Header} onClick={() => setToggleValue(!toggleValue)} className="bg-white border-0 btn text-left d-flex align-items-center" eventKey="0">
                    {/* <div className="chip">
                        Step 3
                    </div> */}
                    <h6 className="mb-3 flex-grow-1">3. Enter your Application Credentials</h6>
                    {toggleValue ? <ChevronDown /> : <ChevronUp />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="pl-3 pr-3">
                    <>
                        <div className="mb-3">
                            <p className="description">
                                From your app’s API or developer settings, copy the Client ID and Secret from your app and paste them below.
                            </p>
                        </div>
                        <Form noValidate validated={validated} onSubmit={updateClientCreds}>
                            <Form.Group controlId="credentialClientID">
                                <Form.Label className="d-flex align-items-center ">
                                    Client ID<span className="required">required</span>
                                </Form.Label>
                                <Form.Text className="form-help-text">
                                    <p className=" text-muted">
                                        Copy Client ID from your app and enter here; may also be called Consumer Key or API Key. Referenced in Comet requests as <span className="bg-light"> &#123;&#123; process.env.CLIENT_ID &#125;&#125; </span>.
                                    </p>
                                </Form.Text>
                                <Form.Control type="text"
                                    required
                                    value={clientCredentials.clientId}
                                    placeholder="Enter Client ID"
                                    onChange={(e) => setClientCredentials(prevState => ({ ...prevState, clientId: e.target.value }))}
                                    defaultValue={clientCredentials.clientId} />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="credentialClientSecret">
                                <Form.Label className="d-flex align-items-center">
                                Client Secret<span className="required">required</span>
                                </Form.Label>
                                <Form.Text className="form-help-text">
                                    <p className=" text-muted">
                                        Copy Client Secret from your app and enter here; may also be called Consumer Secret or API Secret. Referenced in Comet requests as <span className="bg-light"> &#123;&#123; process.env.CLIENT_SECRET &#125;&#125; </span>.
                                    </p>
                                </Form.Text>
                                <Form.Control type="text"
                                    required
                                    value={clientCredentials.clientSecret}
                                    placeholder="Enter Client Secret"
                                    onChange={(e) => setClientCredentials(prevState => ({ ...prevState, clientSecret: e.target.value }))}
                                    defaultValue={clientCredentials.clientSecret} />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="mt-3">
                                <AioButton buttonType="btn-cta1" className="float-right" type="submit" disabled={postLoader}>
                                    { !postLoader ? 'Save and Continue' : <FontAwesomeIcon icon={faSpinner} className="fa-spin color-black"/> }
                                </AioButton>
                            </div>
                        </Form>
                    </>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
