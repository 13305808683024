// import * as ActionTypes from '../actions/types'

const CommonService = {
    getUserapps: (params: string) => {
        const url = '/comet/userapps?appInfoVId=' + params
        return {
            isAPI: true,
            url,
            method: 'GET'
        }
    },

    testComponent: (payload) => {
        const url = '/comet/request'
        return {
            isAPI: true,
            url,
            method: 'POST',
            data: payload
        }
    }
}

export default CommonService